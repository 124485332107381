<template>
  <div class="scan-qr">
    <Background></Background>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="1"
      loader="dots"
    ></Loading>
    <div>
      <h1 class="header-text">
        <div class="back-icon" @click="gotoLogin()">
          <img src="../assets/previous.svg" width="30" />
        </div>
        <div class="topic">{{ $t("password.forget_pw") }}</div>
      </h1>
    </div>
    <div>
      <div>
        <img class="banner" src="../assets/mail.svg" alt="" />

        <p class="detail" v-html="$t('password.input_mail')"></p>
        <div class="card-body">
          <!-- <form> -->
          <div style="">
            <input
              class="input-group"
              type="email"
              v-model="email"
              :placeholder="$t('profile.email')"
              required
            />
          </div>
          <!-- </form> -->
        </div>
        <div
          class="card-body-alert mb-4"
          style="margin-top: -10px"
          v-if="is_checkuser"
        >
          <p>*{{ $t("dialog.no_user") }}</p>
        </div>
        <!-- <span :class="{ 'text-red': invalid }" v-if="invalid"
          >รหัสผ่านต้องมีตัวอักษรภาษาอังกฤษและตัวเลขผสมกันไม่น้อยกว่า 8
          ตัวอักษร</span
        > -->
        <!-- <br /> -->
        <div v-if="invalid">
          <button
            class="btn btn-email btn-auto"
            type="button"
            @click="gotoPassMail()"
          >
            {{ $t("btn.send_mail") }}
          </button>
        </div>
        <div v-else>
          <button
            class="btn btn-email btn-auto"
            type="button"
            style="background: #c4c4c4"
          >
            {{ $t("btn.send_mail") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Footer from "@/components/Footer.vue";
import Background from "@/components/Background.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";

export default {
  data() {
    return {
      email: "",
      message: "",
      error: "",
      invalid: false,
      isLoading: false,
      is_checkuser: false,
    };
  },
  components: { Background, Loading },

  mounted() {
    // this.qrcode_id = this.$route.params.id;
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    async gotoPassMail() {
      this.isLoading = true;
      // if (!this.email) {
      //   // this.invalid = true;
      // } else {
      //   // this.invalid = false;
      try {
        const user = await this.$store.dispatch("getUserByEmail", this.email);
        // console.log("test", user);
        // console.log("email: ", this.email, user);
        let formData = new FormData();
        formData.append("subject", "Change Password");
        this.message += `
        <div style="text-align:center; padding-top:1.5rem; background-color:#ffffff">
              <img src="https://vulcan.houseofdev.tech/proj-file-management-system/assets-file-management/verscan-logo-new_1626081926150.png" width="108px" height="108px">
              <h3>เปลี่ยนรหัสผ่าน</h3>
              <div style="padding-left:14%">
                  <div style="text-align: left; padding:10px; width: 80%">
                      <h3>สวัสดีครับ, คุณ ${this.email}</h3>
                         <p> คุณได้อีเมลนี้เพราะว่ามีการร้องขอการเปลี่ยนรหัสผ่านสำหรับบัญชีผู้ใช้งาน VERSCAN หากคุณไม่ได้ทำการร้องขอการเปลี่ยนรหัสผ่านนี้ คุณสามารถทำการเพิกเฉยต่ออีเมลนี้ได้
                          <br/>
                          <br/>
                          เพื่อทำการเปลี่ยนรหัสผ่านใหม่ กรุณากดที่ลิงค์ด้านล่างเพื่อทำการเปลี่ยนรหัสผ่าน</p>
                      <div style="text-align: center; width:100%; background-color:#2057AD; font-size: 18px;padding: 10px 0;">
                          <a style="color:#ffffff; text-decoration:none !important;" href="${
                            window.location.protocol === "http:"
                              ? "http://"
                              : "https://"
                          }${window.location.host}/changePasswordMail/${
          user.id
        }${user.custom ? `?company=${user.custom}` : ""}">เปลี่ยนรหัสผ่าน
                          </a>
                      </div>
                      <br />
                      <br />
                      <p>หากไม่สามารถทำการคลิกลิงค์ได้ ให้ทำการคัดลอกลิงค์และนำไปวางในแถบที่อยู่ของเบราเซอร์หรือหากคุณไม่ได้ขอเปลี่ยนรหัสผ่านใหม่ โปรดสอบทานการตั้งค่ารหัสผ่านและความปลอดภัยอีกครั้ง หรือติดต่อทีมงานได้ที่ WWW.VERSCAN.COM</p>
                     <hr>
                     <h3>Hello, ${this.email}</h3>
                         <p> Welcome to VERSCAN <br/>
                      You're receiving this email because your requested a password reset for your VERSCAN Account. If you did not request this change, you can safely ignore this email.
                      To choose a new password and complete your request, please follow this link below:</p>
                      <div style="text-align: center; width:100%; background-color:#2057AD; font-size: 18px;padding: 10px 0;">
                          <a style="color:#ffffff; text-decoration:none !important;" href="${
                            window.location.protocol === "http:"
                              ? "http://"
                              : "https://"
                          }${window.location.host}/changePasswordMail/${
          user.id
        }${user.custom ? `?company=${user.custom}` : ""}">RESET PASSWORD
                          </a>
                      </div>
                      <br />
                      <br />
                      <p>You can change your password again at any time from within your My Profile portal at WWW.VERSCAN.COM</p>
                      <br/>
                      Best Regards,<br/><br/>
  
                      VERSCAN Customer Service<br/>
                      Customer Service : www.houseofdev.tech<br/>
                      Tel : 02 096 2959<br/><br/>
                  </div>
              </div>
          </div>
        <br><br>
        `;

        // console.log(this.message);
        formData.append("body", this.message);
        formData.append("sender_email", "service@verscan.com");
        formData.append("sender_name", "VERSCAN");
        formData.append("recipient_email", this.email);
        formData.append("number_of_file", 1);
        // for (var value of formData.values()) {
        //   console.log(value);
        // }

        try {
          const res = await axios.post(
            `https://dev.houseofdev.tech/hod-mailer/verscan_mailer.php`,
            formData
          );
          // console.log(res);
          if (res.data.result) {
            console.log("send email success");
          }
        } catch (e) {
          console.log(e);
        }
        this.gotoLogin();
        // alert("ไม่พบข้อมูลผู้ใช้งานในระบบ");
      } catch (err) {
        this.is_checkuser = true;
      }
      this.isLoading = false;
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    // onDecode(decodedString) {
    //   console.log(`decodedString`, decodedString);
    //   this.$router.push(`/check/${decodedString}`);
    // },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
  },
  watch: {
    email(newValue, oldValue) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let check = re.test(String(newValue).toLowerCase());
      if (check) {
        this.invalid = true;
      } else {
        this.invalid = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.is-invalid {
  border: 1px solid red !important;
}
.text-red {
  color: red;
}
.container {
  justify-content: center;
  display: flex;
}
.card-body {
  text-align: center;
  justify-content: center;
}
.card-body-alert {
  color: red;
  font-size: 14px;
  text-align: center;
  justify-content: center;
}
.topic {
  padding-top: 4rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}
.input-group {
  padding: 0.5rem 1rem;
  background-color: #e0e0e0;
  label {
    padding-bottom: 0.5rem;
  }
}

.btn-email {
  width: 160px;
  height: 45px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 10px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
.camera-frame-corner {
  z-index: 1000;
  width: 100px;
  height: 100px;

  background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
    linear-gradient(to right, black 4px, transparent 4px) 0 100%,
    linear-gradient(to left, black 4px, transparent 4px) 100% 0,
    linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
    linear-gradient(to top, black 4px, transparent 4px) 0 100%,
    linear-gradient(to top, black 4px, transparent 4px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.banner {
  padding-top: 2rem;
}

.scan-qr {
  z-index: 100;
}

.detail {
  padding-top: 1rem;
  padding-bottom: 0%;
  font-size: 18px;
  color: #434343;
}

.header-text {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 32px;
}

.card-form {
  width: 80%;
  height: auto;
  padding: 2.5rem;
  padding-bottom: 2.5rem;
}
.camera-frame {
  height: 250px;
  width: 250px;
  border: 1px solid black;
  margin: auto;
}

.box {
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-repeat: no-repeat;
}
.box-e {
  // Cornered

  background-image: linear-gradient(
      0deg,
      var(--clr-border),
      var(--clr-border) 25%,
      transparent 25%,
      transparent 75%,
      var(--clr-border) 75%
    ),
    // left
      linear-gradient(
        90deg,
        var(--clr-border),
        var(--clr-border) 25%,
        transparent 25%,
        transparent 75%,
        var(--clr-border) 75%
      ),
    // top
      linear-gradient(
        180deg,
        var(--clr-border),
        var(--clr-border) 25%,
        transparent 25%,
        transparent 75%,
        var(--clr-border) 75%
      ),
    // right
      linear-gradient(
        270deg,
        var(--clr-border),
        var(--clr-border) 25%,
        transparent 25%,
        transparent 75%,
        var(--clr-border) 75%
      );
  // bottom;
}

@media only screen and (max-width: 375px) {
  .camera-frame {
    height: 220px;
    width: 220px;
    border: 1px solid black;
    margin: auto;
    margin-top: 1rem;
  }

  .card-form {
    width: 90%;
    height: auto;
    padding: 1.5rem;
    padding-bottom: 2rem;
  }
}
.back-icon {
  position: absolute;
  padding-left: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 320px) {
  .camera-frame {
    height: 200px;
    width: 200px;
    border: 1px solid black;
    margin: auto;
    margin-top: 1rem;
  }

  .card-form {
    width: 90%;
    height: auto;
    padding: 1.5rem;
    padding-bottom: 2rem;
  }
}
@media only screen and (min-width: 500px) {
  .card-body {
    input {
      width: 360px;
    }
    display: flex;
    justify-content: center;
  }
}
</style>
