<template>
  <div>
    <SuccessDialog
      :msg="$t('dialog.save_edit')"
      v-show="showSuccess"
    ></SuccessDialog>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></Loading>
    <div class="responsive-bar">
      <Footer></Footer>
      <!-- <Background></Background> -->
      <div class="DetailAsset">
        <div class="w-100">
          <div class="back-icon">
            <img
              src="../assets/previous.svg"
              width="30"
              @click="backtoDetails()"
            />
          </div>
          <h1 class="header-text">
            <div class="topic" style="padding-top: 0px !important">
              {{ $t("asset.as_data") }}
            </div>
          </h1>

          <div
            class="card-form-login"
            style="padding-top: 47px"
            v-if="!loadingAsset"
          >
            <div class="d-flex">
              <div class="frame-profile" v-if="canUseCustom8">
                <img
                  v-if="firstImage.url"
                  class="banner"
                  :src="firstImage.url"
                  alt=""
                />
                <!-- <img v-else-if="url" :src="url" class="banner-profile" /> -->
                <div class="grey-bg" v-else>
                  <img
                    class="banner"
                    src="../assets/image.svg"
                    style="cursor: pointer"
                    alt=""
                    @click="$refs.firstImageInput.click()"
                  />
                </div>
                <div class="upload-icon-plus">
                  <label for="firstImage">
                    <img
                      src="../assets/pencil-round.svg"
                      style="cursor: pointer"
                    />
                  </label>
                  <input
                    id="firstImage"
                    ref="firstImageInput"
                    type="file"
                    name="firstImageInput"
                    accept="image/png, image/jpeg"
                    style="display: none; margin: 0px !important"
                    @change="firstImageSelected($event)"
                  />
                </div>
              </div>
              <div class="frame-profile">
                <img
                  v-if="assetData.image_url !== null"
                  class="banner"
                  :src="current_img.url"
                  alt=""
                />
                <!-- <img v-else-if="url" :src="url" class="banner-profile" /> -->
                <div class="grey-bg" v-else>
                  <img
                    class="banner"
                    src="../assets/image.svg"
                    style="cursor: pointer"
                    alt=""
                    @click="$refs.currentImageInput.click()"
                  />
                </div>
                <div class="upload-icon-plus">
                  <label for="currentImage">
                    <img
                      src="../assets/pencil-round.svg"
                      style="cursor: pointer"
                    />
                  </label>
                  <input
                    id="currentImage"
                    ref="currentImageInput"
                    type="file"
                    name="currentImageInput"
                    accept="image/png, image/jpeg"
                    style="display: none; margin: 0px !important"
                    @change="currentImageChange($event)"
                  />
                </div>
              </div>
            </div>

            <form @submit.prevent="editAssetData">
              <!-- <div class="input-group" style="padding-top: 2rem">
                <label>รหัสสินทรัพย์</label>
                <input
                  type="text"
                  v-model="assetData.id_fixed_asset"
                />
              </div> -->
              <div
                class="input-group"
                style="
                  display: flex;
                  justify-content: center;
                  padding: 0px;
                  margin-top: 10px;
                "
              >
                <label>{{ $t("asset.qr_id") }} :</label>
                <p
                  class="sub-label"
                  style="padding-left: 10px; margin-bottom: 0px"
                >
                  {{
                    assetData.qrcode
                      ? assetData.qrcode.id_qrcode
                      : $t("asset.not_reg")
                  }}
                </p>
              </div>
              <div
                class="input-group"
                style="display: flex; justify-content: center; padding: 0px"
              >
                <label>{{ $t("asset.as_num") }} :</label>
                <p class="sub-label" style="padding-left: 10px">
                  {{ assetData.id_fixed_asset }}
                </p>
              </div>
              <div class="input-group" style="">
                <label
                  >{{ $t("asset.as_name")
                  }}<span class="require-red">*</span></label
                >
                <input
                  type="text"
                  v-model="assetData.name"
                  style="background-color: #e0e0e0"
                  required
                />
              </div>

              <div class="input-group">
                <label
                  >{{ $t("asset.as_cat")
                  }}<span class="require-red">*</span></label
                >
                <select
                  class="form-control w-100"
                  style="cursor: pointer"
                  v-model="assetData.id_category"
                >
                  <option :value="null">{{ $t("home.not_specified") }}</option>
                  <option
                    v-for="cate in assetOptions.optionCategory"
                    :key="cate.id_category"
                    :value="cate.id_category"
                  >
                    {{ cate.name }}
                  </option>
                  <!-- <option value="0">{{$t("asset.select_cat")}}</option>
                  <option value="เครื่องจักร">{{$t("asset.mach")}}</option>
                  <option value="อุปกรณ์สำนักงาน">{{$t("asset.off_eq")}}</option>
                  <option value="เครื่องตกแต่งสำนักงาน">
                    {{$t("asset.fur")}}
                  </option>
                  <option value="เครื่องใช้สำนักงาน">{{$t("asset.off_sup")}}</option>
                  <option value="เครื่องมือและอุปกรณ์">
                    {{$t("asset.tools")}}
                  </option>
                  <option value="ยานพาหนะ">{{$t("asset.veh")}}</option>
                  <option value="สินทรัพย์ตามสัญญาเช่า">
                    {{$t("asset.lease_as")}}
                  </option>
                  <option value="อะไหล่/ชิ้นส่วนสำรอง">
                    {{$t("asset.sp_part")}}
                  </option>
                  <option value="ระบบสาธารณูปโภค">{{$t("asset.util")}}</option>
                  <option value="สินทรัพย์อื่น">{{$t("asset.others")}}</option> -->
                </select>
              </div>

              <div class="input-group">
                <label>{{ $t("asset.as_sub_cat") }}</label>
                <select
                  v-model="sub_category"
                  class="dropdown form-control w-100"
                >
                  <option :value="null">{{ $t("home.not_specified") }}</option>
                  <option
                    v-for="sub in subOptionCategory"
                    :key="sub.id_sub_category"
                    :value="sub.id_sub_category"
                  >
                    {{ sub.name }}
                  </option>
                </select>
              </div>

              <div class="input-group" v-if="canUseCustom4">
                <label>{{ $t("model.model") }}</label>
                <select
                  v-model="assetData.id_sub_category2"
                  class="dropdown form-control w-100"
                >
                  <option :value="null">{{ $t("home.not_specified") }}</option>
                  <option
                    v-for="sub in subOptionCategory2"
                    :key="sub.id_sub_category2"
                    :value="sub.id_sub_category2"
                  >
                    {{ sub.name }}
                  </option>
                </select>
              </div>

              <div class="input-group">
                <label>{{ $t("asset.as_dt") }}</label>
                <textarea
                  v-model="assetData.description"
                  style="background-color: #e0e0e0"
                ></textarea>
              </div>
              <div class="input-group" v-if="canUseCustom8">
                <label>{{ $t("asset.more_description") }}</label>
                <textarea
                  v-model="assetData.description2"
                  style="background-color: #e0e0e0"
                ></textarea>
              </div>

              <div class="input-group">
                <label>{{ $t("asset.as_stat") }} </label>
                <p
                  class="sub-label require-red clear-pad-mar status_label"
                  style="border: 0px"
                  v-if="getDetailAsset.asset.is_lost === 'lost'"
                >
                  {{ $t("count.as_lost") }}
                </p>
                <p
                  class="sub-label require-red clear-pad-mar status_label"
                  style="border: 0px"
                  v-else-if="!getDetailAsset.asset.qrcode"
                >
                  {{ $t("count.not_reg") }}
                </p>

                <p
                  class="sub-label require-red clear-pad-mar status_label"
                  style="border: 0px"
                  v-else-if="
                    !isCountingFinish &&
                      getDetailAsset.counting_item.status == null &&
                      getDetailAsset.counting_item.damaged_status == null
                  "
                >
                  {{ $t("count.not_count") }}
                </p>
                <p v-else-if="!isCountingFinish" class="sub-label status_label">
                  {{
                    assetStatusTranslate(getDetailAsset.counting_item.status)
                  }}
                  <span v-if="getDetailAsset.counting_item.status === 'ชำรุด'"
                    >:</span
                  >
                  <span
                    v-if="
                      getDetailAsset.counting_item.damaged_status ===
                        'รอการประเมิน'
                    "
                    class="require-red"
                  >
                    {{
                      assetStatusTranslate(
                        getDetailAsset.counting_item.damaged_status
                      )
                    }}</span
                  >
                  <span v-else>
                    {{
                      assetStatusTranslate(
                        getDetailAsset.counting_item.damaged_status
                      )
                    }}</span
                  >
                </p>

                <p
                  class="sub-label require-red clear-pad-mar status_label"
                  style="border: 0px"
                  v-else-if="
                    isCountingFinish == true &&
                      getDetailAsset.asset.status == null &&
                      getDetailAsset.asset.damaged_status == null
                  "
                >
                  {{ $t("count.not_count") }}
                </p>
                <p v-else class="sub-label status_label">
                  {{ assetStatusTranslate(getDetailAsset.asset.status) }}
                  <span v-if="getDetailAsset.asset.status === 'ชำรุด'">:</span>
                  <span
                    v-if="
                      getDetailAsset.asset.damaged_status === 'รอการประเมิน'
                    "
                    class="require-red"
                  >
                    {{
                      assetStatusTranslate(getDetailAsset.asset.damaged_status)
                    }}</span
                  >
                  <span v-else>
                    {{
                      assetStatusTranslate(getDetailAsset.asset.damaged_status)
                    }}</span
                  >
                </p>
              </div>

              <div class="input-group">
                <label>{{ $t("asset.note") }}</label>

                <textarea
                  v-model="assetData.note"
                  style="background-color: #e0e0e0; border: 0px"
                ></textarea>
              </div>

              <customInputAssetInfo
                v-if="isCustom"
                :customid="permissionStatus.company.custom_id"
                :data="assetData"
                :options="assetOptions"
                @set-custom-data="setCustomData"
              />

              <div class="input-group">
                <label>{{ $t("asset.as_dep") }}</label>

                <label>{{ $t("asset.build") }}</label>
                <!-- <select
                    @change="checkMap"
                    v-model="assetData.id_location_building"
                    style="background-color: #e0e0e0; cursor: pointer"
                    class="dropdown form-control w-100"
                  >
                    <option :value="null">{{ $t("home.not_specified") }}</option>
                    <option
                      v-for="building in assetOptions.optionBuilding"
                      :key="building.id_location_building"
                      :value="building.id_location_building"
                    >
                      {{ building.name }}
                    </option>
                  </select> -->
                <v-select
                  :clearable="false"
                  class="search-select"
                  label="name"
                  :reduce="(option) => option.id_location_building"
                  :options="[
                    {
                      name: $t('home.not_specified'),
                      id_location_building: null,
                    },
                    ...(assetOptions.optionBuilding
                      ? assetOptions.optionBuilding
                      : []),
                  ]"
                  v-model="assetData.id_location_building"
                />
                <input
                  v-if="assetData.location_building === 'อื่นๆ'"
                  class="mt-2"
                  type="text"
                  v-model="new_building"
                  :required="assetData.location_building === 'อื่นๆ'"
                />
                <label>{{ $t("asset.fl") }}</label>
                <v-select
                  @input="checkMap"
                  :clearable="false"
                  class="search-select"
                  :getOptionLabel="(option) => option.name"
                  :reduce="(option) => option.id_location_floor"
                  :options="[
                    {
                      name: $t('home.not_specified'),
                      id_location_floor: null,
                    },
                    ...floorOptions,
                  ]"
                  v-model="assetData.id_location_floor"
                />
                <template v-if="canUseCustom8">
                  <label>{{ $t("asset.room") }}</label>
                  <v-select
                    :clearable="false"
                    class="search-select"
                    :getOptionLabel="(option) => option.name"
                    :reduce="(option) => option.id_location_room"
                    :options="[
                      {
                        name: $t('home.not_specified'),
                        id_location_room: null,
                      },
                      ...roomOptions,
                    ]"
                    v-model="assetData.id_location_room"
                  />
                </template>

                <label>{{ $t("asset.dep") }}</label>
                <!-- <input
                  type="text"
                  v-model="assetData.location_departments"
                  style="background-color: #e0e0e0"
                /> -->
                <v-select
                  :clearable="false"
                  class="search-select"
                  :getOptionLabel="(option) => option.label"
                  :reduce="(option) => option.value"
                  :options="optionDepartment"
                  v-model="assetData.id_location_department"
                >
                </v-select>

                <label>{{ $t("asset.zone") }}</label>
                <v-select
                  :clearable="false"
                  class="search-select"
                  label="name"
                  :reduce="(option) => option.id_location_zone"
                  :options="[
                    {
                      name: $t('home.not_specified'),
                      id_location_zone: null,
                    },
                    ...(assetOptions.optionZone ? assetOptions.optionZone : []),
                  ]"
                  v-model="zone"
                />
              </div>
              <div class="input-group">
                <label
                  >{{ $t("asset.as_loc")
                  }}<span class="require-red">*</span></label
                >
                <image-map :disabled="!isMap">
                  <image-map-display class="mt-3" v-if="isMap" />
                </image-map>
              </div>

              <div class="input-group">
                <label>{{ $t("asset.as_reg") }}</label>
                <p class="sub-label">
                  {{ isNull(assetData.registrant) }}
                </p>
              </div>

              <div v-if="!isRoleLower && canUseDepreciation">
                <ExpandHeightHeader
                  :expand="expandDpInfo"
                  :label="$t('registeritem.edit_dp_info')"
                  @toggle="expandDpInfo = !expandDpInfo"
                />
                <ExpandHeight>
                  <div class="py-3" v-if="expandDpInfo">
                    <div class="input-group">
                      <label>
                        {{ $t("asset.acc_note") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="(option) => option.name"
                        :reduce="(option) => option"
                        :options="[
                          {
                            name: $t('home.not_specified'),
                            id_location_zone: null,
                          },
                          ...(assetOptions.optionsNoteAccounting
                            ? assetOptions.optionsNoteAccounting
                            : []),
                        ]"
                        v-model="assetData.note_accounting"
                      >
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("registeritem.note_accounting_code") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_asset && option.name_asset
                              ? `${option.code_asset} - ${option.name_asset}`
                              : '-'
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="assetData.sub_note_accounting"
                        :disabled="!assetData.note_accounting"
                      >
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("depreciation.code_acc") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_acc && option.name_acc
                              ? `${option.code_acc} - ${option.name_acc}`
                              : '-'
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="assetData.sub_note_accounting"
                        :disabled="!assetData.note_accounting"
                      >
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("asset.accumulate_dep_acc_code") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_acc_dp && option.name_acc_dp
                              ? `${option.code_acc_dp} - ${option.name_acc_dp}`
                              : '-'
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="assetData.sub_note_accounting"
                        :disabled="!assetData.note_accounting"
                      >
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.purchase_date") }}</label>
                      <!-- <p>{{data.purchase_date}}</p>  -->
                      <date-picker
                        class="inline-block h-full"
                        v-model="assetData.purchase_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.start_date") }}</label>

                      <date-picker
                        class="inline-block h-full"
                        v-model="assetData.start_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.purchase_price") }}</label>

                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model="assetData.purchase_price"
                      />
                    </div>
                    <div class="input-group">
                      <label>{{ $t("asset.useful_life") }}</label>

                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model="assetData.useful_life"
                      />
                    </div>
                    <div class="input-group">
                      <label>{{ $t("asset.scrap_value") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="assetData.scrap_value"
                      />
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.dep_date") }}</label>
                      <date-picker
                        class="inline-block h-full"
                        v-model="assetData.depreciation_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.prior_dep") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="assetData.prior_depreciation"
                      />
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.prior_nb") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="assetData.prior_net_book"
                      />
                    </div>
                  </div>
                </ExpandHeight>
              </div>

              <div v-if="assetData.asset_details.length >= 0">
                <ExpandHeightHeader
                  :expand="expandAdditionalInfo"
                  :label="$t('registeritem.additional_info')"
                  @toggle="expandAdditionalInfo = !expandAdditionalInfo"
                />
                <ExpandHeight>
                  <div class="py-3" v-if="expandAdditionalInfo">
                    <div
                      class="input-group"
                      v-for="(item, index) in assetData.asset_details"
                      :key="index"
                    >
                      <label>
                        {{ item.name
                        }}<span
                          class="require-red"
                          v-if="
                            (canUseCustom5 && item.column_no == 2) ||
                              (canUseCustom7 && item.column_no == 1)
                          "
                          >*</span
                        >
                      </label>
                      <input
                        type="text"
                        v-model="assetData.asset_details[index].value"
                        :required="
                          (canUseCustom5 && item.column_no == 2) ||
                            (canUseCustom7 && item.column_no == 1)
                        "
                      />
                    </div>
                  </div>
                </ExpandHeight>
              </div>

              <!-- <div class="input-group">
                <label
                  >{{ $t("asset.as_count")
                  }}<span class="require-red">*</span></label
                >
                <p
                  v-if="assetData.counting_status == $t('asset.not_count')"
                  class="sub-label require-red"
                >
                  {{ $t("asset.not_count") }}
                </p>
                <p v-else class="sub-label">
                  {{ isNull(assetCountingItem.inspector) }}
                </p>
              </div> -->

              <!-- <div class="input-group" style="padding-bottom: 2rem">
                <label
                  >{{ $t("asset.countdate")
                  }}<span class="require-red">*</span></label
                >
                <p
                  v-if="assetData.counting_status == $t('asset.not_count')"
                  class="sub-label require-red"
                >
                  {{ $t("asset.not_count") }}
                </p>
                <p v-else class="sub-label">
                  {{ convertDate(assetCountingItem.counting_date) }}
                  {{ $t("asset.time") }}
                  {{ convertTime(assetCountingItem.updatedAt) }}
                </p>
              </div> -->

              <button class="btn btn-save" type="submit" :disabled="isLoading">
                {{ $t("btn.saveedit") }}
              </button>
            </form>
          </div>
          <div class="line-break"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";
// import Background from "@/components/Background.vue";
import Footer from "@/components/Footer.vue";
import moment from "moment";
import ImageMap from "@/components/ImageMap.vue";
import ImageMapDisplay from "@/components/ImageMapDisplay.vue";
import ExpandHeight from "@/components/Animation/expandHeight.vue";
import ExpandHeightHeader from "@/components/ExpandHeightHeader.vue";
import SuccessDialog from "@/components/SuccessDialog.vue";
import Loading from "vue-loading-overlay";
import Axios from "axios";
import Compressor from "compressorjs";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import baseUrl from "../util/backend";
import axios from "axios";
import { authHeader } from "../store/actions";

import customInputAssetInfo from "../components/CustomComponent/customInputAssetInfo.vue";

export default {
  data() {
    return {
      isLoading: false,
      loadingAsset: true,
      isCountingFinish: true,
      showSuccess: false,
      initData: null,
      expandAdditionalInfo: false,
      expandDpInfo: false,
      assetData: {
        id_asset: null,
        id_company: null,
        id_fixed_asset: "",
        name: "",
        status: null,
        damaged_status: null,
        description: "",
        image_url: {
          url: null,
          date: null,
        },
        location_mapX: null,
        location_mapY: null,
        is_lost: "not lost",
        registrant: null,
        note: "",
        note_delete: null,
        note_lost: null,
        delete_by: null,
        report_by: null,
        date_regis: null,
        report_time: null,
        purchase_date: null,
        purchase_price: null,
        useful_life: null,
        start_date: null,
        acc_depreciation: null,
        net_book: null,
        is_delete: false,
        createdAt: null,
        updatedAt: null,
        id_location_building: null,
        id_location_department: null,
        id_location_zone: null,
        id_location_floor: null,
        id_location_room: null,
        id_category: null,
        id_sub_category: null,
        id_sub_category2: null,
        register_by: null,
        qrcode: {
          id: null,
          id_qrcode: "",
          id_company: null,
          id_asset: null,
          is_delete: false,
          createdAt: null,
          updatedAt: null,
        },
        galleries: [],
        asset_details: [],
        location_buildings: {
          id_location_building: null,
          name: "",
          other_lang: null,
          is_delete: false,
          createdAt: null,
          updatedAt: null,
          id_company: null,
        },
        location_floors: {
          id_location_floor: null,
          name: "",
          other_lang: null,
          image_url: null,
          id_company: null,
          is_delete: false,
          createdAt: null,
          updatedAt: null,
          id_location_building: null,
        },
        location_departments: {
          id_location_department: null,
          name: "",
          other_lang: null,
          id_company: null,
          is_delete: false,
          createdAt: null,
          updatedAt: null,
        },
        location_zones: null,
        categories: {
          id_category: null,
          name: "",
          other_lang: null,
          id_company: null,
          is_delete: false,
          createdAt: null,
          updatedAt: null,
        },
        sub_category: null,
        note_accounting: null,
        sub_note_accounting: null,
        depreciation_date: null,
        prior_depreciation: null,
        prior_net_book: null,
      },
      assetCountingItem: null,
      assetOptions: {},
      otherColumn: [],
      id_company: null,
      firstImage: "",
      firstImageChange: false,
      firstImageNewUrl: null,
      current_img: "",
      current_img_change: false,
      current_img_new_url: null,
      qrcode_id: "",
      pictureFile: "",
      new_building: "",
      new_floor: "",
      url_new: null,
      user_data: "",
      prevRoute: null,
      sub_category: null,
      zone: "",
      isMap: false,
    };
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  components: {
    Footer,
    ImageMap,
    ImageMapDisplay,
    DatePicker,
    SuccessDialog,
    customInputAssetInfo,
    Loading,
    ExpandHeightHeader,
    ExpandHeight,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    // "assetData.location_building"() {
    //   this.assetData.location_floor = this.floorHeader[0];
    // },
    "assetData.id_location_building"(_, oldVal) {
      if (oldVal) {
        this.assetData.id_location_floor = null;
      }
    },
    "assetData.id_location_floor"() {
      this.checkMap();
      this.$store.dispatch("setMapPosition", { x: null, y: null });
    },
    "assetData.id_category"(_, oldVal) {
      if (oldVal) {
        this.sub_category = null;
        this.assetData.id_sub_category2 = null;
      }
    },
    sub_category(_, oldVal) {
      if (oldVal) {
        this.assetData.id_sub_category2 = null;
      }
    },
    "assetData.note_accounting"(val, oldVal) {
      if (oldVal.note_accounting_id !== val.note_accounting_id) {
        this.assetData.sub_note_accounting = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      getDetailAsset: "getDetailAsset",
      getCountingDate: "getCountingDate",
      getAllAssets: "getAllAssetsStore",
      getAllMap: "getAllMap",
      getMapPosition: "getMapPosition",
      getUserDetail: "getUserDetail",
      permissionStatus: "permissionStatus",
      isRoleLower: "isRoleLower",
      canUseDepreciation: "canUseDepreciation",
      canUseCustom4: "canUseCustom4",
      canUseCustom5: "canUseCustom5",
      canUseCustom7: "canUseCustom7",
      canUseCustom8: "canUseCustom8",
    }),
    getSubNoteAccountingOptions() {
      // return this.assetOptions.optionsNoteAccounting
      const noteAccouting = this.assetOptions?.optionsNoteAccounting?.find(
        (note_acc) => {
          return (
            note_acc.note_accounting_id ==
            this.assetData?.note_accounting?.note_accounting_id
          );
        }
      );
      return noteAccouting?.sub_note_accountings || [];
    },
    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
    // optionCategory() {
    //   let list = [...new Set(this.getAllAssets.map((item) => item.category))];
    //   return list.filter((x) => x);
    // },
    // builddingHeader() {
    //   let map = this.$store.getters.getAllMap;
    //   map = this.lodash.map(map, (m) => {
    //     return m.location_building;
    //   });
    //   console.log(`map lodash`, [...new Set(map)]);
    //   return [...new Set(map)];
    // },
    // floorHeader() {
    //   let map = this.$store.getters.getAllMap;
    //   let floorInBuilding = [];
    //   for (const building of map) {
    //     if (
    //       building.location_building ===
    //       this.getDetailAsset.asset.location_building
    //     ) {
    //       floorInBuilding.push(building.location_floor);
    //     }
    //   }
    //   return floorInBuilding;
    //   // map = this.lodash.map(map, (m) => {
    //   //   return m.location_floor;
    //   // });
    //   // console.log(`map lodash`, [...new Set(map)]);
    //   // return [...new Set(map)];
    // },
    subOptionCategory() {
      if (this.assetOptions) {
        const subOptions = this.assetOptions.optionSubCategory?.filter(
          (opt) => opt.id_category === this.assetData.id_category
        );
        return subOptions || [];
      } else {
        return [];
      }
    },
    subOptionCategory2() {
      if (this.assetOptions) {
        const subOptions = this.assetOptions.optionSubCategory2?.filter(
          (opt) => opt.id_sub_category === this.sub_category
        );
        return subOptions || [];
      } else {
        return [];
      }
    },
    floorOptions() {
      if (this.assetOptions) {
        const floorOptions = this.assetOptions.optionFloor?.filter(
          (opt) =>
            opt.id_location_building === this.assetData.id_location_building
        );
        return floorOptions || [];
      } else {
        return [];
      }
    },
    roomOptions() {
      if (this.assetOptions) {
        const roomOptions = this.assetOptions.optionRoom?.filter(
          (opt) => opt.id_location_floor === this.assetData.id_location_floor
        );
        return roomOptions || [];
      } else {
        return [];
      }
    },
    optionDepartment() {
      if (this.assetOptions) {
        let department = this.assetOptions?.optionDepartment?.map((opt) => {
          return {
            label: `${opt.code} - ${opt.name}`,
            value: opt.id_location_department,
          };
        });
        if (department) {
          department.unshift({
            label: this.$t("home.not_specified"),
            value: null,
          });
        } else {
          department = [
            {
              label: this.$t("home.not_specified"),
              value: null,
            },
          ];
        }
        return department;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions(["sendAllMap"]),
    isNull(data) {
      return data ? data : "-";
    },
    assetStatusTranslate(text) {
      switch (text) {
        case "ใช้งานได้":
          return this.$t("count.inuse");
        case "ไม่ได้ใช้งาน":
          return this.$t("count.not_inuse");
        case "ชำรุด":
          return this.$t("count.defect");
        case "อยู่ระหว่างการซ่อม":
          return this.$t("count.repairing");
        case "ซ่อมได้":
          return this.$t("count.repar");
        case "ซ่อมไม่ได้":
          return this.$t("count.irrepar");
        case "รอการประเมิน":
          return this.$t("count.wait_as");
        case "กำลังซ่อม":
          return this.$t("maintenance.fixing");
        case "ซ่อมแล้ว":
          return this.$t("maintenance.fixed");
        default:
          return "";
      }
    },

    setCustomData(e) {
      this.assetData = { ...this.assetData, ...e };
    },
    checkMap() {
      const floor = this.assetOptions.optionFloor?.find(
        (opt) => opt.id_location_floor === this.assetData.id_location_floor
      );
      if (floor) {
        this.isMap = true;
        console.log("have imageeeeeeeeeeeeeeeeeeee");
        this.$store.dispatch("setMapUrl", floor.image_url || "");
      } else {
        this.$store.dispatch("setMapUrl", "");
        console.log("no imageeeeeeeeeeeeeeeeee");
        this.isMap = false;
      }
    },
    backtoDetails() {
      this.$router.back();
    },
    onNoteAccountingChange(value) {
      // this.assetData.note_accounting = ;
    },
    async validateCustomInput() {
      if (!this.canUseCustom7) return true;
      const other3 = this.assetData.asset_details.find(
        (detail) => detail.column_no == 3
      );
      const errorDetail = [];
      if (!other3?.value.includes(" - ")) {
        errorDetail.push(other3.name);
      }
      if (!this.assetData.description.includes(" - ")) {
        errorDetail.push(this.$t("asset.as_dt"));
      }
      if (errorDetail.length > 0) {
        const errorText = errorDetail.join(", ");
        const result = await Swal.fire({
          icon: "error",
          text: `${errorText} ${this.$t("dialog.incorrect_confirm")}`,
          showCancelButton: true,
          confirmButtonText: this.$t("btn.conf"),
          cancelButtonText: this.$t("btn.canc"),
          confirmButtonColor: "#007AFE",
          cancelButtonColor: "#C4C4C4",
          reverseButtons: true,
        });
        return result.isConfirmed;
      } else {
        return true;
      }
    },
    async editAssetData() {
      const passValidate = await this.validateCustomInput();
      if (!passValidate) return;
      this.isLoading = true;

      this.assetData.location_mapX = this.getMapPosition.x;
      this.assetData.location_mapY = this.getMapPosition.y;

      // this.assetData.id_category = this.assetData.categories.id_category;

      this.assetData.id_sub_category = this.sub_category;

      // this.assetData.id_location_building = this.assetData.location_buildings?.id_location_building;

      // this.assetData.id_location_floor = this.assetData.location_floors?.id_location_floor;

      // this.assetData.id_location_department = this.assetData.location_departments?.id_location_department;

      this.assetData.id_location_zone = this.zone;
      let data_send = this.assetData;
      console.log("data_send -->", data_send);
      if (this.assetData.location_building === "อื่นๆ") {
        data_send.location_building = this.new_building;
      }
      if (this.assetData.location_floor === "อื่นๆ") {
        data_send.location_floor = this.new_floor;
      }
      if (!data_send.purchase_price) data_send.purchase_price = null;
      if (!data_send.useful_life) data_send.useful_life = null;
      // if (!data_send.acc_depreciation) data_send.acc_depreciation = null;
      // if (!data_send.net_book) data_send.net_book = null;
      if (!data_send.scrap_value) data_send.scrap_value = null;
      if (!data_send.prior_depreciation) data_send.prior_depreciation = null;
      if (!data_send.prior_net_book) data_send.prior_net_book = null;

      try {
        // Uploading images
        if (this.firstImageChange) {
          if (!this.firstImageNewUrl) {
            const newFirstImageUrl = await this.uploadImage(
              this.firstImage.file
            );
            this.firstImageNewUrl = newFirstImageUrl;
            this.assetData.first_img_url = {
              url: newFirstImageUrl,
              date: new Date(),
            };
          } else {
            this.assetData.first_img_url = {
              url: this.firstImageNewUrl,
              date: new Date(),
            };
          }
        }
        if (this.current_img_change) {
          if (!this.current_img_new_url) {
            const newCurrentImageUrl = await this.uploadImage(
              this.current_img.file
            );
            this.current_img_new_url = newCurrentImageUrl;
            this.assetData.image_url = {
              url: newCurrentImageUrl,
              date: new Date(),
            };
          } else {
            this.assetData.image_url = {
              url: this.current_img_new_url,
              date: new Date(),
            };
          }
        }

        // check is data change
        if (JSON.stringify(data_send) !== JSON.stringify(this.initData)) {
          await this.$store.dispatch("editAsset", data_send);
        }
        this.firstImageChange = false;
        this.firstImageNewUrl = null;
        this.current_img_change = false;
        this.current_img_new_url = null;
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
          this.isLoading = false;
          this.$router.back();
        }, 2000);
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },
    convertDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    convertTime(time) {
      return moment(time).format("HH:mm");
    },
    async onFileChange(e) {
      const file = e.target.files[0];
      this.pictureFile = file;

      if (!(await this.$store.dispatch("checkFileType", file))) {
        e.target.value = null;
        return Swal.fire(this.$t("home.up_img"));
      }
      await this.upload();
      e.target = null;
      e.preventDefault();
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },

    async firstImageSelected(e) {
      const file = e.target.files[0];
      if (!(await this.$store.dispatch("checkFileType", file))) {
        e.target.value = null;
        return Swal.fire(this.$t("home.up_img"));
      }
      this.firstImageNewUrl = null;
      this.firstImage = { file, url: URL.createObjectURL(file) };
      this.firstImageChange = true;
    },
    async currentImageChange(e) {
      const file = e.target.files[0];
      if (!(await this.$store.dispatch("checkFileType", file))) {
        e.target.value = null;
        return Swal.fire(this.$t("home.up_img"));
      }
      this.current_img_new_url = null;
      this.current_img = { file, url: URL.createObjectURL(file) };
      this.current_img_change = true;
    },
    async uploadImage(imageFile) {
      const fileforupload = await this.compressImage(imageFile);
      const file = new File([fileforupload], imageFile.name, {
        type: "image/jpeg",
      });
      const formdata = new FormData();
      formdata.append("folder_id", 2);
      formdata.append("file", file);
      const res = await this.$store.dispatch("uploadImage", formdata);
      const uploadedUrl = res.data.data.uploadFileAtLevel.url;
      return uploadedUrl;
      // this.url_new = res.data.data.uploadFileAtLevel.url;
      // this.assetData.image_url = {
      //   url: this.url_new,
      //   date: new Date(),
      // };
    },

    // TODO remove if uploadImage function no bug
    // async upload() {
    //   this.isLoading = true;
    //   const fileforupload = await this.compressImage(this.pictureFile);
    //   const file = new File([fileforupload], this.pictureFile.name, {
    //     type: "image/jpeg",
    //   });

    //   // console.log(`before compress`, this.pictureFile);
    //   // console.log(`after compress`, fileforupload);

    //   const formdata = new FormData();
    //   formdata.append("folder_id", 2);
    //   formdata.append("file", file);
    //   // console.log(`fileforupload`, fileforupload);
    //   // console.log("fddd", file);
    //   const res = await this.$store.dispatch("uploadImage", formdata);
    //   console.log("res.data.data;", res.data.data);
    //   this.url_new = res.data.data.uploadFileAtLevel.url;
    //   console.log("url_new ", this.url_new);
    //   this.current_img = { url: this.url_new };
    //   this.assetData.image_url = {
    //     url: this.url_new,
    //     date: new Date(),
    //   };
    //   // Axios.post("https://api.verscan.com/file/api/file/create", formdata, {
    //   //   headers: {
    //   //     "Content-Type": "multipart/form-data",
    //   //   },
    //   // }).then((res) => {
    //   //   console.log("res.data.data;", res.data.data);
    //   //   this.url_new = res.data.data.uploadFileAtLevel.url;
    //   //   console.log("url_new ", this.url_new);
    //   //   this.current_img = { url: this.url_new };
    //   //   this.assetData.image_url = {
    //   //     url: this.url_new,
    //   //     date: new Date(),
    //   //   };
    //   // });
    //   this.isLoading = false;
    // },
    async loadAssetOptions() {
      try {
        const res = await axios.get(
          `${baseUrl()}asset/get/option/${this.id_company}`,
          authHeader()
        );
        this.assetOptions = res.data.data;
        console.log("assetOption --> ", this.assetOptions);
      } catch (err) {
        console.log(err);
      }
    },
    async loadOtherColumn() {
      try {
        const res = await axios.get(
          `${baseUrl()}admin/other_column?id_company=${this.id_company}`,
          authHeader()
        );
        this.otherColumn = res.data.data;
        await this.mapOtherColumn();
      } catch (err) {
        console.log(err);
      }
    },
    async mapOtherColumn() {
      const otherColumnArr = [];
      for (const column of this.otherColumn) {
        let pushed = false;
        for (const detail of this.getDetailAsset.asset.asset_details) {
          if (detail.column_no === column.column_no) {
            otherColumnArr.push(detail);
            pushed = true;
          }
        }
        if (!pushed) {
          otherColumnArr.push({ ...column, value: null });
        }
      }
      console.log("otherColumnArr");
      console.log(otherColumnArr);
      this.getDetailAsset.asset.asset_details = otherColumnArr;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.loadingAsset = true;
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.qrcode_id = this.$route.params.id;
    await Promise.all([
      this.loadAssetOptions(),
      this.$store.dispatch("getDetailAssets", this.qrcode_id),
      this.$store.dispatch("getAllCountingDateByIdCompany"),
    ]);
    await this.loadOtherColumn();
    // await this.loadAssetOptions();
    // await this.$store.dispatch("getDetailAssets", this.qrcode_id);
    // await this.loadOtherColumn();
    // await this.$store.dispatch("getAllCountingDateByIdCompany");
    this.isCountingFinish =
      this.getCountingDate.length > 0
        ? this.getCountingDate[0].is_finish
        : true;
    this.assetData = this.lodash.cloneDeep(this.getDetailAsset.asset);
    this.initData = this.lodash.cloneDeep(this.getDetailAsset.asset);
    this.assetCountingItem = this.lodash.cloneDeep(
      this.getDetailAsset.counting_item
    );
    this.sub_category = this.assetData.sub_category
      ? this.assetData.sub_category.id_sub_category
      : null;

    this.zone = this.assetData.location_zones
      ? this.assetData.location_zones.id_location_zone
      : null;

    this.current_img =
      typeof this.assetData.image_url === "string"
        ? JSON.parse(this.assetData.image_url)
        : this.assetData.image_url;
    this.firstImage = this.assetData.first_img_url || null;
    await this.checkMap();
    this.$store.dispatch("setMapPosition", {
      x: this.assetData.location_mapX,
      y: this.assetData.location_mapY,
    });
    this.loadingAsset = false;
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.btn-save {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
input,
select,
textarea {
  border-radius: 10px;
  border: 1px solid #bcbaba;
  padding: 0.4rem;
}

select {
  background: white;
}

textarea {
  padding-bottom: 1.5rem;
}
.btn-indoor-modal {
  width: 77px;
  text-align: center;
  margin: auto;
}

.indoor-map-modal {
  height: 40vh;
  padding-bottom: 1rem;
}
.modal-content {
  padding: 1rem;
  border-radius: 35px;
  height: auto;

  & label {
    color: #4b4b4b;
    padding: 0.4rem;
  }
}

.indoor-map-none {
  border-radius: 12px;
  border: 1px solid #bcbaba;
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("https://api.verscan.com/download/no-img.jpg");
  background-size: cover;
  p {
    opacity: 1;
    font-size: 1.25rem;
    box-shadow: 0 0 black;
    background: white;
  }
}
.frame-profile {
  position: relative;
  // top: -0.2rem;
  // left: 50%;
  // right: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  /* text-align: center; */
  margin: auto;
  background: rgb(223, 223, 223);
  border-radius: 10%;
  z-index: 90;
  // border: 5px solid white;
  // box-shadow: 0px 3px 6px #00000029;
}

.banner {
  height: 100%;
  width: 100%;
  border-radius: 10%;
  z-index: 1;
  object-fit: cover;
}

.header-text {
  padding-top: 92px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}

.input-group label {
  justify-self: start;
  padding-bottom: 0.3rem;
  margin-bottom: 0 !important;
  color: #7c7c7c;
}

// .card-form {
//   margin-top: 2rem;
//   width: 90%;
//   padding: 2rem 1rem 2rem 1rem;
// }

.sub-label {
  font-size: 18px;
  color: black;
}

.indoor-map {
  display: flex;
  justify-content: center;
  // margin-bottom: 0.5rem;
  width: 100%;
  height: 400px;
  background: white;
  border: 1px solid #d0d0d0 !important;
  border-radius: 11px;
  overflow: hidden;

  /* border-radius: 0.75rem; */

  img {
    height: 200%; /* probably looks neater if auto */
    width: 200%; /* double width image to show only first quarter */
    vertical-align: bottom; /* moves image to true text bottom */

    /* width: auto;
      max-width: 100% !important;
      height: auto; */
    /* object-fit: scale-down; */
  }
}

.DetailAsset {
  position: relative;
  z-index: 2;
}

.card-form-login {
  margin-top: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

.upload-icon-plus {
  position: absolute;
  bottom: -5px;
  right: -5px;
  z-index: 100;

  img {
    display: inline-block;
    // position: absolute;
    width: 31px;
    height: 31px;
  }
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 6px;
}

.input-group[data-v-659b5a2a]
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  /* border-radius: 6px; */
  border: 0px;
  padding-left: 5px;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1025px) {
  .card-form-login {
    // margin-top: 60px;
    margin-left: 20%;
    margin-right: 20%;
  }
}

.search-select {
  ::v-deep .vs__search::placeholder,
  ::v-deep .vs__dropdown-toggle {
    background: #f4f4f4;
    border: none;
    height: 40px;
  }
}
</style>
