<template>
  <Slide :closeOnNavigation="true" class="menu-admin">
    <a @click="gotoHome()" style="cursor: pointer">
      <span>
        <img src="@/assets/Logo2.png" width="60px" height="60px" />
      </span>
    </a>
    <a @click="gotoHome()">
      <span>{{ $t("home.mainmenu") }}</span>
    </a>
    <div class="menu-submenu">
      <span>{{ $t("asset.as_register") }}</span>
      <div class="menu-submenu-item">
        <a @click="gotoManageAsset()">
          <span>{{ $t("asset.manage_data") }}</span>
        </a>
        <a @click="gotoManageColumnName()">
          <span>{{ $t("asset.manage_column_name") }}</span>
        </a>
      </div>
    </div>
    <a @click="gotoManageUser()">
      <span>{{ $t("home.user") }}</span>
    </a>
    <a @click="gotoMap()">
      <span>{{ $t("home.buildpl") }}</span>
    </a>
    <a @click="gotoCatAssets()">
      <span>{{ $t("home.cat_assets") }}</span>
    </a>
    <a @click="gotoManageDepartments()">
      <span>{{ $t("department.name") }}</span>
    </a>
    <a @click="gotoManageZone()">
      <span>{{ $t("zone.name") }}</span>
    </a>
    <div v-if="isCustom">
      <customSlideMenu
        :customid="permissionStatus.company.custom_id"
      ></customSlideMenu>
    </div>
    <div class="menu-submenu">
      <span>{{ $t("asset.log_topic") }}</span>
      <div class="menu-submenu-item">
        <a @click="gotoAssetAllEditLog()">
          <span>{{ $t("asset.general_log") }}</span>
        </a>
        <!-- TODO - remove old maintenance -->
        <!-- <a @click="gotoAssetMaintenanceLog()">
          <span>{{ $t("asset.maintenance_log") }}</span>
        </a> -->
        <a @click="gotoDeletedAssetLog()">
          <span>{{ $t("asset.deleted_log") }}</span>
        </a>
      </div>
    </div>
  </Slide>
</template>

<script>
import { mapGetters } from "vuex";
import { Slide } from "vue-burger-menu";
import customSlideMenu from "@/components/CustomComponent/customSlideMenu.vue";
export default {
  data() {
    return {
      showSubmenu: false,
    };
  },
  components: {
    Slide,
    customSlideMenu,
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),
    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    },
    gotoManageAsset() {
      this.$store.dispatch("setPageState", {
        pageName: "",
        pageNum: 1,
      });
      this.$router.push("/admin/ManageAsset");
    },
    gotoManageColumnName() {
      this.$router.push("/admin/ManageColumnName");
    },
    gotoManageUser() {
      this.$router.push("/admin/ManageUser");
    },
    gotoMap() {
      this.$router.push("/admin/Map");
    },
    gotoCatAssets() {
      this.$router.push("/admin/CatAssets");
    },
    gotoManageDepartments() {
      this.$router.push("/admin/ManageDepartment");
    },
    gotoManageZone() {
      this.$router.push("/admin/ManageZone");
    },
    gotoDeletedAssetLog() {
      this.$router.push("/admin/DeletedAssetLog");
    },
    gotoAssetAllEditLog() {
      this.$router.push("/admin/AssetAllEditLog");
    },
    gotoAssetMaintenanceLog() {
      this.$router.push("/admin/AssetMaintenanceLog");
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1025px) {
  .menu-admin {
    display: none;
  }
}
.menu-submenu {
  flex-direction: column;
  align-items: flex-start;
}
.menu-submenu-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1.5rem;
  color: white;
}
</style>
