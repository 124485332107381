<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div class="responsive-bar">
      <Footer></Footer>
      <div class="adddate">
        <div class="back-icon">
          <img
            src="../assets/previous.svg"
            width="30"
            @click="backtoHistory()"
          />
        </div>
        <div class="">
          <h1 class="header-text">
            <div class="topic">
              {{
                page === "preadd"
                  ? $t("adddate.add_pre_date")
                  : $t("adddate.add_date")
              }}
            </div>
          </h1>
        </div>
        <div class="mt-4">
          <div class="pt-4">
            <div class="mb-4">
              <p class="text-left">{{ $t("adddate.start") }}</p>
              <div class=" mx-auto" style="width:70%">
                <date-picker
                  class="h-full"
                  locale="en"
                  v-model="counting_date"
                  :min-date="min_date"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <input
                      :value="inputValue | formatDate"
                      class="form-control date-picker input-date"
                      readonly
                      @click="togglePopover()"
                    />
                  </template>
                </date-picker>
              </div>
            </div>
            <button
              class="btn btn-auto"
              :disabled="isDisabled"
              @click="$bvModal.show('confirmAddDate')"
            >
              {{ $t("adddate.add") }}
            </button>
          </div>

          <div class="line-break"></div>

          <b-modal
            class="p-2"
            id="confirmAddDate"
            hide-header-close
            hide-header
            hide-footer
            centered
          >
            <div class="modal-body">
              <!-- Fixed here -->
              <p>
                {{
                  $t("count.set_countdate", {
                    date: formatDate(counting_date),
                  })
                }}
              </p>
              <!-- <p>
                    {{ $t("count.set_countdate_remind") }}
                  </p> -->
            </div>
            <div class="modal-footer align-items-center justify-content-center">
              <button
                style="width: 45%"
                type="button"
                class="btn decline-button"
                @click="$bvModal.hide('confirmAddDate')"
              >
                {{ $t("btn.canc") }}
              </button>

              <button
                style="width: 45%"
                type="button"
                class="btn"
                @click="addCountingDay"
              >
                {{ $t("btn.conf") }}
              </button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Footer from "@/components/Footer.vue";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  data() {
    return {
      isLoading: false,
      counting_date: "",
      min_date: moment(new Date()).format("YYYY-MM-DD"),
      isDisabled: true,
      page: "",
    };
  },
  watch: {
    counting_date() {
      if (this.counting_date != "") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
  },

  computed: {
    ...mapGetters({
      getCountingDate: "getCountingDate",
    }),
    confirm_date() {
      return this.$t("adddate.conf_date");
      // <strong>{{ formatDate(counting_date) }} </strong>หรือไม่?
    },
  },
  mounted() {
    this.$store.dispatch("getAllCountingDateByIdCompany");
    this.page = this.$route.query.page;
    if (this.page === "preadd") {
      const countingDate = this.getCountingDate.find((each) => {
        return each.is_finish == false;
      });
      if (countingDate) {
        this.min_date = moment(countingDate.counting_date)
          .add(1, "days")
          .format("YYYY-MM-DD");
      }
    }
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  methods: {
    formatDate(date) {
      if (this.$i18n.locale === "th") {
        return moment(date).format("ll");
      } else {
        return moment(date).format("dddd, DD MMMM YYYY");
      }
    },
    backtoHistory() {
      this.$router.push("/history");
    },
    gotoDashboard(arg) {
      this.$router.push(`/dashboard/${arg}`);
      // this.$router.push("/dashboard");
    },
    async addCountingDay() {
      this.$bvModal.hide("confirmAddDate");
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      if (this.counting_date) {
        if (this.page === "preadd") {
          this.isLoading = true;
          await this.$store.dispatch("addCountingDate", {
            id_company: id_company, //user.id_company
            counting_date: moment(this.counting_date)
              .startOf("d")
              .toISOString(),
            type: "pre",
          });
          this.isLoading = false;
          this.countingDate = null;
          this.$router.replace("/history");
        } else {
          this.$store.dispatch("addCountingDate", {
            id_company: id_company, //user.id_company
            counting_date: moment(this.counting_date)
              .startOf("d")
              .toISOString(),
          });
          this.countingDate = null;
          this.$router.replace("/history");
        }
      } else {
        alert("error");
      }
    },
    convertDate(date) {
      const convertedDate = new Date(date);
      return (
        convertedDate.getDate() +
        "/" +
        (convertedDate.getMonth() + 1) +
        "/" +
        convertedDate.getFullYear()
      );
    },
  },
  components: { Footer, Loading, DatePicker },
};
</script>

<style lang="scss" scoped>
.modal-content {
  border-radius: 1.3rem;
}
input[type="date"],
.date-piceker {
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.btn {
  //   background-color: white !important;
  width: 100px;
}
.input-date {
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.adddate {
  position: static !important;
  // z-index: 2;
}
.back-icon {
  position: absolute;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
}
.btn-regist {
  width: 160px;
  height: 45px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}
.content {
  padding: 0.2rem;
  padding-bottom: 0;
  text-align: left;
  align-self: end;
  justify-self: start;
}

.content-detail {
  font-size: 11px;
}

.content-header {
  font-size: 18px;
}
.detail-block {
  color: #4b4b4b;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  border-bottom: 1px solid#BCBABA;
}
.icon-calendar {
  grid-row: 1 / span 2;
  align-self: center;
}
.icon-calendar img {
  width: 36px;
  color: #4b4b4b;
}

.card-form {
  margin: auto;
  width: 80%;
  padding-bottom: 4rem;
}
.header-text {
  padding-top: 30px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font-size: 28px;
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}
.decline-button {
  background: grey !important;
}
</style>
