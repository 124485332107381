export const mutations = {
  setStatusFromDashboard(state, payload) {
    state.statusFromDashboard = payload;
    // console.log(payload)
  },
  setDashboardData(state, payload) {
    state.dashboardData = payload;
  },
  setStatusSummary(state, payload) {
    state.statusSummary = payload;
  },
  setMaintenanceRole(state, payload) {
    state.maintenanceRole = payload;
  },
  setComfirmModalSuccess(state, payload) {
    state.confirmModalSuccess = payload;
  }
};
