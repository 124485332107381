import Cookie from "js-cookie";
import { rpcClient } from "../rpc/utils/rpcClient";
import { featureFlags } from "../native-app-feature-flag/index.js";
export const mutations = {
  setCustomName(state, payload) {
    state.customName = payload;
    if (featureFlags.customName) {
      rpcClient.stub.setCustomName(payload);
    }
  },
  genQrCodeMutations(state, payload) {
    state.qr_all_gen = payload;
    // console.log("qr code that gen is ->", state.qr_all_gen);
  },
  getAssetMutations(state, payload) {
    state.asset = payload;
    // console.log("This asset is ==>", state.asset);
  },
  regisAssetMutations(state, payload) {
    state.result_regis_asset = payload;
    // console.log("asset is ->", state.result_regis_asset);
  },

  setAssetSelectedRegister(state, payload) {
    state.assetSelectedRegister = payload;
  },

  setAllAssetFilter(state, payload) {
    state.asset_filter = payload;
  },
  saveManageAssetFilter(state, payload) {
    state.manage_asset_filter = payload;
  },

  firstSetAllAsset(state, payload) {
    state.all_asset_store = payload.tableData;
    state.all_asset = payload.tableData;
    if (payload.otherColumn) {
      state.asset_other_column = payload.otherColumn;
    }
    state.all_asset_count = payload.assets_count;

    // console.log("all asset store ->", state.all_asset);
  },

  setOptionChoice(state, payload) {
    state.filterOption = payload;
  },

  setDashboardFiltered(state, payload) {
    state.dashboard_filter = payload;
    // console.log("all asset filtered by dashboard->", state.dashboard_filter);
  },

  setDashboardModalFilter(state, payload) {
    state.dashboard_modal_filter = payload;
  },

  getAllAssetsMutations(state, payload) {
    state.all_asset = payload;
    // console.log("get assets: ", payload);
    // console.log("all asset is ->", state.all_asset);
  },
  //  getAllAssetsDashboarMutations(state, payload) {
  //   state.all_asset = payload;
  //   // console.log("get assets: ", payload);
  //   console.log("all asset is ->", state.all_asset);
  // },

  checkAssetMutations(state, payload) {
    state.check_asset = payload;
    // console.log("check asset is ->", state.check_asset);
  },

  createCheckAssetMutations(state, payload) {
    state.create_check_asset = payload;
    // console.log("create_check_asset is ->", state.create_check_asset);
  },

  getReportCountMutation(state, payload) {
    state.report_asset = payload;
  },
  getDetailAssetsMutations(state, payload) {
    state.detailAsset = payload;
  },
  getDetailAssetsRegisterNowMutations(state, payload) {
    state.detailAssetRegisterNow = payload;
  },

  reportAssetLostMutations(state, payload) {
    // state.editlAsset = payload;
    state.detailAsset.asset.is_lost = "report";
    state.detailAsset.asset.note = payload;
    console.log("report lost: ", state.detailAsset.asset);
  },
  // confirmAssetLostMutations(state, payload) {
  //   // state.editlAsset = payload;
  //   state.all_asset[payload - 1].is_lost = "lost";
  //   console.log("get all asset confirm lost: ", state.all_asset[payload - 1]);
  // },

  editAssetMutations(state, payload) {
    state.editlAsset = payload;
    // state.all_asset[payload - 1].is_lost = "lost";
    // console.log("get all asset confirm lost: ", state.all_asset[payload - 1]);
  },

  uploadPictureAssetMutations(state, payload) {
    state.pictureAsset = payload;
    // console.log("uploadPictureAssetMutations is ->", state.pictureAsset);
  },
  loginMutations(state, payload) {
    state.login = payload;
    // console.log("login is ->", state.login);
  },
  loginStatusMutations(state, payload) {
    state.login_password_invalid = payload;
    // console.log("password invalid is ->", state.login_password_invalid);
  },
  logout(state) {
    state.permissionStatus = {};
    state.user = {};
    state.customName = null;
  },
  userRegisterMutations(state, payload) {
    state.user_register = payload;
    // console.log("userRegister is ->", state.user_register);
  },
  getUserMutations(state, payload) {
    const user = state.user;
    state.user = { ...user, ...payload };
  },

  getEditUser(state, payload) {
    const user = state.editUser;
    state.editUser = { ...user, ...payload };
  },

  getSelectedUserMutations(state, payload) {
    state.selected_user = payload;
  },
  getAssetAmountMutations(state, payload) {
    state.asset_amount = payload;
    // console.log("assetamount is ->", state.asset_amount);
  },
  getAssetUsedMutations(state, payload) {
    state.asset_used = payload;
    // console.log("getAssetUsedis ->", state.asset_used);
  },
  getAssetDamagedMutations(state, payload) {
    state.asset_damaged = payload;
    // console.log("getAssetDamagedis ->", state.asset_damaged);
  },
  getAllSumaryMutations(state, payload) {
    state.all_sumary = payload;
    // console.log("getAllSumaryis ->", state.all_sumary);
  },
  getMapUrlMutations(state, payload) {
    state.map_url = payload;
    // console.log("map URL ->", state.map_url);
  },

  setMapUrlMutations(state, payload) {
    state.map_url = payload;
    // console.log("map URL ->", state.map_url);
  },
  getMapUrlRegisterNowMutations(state, payload) {
    state.map_url = payload;
    // console.log("map URL ->", state.map_url);
  },
  setMapUrlRegisterNowMutations(state, payload) {
    state.map_url = payload;
    // console.log("map URL ->", state.map_url);
  },
  deleteMapMutations(state, payload) {
    let index = state.floor.findIndex((map) => {
      return map.id_location_floor === payload;
    });
    state.all_map.splice(index, 1);
    // console.log("delete map: ", state.all_map);
  },
  setMapPositionMutations(state, payload) {
    state.map_position = payload;
    // console.log(" -> state.map_position", state.map_position);
  },
  setMapPositionRegisterNowMutations(state, payload) {
    state.map_position = payload;
    // console.log(" -> state.map_position", state.map_position);
  },
  editUserMutations(state, payload) {
    state.user = payload;
    // console.log(" -> editUserMutations", state.user);
  },
  setAllUser(state, payload) {
    // console.log("set all user: ", payload.data.data);
    state.all_user = payload;
    // console.log("set state all user", state.all_user);
  },
  deleteUser(state, payload) {
    let index = state.all_user.data.data.findIndex((user) => {
      return user.id_user === payload;
    });
    state.all_user.data.data.splice(index, 1);
    // console.log("-> delete user ", state.all_user.data.data, index);
  },
  createMapMutations(state, payload) {
    state.map = payload;
    state.all_map.push(payload);
    // console.log(" -> map", state.all_map);
    // console.log(" -> map", state.map);
  },
  getFloorMutaions(state, payload) {
    state.floor = payload;
  },
  getCountFloorMutaions(state, payload) {
    state.count_floor = payload;
  },
  sendAllMapMutations(state, payload) {
    state.all_map = payload;
    // console.log(" -> all map", state.all_map);
  },
  importRegisAssetMutations(state, payload) {
    state.regis_asset_result = payload;
    // console.log("regis_asset->", state.regis_asset_result);
  },
  importRegisAssetCheckMutations(state, payload) {
    state.regis_asset_check = payload;
    console.log("Imported->", state.regis_asset_check);
  },
  getimportRegisAssetMutations(state, payload) {
    state.regis_asset = payload;
    // console.log("regis_asset->", state.regis_asset);
  },
  setRegisStatus(state, payload) {
    state.register_success = payload;
    // console.log("register_status", state.register_success);
  },
  setCountingDate(state, payload) {
    state.counting_date = payload;
    // console.log("counting_date", state.counting_date);
  },
  setSummaryData(state, payload) {
    state.summary_data = payload;
    // state.filterOption = payload.result.options;
    // console.log("summary_data", state.summary_data);
  },
  setCompanyDetail(state, payload) {
    state.company_detail = payload;
    // console.log("company_detail", state.company_detail);
  },
  setAdminPage(state, payload) {
    state.admin_page = payload;
    // console.log("page: ", state.admin_page);
  },
  setSignature(state, payload) {
    state.signature = payload;
    // console.log("signature url: ", state.signature);
  },
  setAlertTrue(state, payload) {
    state.isAlert = payload;
  },
  setPermission(state, payload) {
    state.permissionStatus = payload;
  },
  setAssetDashboard(state, payload) {
    state.asset_dashboard = payload;
  },
  isFromProfileMutation(state, payload) {
    state.isFromProfile = payload;
  },
  setRegisManual(state, payload) {
    state.regisManual = payload;
  },
  setMaintenanceData(state, payload) {
    state.all_maintenance_store = payload;
  },
  // setAllMaintenanceFilter(state, payload) {
  //   state.maintenance_filter = payload;
  // },

  setCategory(state, payload) {
    state.category = payload;
  },
  delCategory(state, payload) {
    state.delcategory = payload;
  },
  updateCategory(state, payload) {
    state.updatecategory = payload;
    console.log("updateCategory", state.updatecategory);
  },
  setSubCategory(state, payload) {
    state.subcategory = payload;
  },

  setAllDepartments(state, payload) {
    state.alldepartment = payload;
  },
  setDepartments(state, payload) {
    state.departments = payload;
  },
  setAllZone(state, payload) {
    state.allzone = payload;
  },
  setZone(state, payload) {
    state.zone = payload;
  },
  setAllColumn(state, payload) {
    state.allColumn = payload;
  },
  setDeletedAsset(state, payload) {
    state.deleted_asset = payload;
  },
  setMaintenancePlanAsset(state, payload) {
    state.selected_maintenance_asset = payload;
  },
  setMaintenancePlanData(state, payload) {
    state.maintenance_plan_data = payload;
  },
  setSector(state, payload) {
    state.sector = payload;
  },
  setSectorSelect(state, payload) {
    state.sectorSelect = payload;
  },
  setBranch(state, payload) {
    state.branch = payload;
  },
  setVendor(state, payload) {
    state.vendor = payload;
  },

  setPageState(state, payload) {
    state.pageState = payload;
  },

  setDepreciationNotification(state, payload) {
    state.dpRequest = payload;
  },

  setNoteAccounting(state, payload) {
    state.noteAccounting = payload;
  },

  setNoteAccountingSelect(state, payload) {
    state.noteAccountingSelect = payload;
  },

  setOptionAllIdFixAssets(state, payload) {
    state.optionAllIdFixAssets = payload;
  },
  setPackageLimitation(state, payload) {
    state.packageLimit = payload;
  },
};
