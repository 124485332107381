<template>
  <Teleport to="body">
    <transition name="fade">
      <div v-if="showModal">
        <div class="backdrop"></div>
        <div class="modal-container" :style="modalWidth">
          <div class="position-relative">
            <button
              v-if="showCloseButton"
              class="close_modal text-light border-none p-0 position-absolute"
              style="border-radius: 9999px; right: 15px; top: 15px"
              @click="closeModal"
            >
              <b-icon-x scale="1.5" />
            </button>
            <div class="p-3">
              <template v-if="isForm">
                <form @submit.prevent="submitForm">
                  <slot></slot>
                  <div
                    class="d-flex justify-content-center footer"
                    v-if="!hideButton"
                  >
                    <button
                      class="mx-3 cancel"
                      type="button"
                      :style="{ backgroundColor: cancelButtonColor }"
                      @click="cancelAction"
                    >
                      <p class="m-0">{{ cancelButtonText }}</p>
                    </button>
                    <button
                      class="mx-3 confirm"
                      type="submit"
                      :style="{ backgroundColor: confirmButtonColor }"
                    >
                      <p class="m-0">{{ confirmButtonText }}</p>
                    </button>
                  </div>
                </form>
              </template>
              <template v-else>
                <slot></slot>
                <div
                  class="d-flex justify-content-center footer"
                  v-if="!hideButton"
                >
                  <button
                    class="mx-3 cancel"
                    @click="cancelAction"
                    :style="{ backgroundColor: cancelButtonColor }"
                  >
                    <p class="m-0">{{ cancelButtonText }}</p>
                  </button>
                  <button
                    class="mx-3 confirm"
                    @click="confirmAction"
                    :style="{ backgroundColor: confirmButtonColor }"
                  >
                    <p class="m-0">{{ confirmButtonText }}</p>
                  </button>
                </div></template
              >
            </div>
            <slot name="after"></slot>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
import Teleport from "vue2-teleport";
export default {
  emits: ["hide-modal", "confirm-action", "cancel-action", "submitForm"],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: "M",
    },
    cancelButtonText: {
      type: String,
      default: "ยกเลิก",
      required: false,
    },
    confirmButtonText: {
      type: String,
      default: "ยืนยัน",
      required: false,
    },
    cancelButtonColor: {
      type: String,
      default: "#7c7c7c",
    },
    confirmButtonColor: {
      type: String,
      default: "#007afe",
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Teleport,
  },
  computed: {
    modalWidth() {
      switch (this.size) {
        case "S":
          return { "max-width": "300px" };
        case "M":
          return { "max-width": "500px" };
        case "L":
          return { "max-width": "800px" };
        case "XL":
          return { "max-width": "100%" };
        default:
          return { "max-width": "500px" };
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("hide-modal");
    },
    confirmAction() {
      this.$emit("confirm-action");
    },
    cancelAction() {
      this.$emit("cancel-action");
    },
    submitForm() {
      this.$emit("submit-form");
    },
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 100;
}
.modal-container {
  position: fixed;
  width: 80%;
  // max-width: 500px;
  background-color: #fff;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 101;
}
.footer {
  margin: 30px 0 0 0;
}
button {
  width: 155px;
  padding: 5px;
  border: none;
  color: #fff;
  border-radius: 8px;
  // &.cancel {
  //   background: #7c7c7c;
  // }
  // &.confirm {
  //   background: #007afe;
  // }
}
/* Animation */
.fade-enter-active,
.fade-leave-active {
  position: relative;
  z-index: 100;
  transition: opacity 300ms ease;
}
.fade-enter,
.fade-leave-to {
  position: relative;
  z-index: 100;
  opacity: 0;
}
</style>
