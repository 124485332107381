<template>
  <div class="responsive-bar-admin">
    <div
      @click="gotoHome()"
      class="responsive-bar-admin-custom"
      style="cursor: pointer"
    >
      <img src="@/assets/Logo2.png" width="60px" height="60px" />
    </div>
    <div class="responsive-bar-admin-menu">
      <span @click="showAssetSubMenu = !showAssetSubMenu">{{
        $t("asset.as_register")
      }}</span>
      <div v-show="showAssetSubMenu" class="responsive-bar-admin-submenu">
        <div
          @click="gotoManageAsset()"
          :style="$route.name == 'AdminManageAsset' ? 'color: #007afe' : ''"
        >
          <span>{{ $t("asset.manage_data") }}</span>
        </div>
        <div
          @click="gotoManageColumnName()"
          :style="$route.name == 'ManageColumnName' ? 'color: #007afe' : ''"
        >
          <span>{{ $t("asset.manage_column_name") }}</span>
        </div>
      </div>
    </div>
    <div
      @click="gotoManageUser()"
      class="responsive-bar-admin-menu"
      :style="$route.name == 'AdminManageUser' ? 'color: #007afe' : ''"
    >
      <span>{{ $t("home.user") }}</span>
    </div>
    <div
      @click="gotoMap()"
      class="responsive-bar-admin-menu"
      :style="$route.name === 'AdminMap' || $route.name === 'ManageRoom' ? 'color: #007afe' : ''"
    >
      <span>{{ $t("home.buildpl") }}</span>
    </div>
    <div
      @click="gotoCatAssets()"
      class="responsive-bar-admin-menu"
      :style="$route.name == 'AdminCatAssets' ? 'color: #007afe' : ''"
    >
      <span> {{ $t("home.cat_assets") }}</span>
    </div>
    <div
      @click="gotoManageDepartments()"
      class="responsive-bar-admin-menu"
      :style="$route.name == 'ManageDepartment' ? 'color: #007afe' : ''"
    >
      <span> {{ $t("department.name") }}</span>
    </div>
    <div
      @click="gotoManageZone()"
      class="responsive-bar-admin-menu"
      :style="$route.name == 'ManageZone' ? 'color: #007afe' : ''"
    >
      <span> {{ $t("zone.name") }}</span>
    </div>
    <div v-if="isCustom">
      <customMenuBar
        :customid="permissionStatus.company.custom_id"
      ></customMenuBar>
    </div>
    <div class="responsive-bar-admin-menu">
      <span @click="showLogSubMenu = !showLogSubMenu">{{
        $t("asset.log_topic")
      }}</span>
      <div v-show="showLogSubMenu" class="responsive-bar-admin-submenu">
        <div
          @click="gotoAssetAllEditLog()"
          :style="$route.name == 'AssetAllEditLog' ? 'color: #007afe' : ''"
        >
          <span>{{ $t("asset.general_log") }}</span>
        </div>
        <!-- TODO - remove old maintenance -->
        <!-- <div
          @click="gotoAssetMaintenanceLog()"
          :style="$route.name == 'AssetMaintenanceLog' ? 'color: #007afe' : ''"
        >
          <span>{{ $t("asset.maintenance_log") }}</span>
        </div> -->
        <div
          @click="gotoDeletedAssetLog()"
          :style="$route.name == 'DeletedAssetLog' ? 'color: #007afe' : ''"
        >
          <span>{{ $t("asset.deleted_log") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import customMenuBar from "@/components/CustomComponent/customMenuBar.vue";
export default {
  data() {
    return {
      showAssetSubMenu: false,
      showLogSubMenu: false,
    };
  },
  components: {
    customMenuBar,
  },
  mounted() {
    if (
      this.$route.name == "AdminManageAsset" ||
      this.$route.name == "ManageColumnName"
    ) {
      this.showAssetSubMenu = true;
    }
    if (
      this.$route.name === "DeletedAssetLog" ||
      this.$route.name === "AssetAllEditLog" ||
      this.$route.name === "AssetMaintenanceLog"
    ) {
      this.showLogSubMenu = true;
    }
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),
    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    },
    gotoManageAsset() {
      this.$store.dispatch("setPageState", {
        pageName: "",
        pageNum: 1,
      });
      this.$router.push("/admin/ManageAsset");
    },
    gotoManageColumnName() {
      this.$router.push("/admin/ManageColumnName");
    },
    gotoManageUser() {
      this.$router.push("/admin/ManageUser");
    },
    gotoMap() {
      this.$router.push("/admin/Map");
    },
    gotoCatAssets() {
      this.$router.push("/admin/CatAssets");
    },
    gotoManageDepartments() {
      this.$router.push("/admin/ManageDepartment");
    },
    gotoManageZone() {
      this.$router.push("/admin/ManageZone");
    },
    gotoDeletedAssetLog() {
      this.$router.push("/admin/DeletedAssetLog");
    },
    gotoAssetAllEditLog() {
      this.$router.push("/admin/AssetAllEditLog");
    },
    gotoAssetMaintenanceLog() {
      this.$router.push("/admin/AssetMaintenanceLog");
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-bar-admin-menu {
  cursor: pointer;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  z-index: 99;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
  &-submenu {
    padding-left: 1rem;
    & div {
      padding-top: 1rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .responsive-bar-admin {
    display: none;
    box-shadow: $shadow-default;
  }
}
</style>
