<template>
  <Teleport to="body">
    <transition name="fade">
      <div v-if="showModal">
        <div class="backdrop"></div>
        <div class="modal-container" :style="modalWidth">
          <!-- if value = assign  -->
          <div
            class="dialog-card align-items-center"
            v-if="selected.value == 'assign' && !success"
          >
            <div class="message d-flex flex-column align-items-center">
              <p class="dialog-header-text m-0 ">
                {{ this.$t("maintenance_report.assign-list") }}
              </p>
            </div>
            <div>
              <p>{{ this.$t("maintenance_report.asign-task") }}</p>
              <div class="assign-detail align-items-center">
                <div class="d-flex justify-content-between m-3">
                  <p>{{ this.$t("maintenance_report.maintain-ref") }}</p>
                  <!-- <p v-if="fromId.maintain_ref == null">
                    RE1234567
                  </p> -->
                  <p>{{ fromId.maintain_ref }}</p>
                </div>
                <div class="d-flex justify-content-between m-3">
                  <p>
                    {{ this.$t("maintenance_report.urgency") }}
                  </p>
                  <!-- <p v-if="fromId.priority == null">ปานกลาง</p> -->
                  <!-- <p>{{ fromId.priority }}</p> -->
                  <p :class="fromId.priority">
                    {{ $t(`maintenance_report.${fromId.priority}`) }}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <label class="mt-2">{{ $t("maintenance_report.assign") }}</label>
              <v-select
                :reduce="(option) => option.value"
                v-model="selectedTechnician"
                :options="optionTechnician"
                :clearable="false"
                label="label"
              >
              </v-select>
            </div>
            <div class="d-flex justify-content-center gap-2 my-1 mt-3">
              <button class="cancel-btn" @click="closeModal">
                {{ this.$t("maintenance_report.cancel") }}
              </button>
              <button class="save-btn" @click="manageRequest(selected.value)">
                {{ this.$t("maintenance_report.save") }}
              </button>
            </div>
            <slot name="after"></slot>
          </div>
          <!-- if value != assign  -->
          <div
            class="dialog-card align-items-center"
            v-if="selected.value !== 'assign' && !success"
          >
            <div class="message d-flex flex-column align-items-center">
              <img :src="selected.icon" alt="icon" width="22px" class="mb-1" />
              <p class="dialog-text m-0 ">
                {{ selected.text }}
              </p>
              <p class="small-text mb-3">
                {{ selected.subText }}
              </p>
            </div>
            <div class="reason mx-1">
              <p>
                {{ this.$t("maintenance_report.description") }}
                <span style="color:red; font-size: 20px; font-weight: 500;"
                  >*</span
                >
              </p>
              <b-form-textarea v-model="manageReason"></b-form-textarea>
            </div>
            <div class="d-flex justify-content-center gap-2 my-1 mt-3">
              <button class="cancel-btn" @click="closeModal">
                {{ this.$t("maintenance_report.cancel") }}
              </button>
              <button
                class="confirm-btn"
                @click="manageRequest(selected.value)"
              >
                {{ this.$t("maintenance_report.confirm") }}
              </button>
            </div>
            <slot name="after"></slot>
          </div>
          <div v-if="success" class="relative">
            <div class="close-success" @click="closeModalSuccess">
              <img src="@/assets/close.svg" />
            </div>
            <div
              class="d-flex flex-column justify-content-center align-items-center my-5"
            >
              <img
                src="@/assets/check_confirm.svg"
                width="40px"
                alt="success"
                class="m-3"
              />
              <p class="mb-3 px-5 text-center" style="font-size: 16px;">
                {{ selected.success }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="successDetail">
        <div class="backdrop"></div>
        <div class="modal-container" :style="modalWidth">
          <!-- if value = assign  -->
          <div class="close-success" @click="closeModalSuccess">
            <img src="@/assets/close.svg" />
          </div>
          <div
            class="d-flex flex-column justify-content-center align-items-center my-5"
          >
            <img
              src="@/assets/check_confirm.svg"
              width="40px"
              alt="success"
              class="m-3"
            />
            <p class="mb-3 px-5 text-center" style="font-size: 16px;">
              {{ this.$t("maintenance_report.confirm-success") }}
            </p>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
import Teleport from "vue2-teleport";
import Reject from "../assets/reject.svg";
import Cancel from "../assets/cancle.svg";
import Reset from "../assets/reset.svg";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      technicianOptions: [],
      selectedTechnician: null,
      selected: "",
      success: false,
      manageReason: "",
      manageOptions: [
        {
          value: "assign",
          text: "test",
          subText: "",
          placeHolder: "",
          success: this.$t("maintenance_report.assign-success"),
          icon: Reject,
        },
        {
          value: "download",
          text: "download",
          subText: "",
          placeHolder: "download",
          icon: Reject,
        },
        {
          value: "reject",
          text: this.$t("maintenance_report.reject-text"),
          subText: "",
          placeHolder: this.$t("maintenance_report.reject-placeholder"),
          success: this.$t("maintenance_report.reject-success"),
          icon: Reject,
        },
        {
          value: "cancel",
          text: this.$t("maintenance_report.cancel-text"),
          subText: this.$t("maintenance_report.cancel-subtext"),
          placeHolder: this.$t("maintenance_report.cancel-placeholder"),
          success: this.$t("maintenance_report.cancel-success"),
          icon: Cancel,
        },
        {
          value: "reset",
          text: this.$t("maintenance_report.reset-text"),
          subText: this.$t("maintenance_report.reset-subtext"),
          placeHolder: this.$t("maintenance_report.reset-placeholder"),
          success: this.$t("maintenance_report.reset-success"),
          icon: Reset,
        },
      ],
    };
  },
  emits: ["hide-modal", "confirm-action", "cancel-action"],
  props: {
    // requestSuccess: {
    //   type: Boolean,
    //   default: false,
    // },
    technicianData: {
      type: Array,
      default: null,
    },
    // from MaintenanceReportDetail
    successDetail: {
      type: Boolean,
      default: false,
    },
    manageFromTable: {
      //{selectedOption, id: this.params.value} from CellFramework
      type: Object,
      default: null,
    },
    // asset detail from MaintenanceReportList -> MaintenanceReportTable
    fromId: {
      type: Object,
      default: null,
    },
    // from MaintenanceReportDetail
    manage: {
      type: String,
      default: "",
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: "M",
    },
    cancelButtonText: {
      type: String,
      default: "ยกเลิก",
      required: false,
    },
    confirmButtonText: {
      type: String,
      default: "ยืนยัน",
      required: false,
    },
    cancelButtonColor: {
      type: String,
      default: "#7c7c7c",
    },
    confirmButtonColor: {
      type: String,
      default: "#007afe",
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Teleport,
  },
  computed: {
    ...mapGetters({ requestSuccess: "maintenance/getComfirmModalSuccess" }),
    modalWidth() {
      switch (this.size) {
        case "SS":
          return { "max-width": "300px" };
        case "S":
          return { "max-width": "450px" };
        case "M":
          return { "max-width": "500px" };
        case "L":
          return { "max-width": "800px" };
        case "XL":
          return { "max-width": "100%" };
        default:
          return { "max-width": "500px" };
      }
    },
    optionTechnician() {
      if (this.technicianData) {
        let list = [
          ...new Set(
            this.technicianData.map((item) => {
              return { label: item.name, value: item.id_technician };
            })
          ),
        ];
        // console.log("Building list: ", list);
        return list;
      }
      return [{ label: this.$t("filter.all"), value: null }];
    },
  },
  updated() {
    // console.log(this.requestSuccess);
    if (this.requestSuccess) {
      this.success = true;
    }
    //from MaintenanceReportdetail
    if (this.manage !== "") {
      this.selected = this.manageOptions.find(
        (option) => option.value === this.manage
      );
      // console.log(this.$props.manage);
    } else if (this.manageFromTable !== null) {
      this.selected = this.manageOptions.find(
        (option) => option.value === this.manageFromTable.selectedOption
      );
      // console.log(this.$props.manageFromTable);
      // console.log(this.$props.fromId);
    }
  },
  methods: {
    manageRequest(value) {
      if (this.manageReason === "" && value !== "assign") {
        alert("โปรดระบุหมายเหตุ");
      } else {
        this.$emit("manage-request", {
          reason: this.manageReason,
          action: value,
          assetDetail: this.$props.fromId,
          assignTo: this.selectedTechnician,
        });
        this.$emit("hide-modal");
      }
    },
    closeModal() {
      this.$store.commit("maintenance/setComfirmModalSuccess", false);
      this.success = false;
      this.$emit("hide-modal");
    },
    closeModalSuccess() {
      this.$store.commit("maintenance/setComfirmModalSuccess", false);
      this.success = false;
      this.$props.successDetail = false;
      this.manageReason = "";
      this.$emit("hide-modal-success");
    },
    confirmAction() {
      this.$emit("confirm-action");
    },
    cancelAction() {
      this.$emit("cancel-action");
    },
  },
};
</script>

<style lang="scss" scoped>
.close-success {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
}
.dialog-header-text {
  font-size: 18px;
  font-weight: 500;
}
.select {
  border-radius: 3px;
}
.assign-detail {
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 3px 3px 3px 3px rgb(236, 236, 236);
  border-radius: 5px;
}
.save-btn {
  background-color: #007afe;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin: 0 10px;
  border: none;
  border-radius: 8px;
  padding: 9px 10px;
  min-width: 48%;
}
.dialog-card {
  padding: 30px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 100;
}
.modal-container {
  position: fixed;
  width: 70%;
  // max-width: 500px;
  background-color: #fff;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 101;
}
.footer {
  margin: 30px 0 0 0;
}
button {
  width: 155px;
  padding: 5px;
  border: none;
  color: #fff;
  border-radius: 8px;
}
/* Animation */
.fade-enter-active,
.fade-leave-active {
  position: relative;
  z-index: 100;
  transition: opacity 300ms ease;
}
.fade-enter,
.fade-leave-to {
  position: relative;
  z-index: 100;
  opacity: 0;
}
.small-text {
  font-size: 12px;
  color: #686868;
  margin: 0;
}
.cancel-btn {
  border: none;
  background-color: #7c7c7c;
  color: #ffffff;
  border-radius: 8px;
  padding: 9px 10px;
  min-width: 48%;
}
.confirm-btn {
  border: none;
  background-color: #ed1616;
  color: #ffffff;
  border-radius: 8px;
  padding: 9px 10px;
  min-width: 48%;
}
.high {
  color: #ff0000;
}
.medium {
  color: #d3a500;
}
.low {
  color: #00ab1b;
}
</style>
