<template>
  <div class="login">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <BackgroundLogo></BackgroundLogo>
    <div v-if="prevRoute == 'ChangePasswordFromEmail'">
      <SuccessDialog
        msg="เปลี่ยนรหัสผ่านสำเร็จ"
        v-show="showSuccess"
      ></SuccessDialog>
    </div>

    <div v-else-if="this.$store.state.register_success">
      <!-- //prevRoute.name == 'Register' -->
      <SuccessDialog
        :msg="$t('dialog.done_reg')"
        v-show="showError"
      ></SuccessDialog>
    </div>

    <div v-show="this.$store.state.login_password_invalid">
      <ErrorDialog :msg="$t('dialog.no_log')"></ErrorDialog>
    </div>

    <div class="card-div" v-if="!isMaintenance || adminPath">
      <div class="card-body">
        <form @submit.prevent="login">
          <div style="padding-bottom: 0.5rem; padding-top: 2rem">
            <p class="text-start" style="color:#818181;">
              {{ $t("login.email") }}
            </p>
            <input
              class="input-group"
              type="email"
              v-model="username"
              required
              autocomplete="username"
            />
          </div>

          <div style="padding-bottom: 1.5rem">
            <p class="text-start" style="color:#818181;">
              {{ $t("login.pw") }}
            </p>
            <div class="position-relative">
              <input
                class="input-group"
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                required
                autocomplete="current-password"
              />
              <img
                v-if="showPassword"
                @click="showPassword = !showPassword"
                src="../assets/showpassword.png"
                alt="showpassword"
                class="password-toggler"
              />
              <img
                v-else
                @click="showPassword = !showPassword"
                src="../assets/hidepassword.png"
                alt="showpassword"
                class="password-toggler"
              />
            </div>
            <div>
              <p class="link-forget" @click="gotoForget()">
                {{ $t("login.forget_pw") }}
              </p>
            </div>
          </div>
          <button class="btn btn-login" type="submit" v-on:click="hideError()">
            {{ $t("btn.login") }}
          </button>
          <div class="pt-4">
            <div class="separator" style="color: #7c7c7c; font-weight: 300">
              {{ $t("login.no_acc") }}
            </div>
            <div class="p-4">
              <button class="btn-regist" type="button" @click="gotoRegis()">
                {{ $t("login.regist") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="container mx-auto" v-else>
      <div class="my-3">
        <div class="my-3">
          <img
            class="w-80"
            style="max-width: 300px"
            src="../assets/Maintenancing/image.png"
            alt=""
          />
        </div>
        <h6 style="line-height: 1.6">
          เเจ้งปิดปรับปรุงระบบ VERSCAN ชั่วคราว
          <br />
          <nobr>ตั้งแต่เวลา 20:00 – 00:00 น.</nobr>
          <br />
          <nobr>ในวันที่ 17-18 ส.ค. 65</nobr>
        </h6>
      </div>
    </div>

    <div class="copyright">
      Copyright © 2021 House of Dev Technology Co., Ltd. All rights reserved.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BackgroundLogo from "@/components/BackgroundLogo.vue";
import SuccessDialog from "@/components/SuccessDialog.vue";
import ErrorDialog from "@/components/ErrorDialog.vue";
import Loading from "vue-loading-overlay";

export default {
  data() {
    return {
      // ------------------- for maintenance ------------------------
      isMaintenance: false,
      // ------------------- for maintenance ------------------------
      isLoading: false,
      prevRoute: null,
      showError: true,
      showSuccess: true,
      showPassword: false,
      username: "",
      password: "",
    };
  },

  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
    }),
    adminPath() {
      return this.$route.params.type === "TesterHOD" ? true : false;
    },
  },

  mounted() {
    this.$store.commit("logout");

    this.$store.commit("isFromProfileMutation", false);
    this.hide();
    this.$store.commit("isFromProfileMutation", false);
  },
  created() {
    console.log(this.$store.state.register_success);
  },
  methods: {
    switchLocale() {
      if (this.$i18n.locale === "th") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "th";
      }
    },
    hide() {
      setTimeout(() => {
        this.showSuccess = false;
        this.$store.commit("setRegisStatus", false);
      }, 2500);
    },
    hideError() {
      setTimeout(() => {
        this.showError = false;
        this.$store.commit("loginStatusMutations", false);
      }, 2500);
    },
    gotoForget() {
      this.$router.push("/forget");
    },
    async login(e) {
      this.showError = true;
      e.preventDefault();
      let userSendData = {
        email: this.username,
        password: this.password,
      };
      this.isLoading = true;
      await this.$store.dispatch("login", userSendData);
      this.isLoading = false;
    },

    gotoRegis() {
      if (this.isNativeApp) {
        this.$router.push({ name: "RegisterNative" });
      } else {
        window.open("https://www.verscan.com");
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    username() {
      if (this.username) {
        this.username = this.username.trimStart();
        this.username = this.username.trimEnd();
      }
    },
    password() {
      if (this.password) {
        this.password = this.password.trimStart();
        this.password = this.password.trimEnd();
      }
    },
  },
  components: { BackgroundLogo, SuccessDialog, ErrorDialog, Loading },
};
</script>

<style lang="scss" scoped>
.copyright {
  color: #7c7c7c;
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 100%;
  font-size: 12px;
}
body {
  background: rgba(244, 244, 244);
}
.container {
  display: flex;
  justify-content: center;
}
.link-color {
  color: $color-link;
}
.header-text {
  color: $font-black;
}
.login {
  z-index: 0;
}

.password-toggler {
  position: absolute;
  width: 15px;
  height: auto;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.link-forget {
  display: block;
  padding-top: 10px;
  font-size: 0.75rem;
  color: $color-active-blue;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .card-form {
    background-color: white;
    // height: auto;
    padding: 1.5rem;
    padding-bottom: 2rem;
  }
}
// .card-body {
//   display: flex;
//   justify-content: center;
//   align-content: center;
// }
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid #7c7c7c;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.input-group {
  background-color: #e0e0e0;
}
@media only screen and (min-width: 1024px) {
  .card-body {
    margin-left: 30%;
    margin-right: 30%;
  }
}

@media only screen and (max-height: 564px) {
  .copyright {
    display: none;
  }
} // @media only screen and (min-width: 1024px) {
//   .card-body{
//     display: flex;
//     justify-content: center;
//   }
// }
</style>
