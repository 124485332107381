// ?? local
import store from "../store/index";
export const stage = () => {
  const url = window.location.hostname;
  switch (url) {
    case "app.verscan.com":
    case "prod-app-verscan.web.app":
      return "prod";
    case "pre-prod-web-verscan.web.app":
    case "pre-prod-web-verscan.firebaseapp.com":
    case "pre-prod-web.verscan.com":
      return "pre-prod";
    case "pre-prod-app.verscan.com":
    case "pre-prod-app-verscan.web.app":
    case "pre-prod-app-verscan.firebaseapp.com":
      return "uat";
    case "staging-app-verscan.web.app":
    case "staging-app.verscan.com":
      return "uat";
    case "dev-app-verscan.web.app":
    case "dev-app.verscan.com":
      return "dev";
    case "localhost":
      return "dev";
    default:
      return null;
  }
};
export const baseUrl = (customName) => {
  const custom = customName || store.getters.getCustomName;
  if (custom === "jagota") {
    switch (stage()) {
      case "prod":
      case "pre-prod":
        return "https://api-op.verscan.com/jagota/prod/";
      case "uat":
        return "https://api-op.verscan.com/jagota/uat/";
      case "dev":
        return "https://api.verscan.com/dev/";
      case "local":
        // return "https://api.verscan.com/dev/";
        // return "http://192.168.0.126:3000/";
        return "http://teerawats-macbook-air.local:3001/";
      default:
        return "http://localhost:3000/";
    }
  }
  switch (stage()) {
    case "prod":
      return "https://prod-api.verscan.com/";
    case "pre-prod":
      return "https://pre-prod-api.verscan.com/";
    case "uat":
      return "https://api.verscan.com/uat/";
    case "dev":
      return "https://api.verscan.com/dev/";
    case "local":
      return "https://api.verscan.com/dev/";
    default:
      return "http://localhost:3000/";
  }
};
export const borrowUrl = () => {
  switch (stage()) {
    case "prod":
      return "https://prod-api-borrow.verscan.com/v1.0/";
    case "pre-prod":
      return "https://api.verscan.com/borrow-pre-prod/v1.0/";
    case "uat":
      return "https://api.verscan.com/borrow-uat/v1.0/";
    case "dev":
      return "https://api.verscan.com/borrow-dev/v1.0/";
    case "local":
      return "https://api.verscan.com/borrow-dev/v1.0/";
    default:
      return "http://localhost:3000/";
  }
};

export const exportUrl = () => {
  const custom = store.getters.getCustomName;
  if (custom === "jagota") {
    switch (stage()) {
      case "prod":
      case "pre-prod":
        return "https://api-op.verscan.com/jagota/prod/";
      case "uat":
        return "https://api-op.verscan.com/jagota/uat/";
      case "dev":
        return "https://api2.verscan.com/dev/";
      case "local":
        return "https://api2.verscan.com/dev/";
      default:
        return "http://localhost:3000/";
    }
  }
  switch (stage()) {
    case "prod":
      return "https://prod-api.verscan.com/";
    case "pre-prod":
      return "https://pre-prod-api.verscan.com/";
    case "uat":
      return "https://api.verscan.com/uat/";
    case "dev":
      return "https://api.verscan.com/dev/";
    case "local":
      // return "https://api.verscan.com/dev/";
      return "http://teerawats-macbook-air.local:3001/";
    default:
      return "http://localhost:3000/";
  }
};

export const vpsUrl = () => {
  const custom = store.getters.getCustomName;
  if (custom === "jagota") {
    switch (stage()) {
      case "prod":
      case "pre-prod":
        return "https://api-op.verscan.com/jagota/prod/";
      case "uat":
        return "https://api-op.verscan.com/jagota/uat/";
      case "dev":
        return "https://api2.verscan.com/dev/";
      case "local":
        return "https://api2.verscan.com/dev/";
      default:
        return "http://localhost:3000/";
    }
  }
  switch (stage()) {
    case "prod":
      return "https://api2.verscan.com/prod/";
    case "pre-prod":
      return "https://api2.verscan.com/prod/";
    case "uat":
      return "https://api2.verscan.com/uat/";
    case "dev":
      return "https://api2.verscan.com/dev/";
    case "local":
      return "https://api2.verscan.com/dev/";
    default:
      return "http://localhost:3000/";
  }
};

export const uploadUrl = () => {
  const custom = store.getters.getCustomName;
  if (custom === "jagota") {
    return "https://api-op.verscan.com/jagota/file/api/upload/image";
  }
  switch (stage()) {
    case "prod":
      return "https://image-test-dot-verscan-test-378604.as.r.appspot.com/image/";
    case "pre-prod":
      return "https://image-test-dot-verscan-test-378604.as.r.appspot.com/image/";
    case "uat":
      return "https://api.verscan.com/file/api/file/create/";
    case "dev":
      return "https://api.verscan.com/file/api/file/create/";
    case "local":
      return "https://api.verscan.com/file/api/file/create/";
    default:
      return "https://api.verscan.com/file/api/file/create/";
  }
};
export default baseUrl;
