<template>
  <div>
    <!-- <Background></Background> -->

    <!-- // !! for slide test -->
    <!-- <button data-toggle="modal" data-target="#exampleModal">test</button> -->

    <!-- Button trigger modal -->

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <b-modal
      class="p-2"
      hide-header-close
      hide-header
      hide-footer
      id="importCheckModal"
      centered
    >
      <p class="text-center">ระบบกำลังประมวลผลข้อมูล</p>
      <br />
      <p class="text-center">กรุณารอสักครู่</p>
    </b-modal>
    <div>
      <div class="back-icon">
        <img src="../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1 class="header-text" style="overflow-x: hidden">
        <div class="topic">{{ $t("asset.as_regdoc") }}</div>
      </h1>
      <modal name="dupmodal" width="90%" height="auto" scrollable>
        <div class="div-export-h">
          <a class="text-export-h">{{ $t("asset.conf_im") }}</a>
          <!-- <p>ข้อมูลสินทรัพย์ใหม่ {{ getdataimport.result.new }} รายการ</p>
          <p>ข้อมูลสินทรัพย์มีการแก้ไข {{ getdataimport.result.change }} รายการ</p> -->
          <p class="mt-3">
            {{ getdataimport.result ? getdataimport.result.new : null }}
            {{ $t("asset.as_newdt") }}
            |
            {{ getdataimport.result ? getdataimport.result.change : null }}
            {{ $t("asset.as_fixdt") }}
          </p>
        </div>
        <div
          class="justify-content-center text-center mt-5"
          style="padding-top: 35px"
        >
          <div class="div-export-table">
            <!-- Ag Grid ข้อมูลมีซ้ำ -->
            <asset-table
              :tag="true"
              :dataTable="getdataimport.dataNotDuplicate"
              :otherColumn="getdataimport.otherColumn"
            />
            <p style="color: red; text-align: left">
              {{ $t("asset.replace_dt") }}
            </p>
            <p style="color: red; text-align: left" v-if="canUseCustom5">
              *กรณีที่ชื่อ หมวดหมู่, หมวดหมู่ย่อย, BU, สาขา, ชั้น
              ไม่ตรงกับข้อมูลในระบบจะไม่สามารถนำเข้าข้อมูลได้
            </p>
          </div>
        </div>
        <div class="button-bar-filter mt-5 mb-3">
          <button
            class="button-load"
            @click="importupdate"
            v-if="
              getdataimport
                ? getdataimport.result
                  ? getdataimport.result.wrongData === 0 &&
                    getdataimport.result.error === 0
                  : false
                : false
            "
          >
            {{ $t("btn.im_update") }}
          </button>
          <button class="button-filter" @click="closemodal">
            {{ $t("btn.canc") }}
          </button>
        </div>
      </modal>
    </div>

    <!-- Filter Modal -->
    <b-modal
      id="filter-modal"
      @cancel="handleclear"
      :cancel-title="$t('btn.clear')"
      hide-header-close
      hide-header
      :ok-title="$t('btn.conf')"
      @ok="handleFilter"
    >
      <b-row>
        <b-col class="text-end" cols="2"> </b-col>

        <b-col class="text-center" cols="8">
          <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
        </b-col>
        <b-col class="text-end" cols="2">
          <button
            class="close_modal text-light border-none"
            @click="$bvModal.hide('filter-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-col>
      </b-row>
      <custom2Filter
        v-if="isCustom"
        :customid="permissionStatus.company.custom_id"
        :options="optionChoice"
        :filterOptions="filterOptions"
        @set-filter-option="addCustomFilter"
      />
      <label class="mt-2">{{ $t("home.cat") }}</label>
      <v-select
        :options="optionCategory"
        label="label"
        :reduce="(option) => option.value"
        v-model="filterOptions.id_category"
        class="dropdown-style"
        :clearable="false"
      />
      <!-- <select
          class="form-control form-border"
          v-model="filterOptions.category"
        >
          <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
          <option
            v-for="(cate, index) in optionCategory"
            :key="index"
            :value="cate"
          >
            {{ cate }}
          </option>
        </select> -->
      <label class="mt-2">{{ $t("home.sub_cat") }}</label>
      <v-select
        :options="optionSubCategory"
        label="label"
        :reduce="(option) => option.value"
        v-model="filterOptions.id_sub_category"
        class="dropdown-style"
        :clearable="false"
      />
      <custom4Filter
        v-if="isCustom"
        :customid="permissionStatus.company.custom_id"
        :options="optionChoice"
        :filterOptions="filterOptions"
        @set-filter-option="addCustomFilter"
      />
      <!-- <select
          class="form-control form-border"
          v-model="filterOptions.category"
        >
          <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
          <option
            v-for="(cate, index) in optionCategory"
            :key="index"
            :value="cate"
          >
            {{ cate }}
          </option>
        </select> -->
      <label class="mt-2">{{ $t("home.as_stat") }}</label>
      <select class="form-control form-border" v-model="filterOptions.status">
        <option
          v-for="(stat, index) in statusData"
          :key="index"
          :value="stat.value"
        >
          {{ stat.name }}
        </option>
      </select>
      <label class="mt-2">{{ $t("home.build") }}</label>
      <v-select
        :options="optionBuilding"
        label="label"
        :reduce="(option) => option.value"
        v-model="filterOptions.id_location_building"
        class="dropdown-style"
        :clearable="false"
      />
      <!-- <select
          class="form-control form-border"
          v-model="filterOptions.building"
        >
          <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
          <option
            v-for="(list, index) in optionBuilding"
            :key="index"
            :value="list"
          >
            {{ list }}
          </option>
        </select> -->
      <label class="mt-2">{{ $t("home.fl") }}</label>
      <!-- <select class="form-control form-border" v-model="filterOptions.floor">
          <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
          <option
            v-for="(list, index) in optionFloor"
            :key="index"
            :value="list"
          >
            {{ list }}
          </option>
        </select> -->
      <v-select
        :options="optionFloor"
        label="label"
        :reduce="(option) => option.value"
        v-model="filterOptions.id_location_floor"
        class="dropdown-style"
        :clearable="false"
      />

      <custom8Filter
        v-if="isCustom"
        :options="optionChoice"
        :filterOptions="filterOptions"
        @set-filter-option="addCustomFilter"
      />

      <label class="mt-2">{{ $t("home.department") }}</label>
      <v-select
        :options="optionDepartment"
        label="label"
        :reduce="(option) => option.value"
        v-model="filterOptions.id_location_department"
        class="dropdown-style"
        :clearable="false"
      />

      <label class="mt-2">{{ $t("home.zone") }}</label>
      <v-select
        :options="optionZone"
        label="label"
        :reduce="(option) => option.value"
        v-model="filterOptions.id_location_zone"
        class="dropdown-style"
        :clearable="false"
      />
      <!-- <select class="form-control form-border" v-model="filterOptions.zone">
          <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
          <option
            v-for="(list, index) in optionZone"
            :key="index"
            :value="list"
          >
            {{ list }}
          </option>
        </select> -->

      <div v-if="!isRoleLower">
        <label class="mt-2">{{ $t("filter.purchase_date") }}</label>

        <div class="d-flex align-items-center w-100">
          <input
            type="date"
            class="form-control form-border"
            v-model="filterOptions.datePurchase_min"
          />

          <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

          <input
            type="date"
            class="form-control form-border"
            :min="filterOptions.datePurchase_min"
            v-model="filterOptions.datePurchase_max"
          />
        </div>

        <label class="mt-2">{{ $t("filter.purchase_price") }}</label>

        <div class="d-flex align-items-center w-100">
          <vue-numeric
            separator=","
            decimal-separator="."
            :precision="2"
            class="w-100 form-control form-border"
            :placeholder="$t('filter.lowest_price')"
            v-model="filterOptions.purchase_price_min"
          ></vue-numeric>

          <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

          <vue-numeric
            separator=","
            decimal-separator="."
            :precision="2"
            class="w-100 form-control form-border"
            :placeholder="$t('filter.highest_price')"
            v-model="filterOptions.purchase_price_max"
          ></vue-numeric>
        </div>

        <label class="mt-2">{{ $t("filter.useful_life") }}</label>
        <div class="d-flex align-items-center w-100">
          <vue-numeric
            class="w-100 form-control form-border"
            :placeholder="$t('filter.lowest')"
            v-model="filterOptions.useful_life_min"
          ></vue-numeric>

          <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

          <vue-numeric
            class="w-100 form-control form-border"
            :placeholder="$t('filter.highest')"
            v-model="filterOptions.useful_life_max"
          ></vue-numeric>
        </div>

        <label class="mt-2">{{ $t("filter.date_start") }}</label>

        <div class="d-flex align-items-center w-100">
          <input
            type="date"
            class="form-control form-border"
            v-model="filterOptions.dateUsed_start"
          />

          <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

          <input
            type="date"
            class="form-control form-border"
            :min="filterOptions.dateUsed_start"
            v-model="filterOptions.dateUsed_end"
          />
        </div>

        <!-- <label class="mt-2">{{ $t("filter.acc_depreciation") }}</label>
        <div class="d-flex align-items-center w-100">
          <vue-numeric
            separator=","
            decimal-separator="."
            :precision="2"
            class="w-100 form-control form-border"
            :placeholder="$t('filter.lowest_price')"
            v-model="filterOptions.acc_depreciation_min"
          ></vue-numeric>

          <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

          <vue-numeric
            separator=","
            decimal-separator="."
            :precision="2"
            class="w-100 form-control form-border"
            :placeholder="$t('filter.highest_price')"
            v-model="filterOptions.acc_depreciation_max"
          ></vue-numeric>
        </div>

        <label class="mt-2">{{ $t("filter.net_book") }}</label>
        <div class="d-flex align-items-center w-100">
          <vue-numeric
            separator=","
            decimal-separator="."
            :precision="2"
            class="w-100 form-control form-border"
            :placeholder="$t('filter.lowest_price')"
            v-model="filterOptions.net_book_min"
          ></vue-numeric>

          <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

          <vue-numeric
            separator=","
            decimal-separator="."
            :precision="2"
            class="w-100 form-control form-border"
            :placeholder="$t('filter.highest_price')"
            v-model="filterOptions.net_book_max"
          ></vue-numeric>
        </div> -->
      </div>
      <custom3Filter
        v-if="isCustom"
        :customid="permissionStatus.company.custom_id"
        :options="optionChoice"
        :filterOptions="filterOptions"
        @set-filter-option="addCustomFilter"
      />
    </b-modal>

    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <button class="px-2 email-me" @click="onExportDocRegisAsset(true)">
          {{ $t("btn.send_mail") }}
        </button>

        <button
          v-if="!isNativeApp"
          class="px-2 download-file"
          @click="onExportDocRegisAsset(false)"
        >
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>
    <!-- Export SAP Modal -->
    <b-modal
      class="p-2"
      id="export-SAP-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-SAP-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <button class="px-2 email-me" @click="$refs.sapInputFile.click()">
          {{ $t("btn.im_file") }}
        </button>
        <input
          id="sapInputFile"
          type="file"
          ref="sapInputFile"
          style="display: none; margin: 0px !important font-size: 10px;"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="onFileChange($event, 'SAP')"
        />
        <button
          v-if="!isNativeApp"
          class="px-2 download-file"
          @click="onExportSAP(false)"
        >
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>

    <!-- Modal Import Asset -->
    <modal name="example" width="90%" height="auto" scrollable>
      <div class="div-export-h">
        <a class="text-export-h">{{ $t("docregas.sum_im") }}</a>

        <p class="mt-3" v-if="id_company !== 196">
          {{ $t("docregas.all_imas") }}
          {{ getdataimport.result ? getdataimport.result.total : null }}
        </p>
      </div>
      <div
        class="justify-content-center text-center mt-5"
        style="padding-top: 35px"
      >
        <!-- Ag Grid ยืนยันการนำเข้าข้อมูล -->
        <div class="div-export-table">
          <asset-table
            :dataTable="getdataimport.all"
            :otherColumn="getdataimport.otherColumn"
          />
          <p style="color: red; text-align: left">
            {{ $t("docregas.replace_dt") }}
          </p>
        </div>
      </div>
      <div class="button-bar-filter mt-3 mb-3">
        <button
          class="button-load"
          v-if="
            getdataimport
              ? getdataimport.status === 'success' && getdataimport.result
                ? getdataimport.result.change === 0 &&
                  getdataimport.result.new === 0 &&
                  getdataimport.result.wrongData === 0 &&
                  getdataimport.result.error === 0
                : false
              : false
          "
          @click="noChangeAlert"
        >
          {{ $t("btn.conf") }}
        </button>
        <button class="button-load mt-0" v-else @click="openmodal">
          {{ $t("btn.conf") }}
        </button>
        <button class="button-filter mt-0" @click="closemodal">
          {{ $t("btn.canc") }}
        </button>
      </div>
    </modal>

    <div v-if="progressStatus.is_show" class="progress_bar">
      <p>
        {{ $t("asset.import_by") }} {{ progressStatus.username }}
        <nobr
          >{{ $t("asset.amount") }} {{ this.progressStatus.count }} /
          {{ this.progressStatus.max_assets }}
          {{ $t("asset.data_list") }}</nobr
        >
      </p>
    </div>

    <div class="card-div pt-4">
      <div class="pl-4">
        <div class="text-left pb-2 col text-xl">
          <div class="text-start">
            <p>
              <b>{{ companyName }}</b>
            </p>
            <p v-if="id_company !== 196">
              {{ $t("asset.imported_assets") }}
              {{ packageLimit.imported_asset }} /
              {{ packageLimit.available_asset }}
            </p>
            <p v-if="id_company !== 196">
              {{ $t("asset.registered_assets") }}
              {{ packageLimit.registered_qr }} /
              {{ packageLimit.available_qr }}
            </p>
          </div>
        </div>

        <div class="button-bar overflow-auto pb-2">
          <div class="button-bar-group d-flex align-items-center flex-wrap">
            <button
              for="fusk"
              type="submit"
              class="p-2"
              v-if="isImportCookie && !isSummaryPage"
              @click="$refs.fileInput.click()"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <b-icon-download font-scale="1.5" style="margin-right: 5px" />
                {{ $t("btn.im_file") }}
              </div>
            </button>
            <input
              id="fusk"
              type="file"
              ref="fileInput"
              style="display: none; margin: 0px !important font-size: 10px;"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="onFileChange($event)"
            />
            <button
              @click="$bvModal.show('export-modal')"
              class="p-2"
              :disabled="!canUseDepreciation"
            >
              <div class="d-flex align-items-center justify-content-evenly">
                <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
                {{ $t("btn.ex_file") }}
              </div>
            </button>
            <button
              v-if="canUseCustom7"
              @click="$bvModal.show('export-SAP-modal')"
              class="p-2"
              style="min-width: fit-content;"
            >
              <div class="d-flex align-items-center justify-content-center">
                {{ `${$t("btn.manage_file")} (SAP)` }}
              </div>
            </button>
            <customReplaceTmp />
            <span v-if="isCustom">
              <customDownloadTemplate
                v-bind:customid="permissionStatus.company.custom_id"
              ></customDownloadTemplate>
            </span>
            <a v-else>
              <button
                style="width: fit-content; height: 47px"
                class="download-template"
                @click="downloadStandard()"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                  "
                >
                  <b-icon-file-earmark-arrow-down
                    font-scale="1.5"
                    style="margin-right: 3px"
                  />{{ $t("btn.tem_as") }}
                </div>
              </button>
            </a>
          </div>

          <div class="group-search">
            <TmpFilter
              :searchTerm="searchText"
              @set-search-term="
                (text) => {
                  searchText = text;
                  searchByText();
                }
              "
            />
            <span
              class="filter-box"
              style="
                border-radius: 10px;
                display: flex;
                padding-left: 1rem;
                margin-right: 1rem;
              "
            >
              <img src="../assets/search-white.svg" width="20px" />
              <input
                type="text"
                class="form-control filter-box"
                style="
                  height: 45px;
                  width: 100%;
                  border-radius: 10px 10px 10px 10px;
                "
                v-debounce:700ms="searchByText"
                :placeholder="$t('home.search')"
                v-model="searchText"
              />
            </span>

            <!-- Filter Button -->
            <button
              v-if="!isSummaryPage || !isFinishedCounting"
              class="filter"
              style="border: none"
              type="button"
              id="button-addon2"
              @click="$bvModal.show('filter-modal')"
            >
              <img src="@/assets/filter-white.svg" />
            </button>
          </div>
        </div>

        <!-- Ag Grid Table Import -->
        <!-- <div v-if="!force" class="div-export-table-first">
          <import-asset-table
            :assetData="datatable"
            :searchText="searchText"
            ref="selectedAsset"
          />
        </div> -->
        <history-asset-table
          v-if="isSummaryPage"
          :assetData="datatable"
          :otherColumn="getAllAssetOtherColumn"
          :page="currentPage"
        />
        <template v-else>
          <MasterTable
            v-if="!force"
            :assetData="datatable"
            :otherColumn="getAllAssetOtherColumn"
            :page="currentPage"
            :perPage="perPage"
            rowSelection="none"
          />
          <div class="div-no-asset" v-else>
            <div class="block">
              <img src="../assets/import-grey.svg" />
              {{ $t("asset.dt_not") }}
              <div class="button-bar justify-content-center mt-2">
                <button
                  for="fusk"
                  class="p-2"
                  v-if="isImportCookie"
                  @click="$refs.fileInput.click()"
                >
                  <div
                    style="
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                  "
                  >
                    <b-icon-download
                      font-scale="1.5"
                      style="margin-right: 5px"
                    />
                    {{ $t("btn.im_file") }}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </template>

        <!-- // !! pageination -->
        <!-- <div class="mt-3 d-flex justify-content-center">
          <span class="pagination">1</span>
        </div> -->
      </div>
      <div class="d-flex justify-content-center mt-3" v-if="data.length > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import Background from "@/components/Background.vue";
// import Signature from "@/components/Signature.vue";
// import XLSX from "xlsx-js-style"; // import xlsx
// import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../util/backend.js";
import { mapGetters } from "vuex";
import { authHeader } from "../store/actions";

import { stage } from "../util/backend";
// import ImportAssetTable from "../components/Table/ImportAssetTable.vue";
import MasterTable from "../components/Table/Depreciation/MasterDepreciationTable.vue";
import HistoryAssetTable from "../components/Table/HistoryAssetTable.vue";
import AssetTable from "../components/Table/AssetTable.vue";
import TmpFilter from "@/components/CustomComponent/customTmpFilter.vue";
import customReplaceTmp from "@/components/CustomComponent/customReplaceTmp.vue";
import customDownloadTemplate from "@/components/CustomComponent/customDownloadTemplate.vue";
import custom2Filter from "../components/CustomComponent/customFilterJGT.vue";
import custom3Filter from "../components/CustomComponent/SubAsset/customFilterBL.vue";
import custom4Filter from "../components/CustomComponent/customFilterNinja.vue";
import custom8Filter from "../components/CustomComponent/Hoshi/customFilterRoom.vue";

export default {
  data() {
    return {
      isLoading: true,
      isFinishedCounting: false,
      canImport: false,
      timer: null,
      force: false,
      title_table: [
        this.$t("asset.fixedas_num"),
        this.$t("asset.as_name"),
        this.$t("asset.as_dt"),
        this.$t("asset.as_cat"),
        this.$t("asset.build"),
        this.$t("asset.fl"),
        this.$t("asset.dep"),
        this.$t("asset.zone"),
      ],
      title_table_import: [
        this.$t("asset.order"),
        this.$t("asset.fixedas_num"),
        this.$t("asset.as_name"),
        this.$t("asset.as_dt"),
        this.$t("asset.as_cat"),
        this.$t("asset.build"),
        this.$t("asset.fl"),
        this.$t("asset.dep"),
        this.$t("asset.zone"),
        this.$t("asset.note"),
      ],
      searchText: "",
      checked: {
        id: [],
        name: [],
        description: [],
        category: [],
        location_building: [],
        location_floor: [],
        location_department: [],
        location_zone: [],
      },
      filterOptions: {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
        dateUsed_start: null,
        dateUsed_end: null,
        datePurchase_min: null,
        datePurchase_max: null,
        useful_life_min: "",
        useful_life_max: "",
        purchase_price_min: "",
        purchase_price_max: "",
        acc_depreciation_min: "",
        acc_depreciation_max: "",
        net_book_min: "",
        net_book_max: "",
      },
      filterdata: [],
      filterShow: false,
      sortOrder: "",
      searchList: "",
      selectColum: "",
      data: "",
      isData: false,
      dataimport: "",
      isImport: false,
      categoryData: [
        { name: this.$t("filter.all"), value: "ทั้งหมด" },
        { name: this.$t("filter.mach"), value: "เครื่องจักร" },
        { name: this.$t("filter.off_eq"), value: "อุปกรณ์สำนักงาน" },
        { name: this.$t("filter.fur"), value: "เครื่องตกแต่งสำนักงาน" },
        { name: this.$t("filter.off_sup"), value: "เครื่องใช้สำนักงาน" },
        { name: this.$t("filter.tools"), value: "เครื่องมือและอุปกรณ์" },
        { name: this.$t("filter.veh"), value: "ยานพาหนะ" },
        { name: this.$t("filter.lease_as"), value: "สินทรัพย์ตามสัญญาเช่า" },
        { name: this.$t("filter.sp_part"), value: "อะไหล่/ชิ้นส่วนสำรอง" },
        { name: this.$t("filter.util"), value: "ระบบสาธารณูปโภค" },
        { name: this.$t("filter.others"), value: "สินทรัพย์อื่น" },
      ],
      statusData: [
        { name: this.$t("filter.all"), value: "ทั้งหมด" },
        { name: this.$t("count.usable"), value: "ใช้งานได้" },
        { name: this.$t("count.not_inuse"), value: "ไม่ได้ใช้งาน" },
        { name: this.$t("count.wait_as"), value: "รอการประเมิน" },
        { name: this.$t("count.repar"), value: "ซ่อมได้" },
        { name: this.$t("count.irrepar"), value: "ซ่อมไม่ได้" },
        { name: this.$t("filter.rep_lost"), value: "report" },
        { name: this.$t("filter.lost"), value: "lost" },
        { name: this.$t("asset.not_reg"), value: "ยังไม่ได้ลงทะเบียน" },
        { name: this.$t("asset.reg"), value: "ลงทะเบียนแล้ว" },
      ],
      progressStatus: {
        timestamp: null,
        max_assets: null,
        username: null,
        count: null,
        is_show: false,
      },
      isShowProgressBar: true,
      id_company: "",
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
    };
  },
  components: {
    Loading,
    MasterTable,
    HistoryAssetTable,
    AssetTable,
    customDownloadTemplate,
    TmpFilter,
    customReplaceTmp,
    custom2Filter,
    custom3Filter,
    custom4Filter,
    custom8Filter,
  },
  async created() {
    // const id_company = await this.$store.dispatch("getCompanyIdCookie");
    // await this.$store.dispatch("firstGetAllAsset", {
    //   company_id: id_company, //this.$store.state.user.id_company
    //   isCountingDate: false,
    // });
    // this.data = this.getAllAssets;
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.id_company = id_company;
    this.company_id = id_company;

    await this.getProgressBar(true);
    this.timer = setInterval(() => {
      this.getProgressBar(false);
    }, 3000);
  },
  async mounted() {
    this.isLoading = true;
    this.$store.dispatch("getPackageLimitation");
    await this.loadAssetData();
    this.force = this.data.length === 0 ? true : false;

    this.isLoading = false;
    // this.$store.dispatch("allAssets", this.$store.state.user.id_company);
  },

  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      getUserDetail: "getUserDetail",
      report: "getReport",
      assets_count: "assets_count",
      getAllAssets: "getAllAssetsStore",
      getAllAssetsFilter: "getAllAssetsFilter",
      getRegisAssetResult: "getRegisAssetResult",
      getRegisAssetCheck: "getRegisAssetCheck",
      getAllAssetOtherColumn: "getAllAssetOtherColumn",
      isImportCookie: "isImport",
      companyName: "companyName",
      branchName: "branchName",
      departmentName: "departmentName",
      isRoleLower: "isRoleLower",
      optionChoice: "getfilterOption",
      permissionStatus: "permissionStatus",
      summaryData: "getSummaryData",
      canUseDepreciation: "canUseDepreciation",
      canUseCustom5: "canUseCustom5",
      canUseCustom7: "canUseCustom7",
      packageLimit: "packageLimit",
    }),
    isSummaryPage() {
      return this.$route.params.summaryId ? true : false;
    },
    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
    optionCategory() {
      if (this.optionChoice.optionCategory) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionCategory.map((item) => {
              return { label: item.name, value: item.id_category };
            })
          ),
        ];
        return list;
      }
      return [{ label: this.$t("filter.all"), value: null }];
    },
    optionSubCategory() {
      if (this.filterOptions.id_category) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionCategory
            .find(
              (category) =>
                category.id_category === this.filterOptions.id_category
            )
            ?.sub_categories.map((item) => {
              return {
                label: item.name,
                value: item.id_sub_category,
              };
            }),
        ];
        return list;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionBuilding() {
      if (this.optionChoice.optionBuilding) {
        const buildingList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionBuilding.map((each) => {
            return {
              label: each.name,
              value: each.id_location_building,
            };
          }),
        ];
        // console.log("Building list: ", buildingList);
        return buildingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionFloor() {
      if (this.filterOptions.id_location_building) {
        const floorList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice?.optionBuilding
            .find(
              (item) =>
                item.id_location_building ===
                this.filterOptions.id_location_building
            )
            ?.location_floors.map((each) => {
              return { label: each.name, value: each.id_location_floor };
            }),
        ];
        return floorList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionDepartment() {
      if (this.optionChoice.optionDepartment) {
        let departmentList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionDepartment.map((item) => {
              return {
                label: item.name,
                value: item.id_location_department,
              };
            })
          ),
        ];
        return departmentList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionZone() {
      if (this.optionChoice.optionZone) {
        const zoneList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionZone.map((each) => {
              return {
                label: each.name,
                value: each.id_location_zone,
              };
            })
          ),
        ];
        // console.log("zone list: ", zoneList);
        return zoneList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },

    datatable() {
      let datafilter = this.data || [];
      return datafilter;
    },

    getdataimport() {
      const dataimport = this.getRegisAssetCheck;
      const dataNotDuplicate = dataimport
        ? dataimport.all.filter((data) => {
            return data.status !== "duplicate";
          })
        : [];
      return { ...dataimport, dataNotDuplicate };
    },
    searchCom() {
      let data = this.data;
      let searchText = this.searchText;
      let getAllAssets = this.getAllAssets;
      if (searchText) {
        return (data = data.filter((asset) => {
          let searchName = asset.name.toLowerCase().includes(searchText);
          let searchCode = asset.id_fixed_asset
            .toLowerCase()
            .includes(searchText);
          return searchName | searchCode;
        }));
      }
      return data;
    },
    getAllAssetsResult() {
      return this.getAllAssets;
    },
    listid() {
      let selectColum = this.selectColum;
      if (selectColum === "รหัสสินทรัพย์ถาวร") {
        let list = this.lodash.uniqBy(this.data, "id_fixed_asset");
        let searchList = this.searchList;
        return list.filter((asset) => {
          let searchName = asset.id_fixed_asset
            .toLowerCase()
            .includes(searchList);
          return searchName;
        });
      }
      if (selectColum === "ชื่อสินทรัพย์") {
        let list = this.lodash.uniqBy(this.data, "name");
        let searchList = this.searchList;
        return list.filter((asset) => {
          let searchName = asset.name
            .toLowerCase()
            .includes(searchList.toLowerCase());
          return searchName;
        });
      }
      if (selectColum === "รายละเอียดสินทรัพย์") {
        let list = this.lodash.uniqBy(this.data, "description");
        let searchList = this.searchList;
        return list.filter((asset) => {
          let searchName = asset.description
            .toLowerCase()
            .includes(searchList.toLowerCase());
          return searchName;
        });
      }
      if (selectColum === "หมวดหมู่") {
        let list = this.lodash.uniqBy(this.data, "category");
        let searchList = this.searchList;
        return list.filter((asset) => {
          let searchName = asset.category
            .toLowerCase()
            .includes(searchList.toLowerCase());
          return searchName;
        });
      }
      if (selectColum === "อาคาร") {
        let list = this.lodash.uniqBy(this.data, "location_building");
        let searchList = this.searchList;
        return list.filter((asset) => {
          let searchName = asset.location_building
            .toLowerCase()
            .includes(searchList.toLowerCase());
          return searchName;
        });
      }
      if (selectColum === "ชั้น") {
        let list = this.lodash.uniqBy(this.data, "location_floor");
        let searchList = this.searchList;
        return list.filter((asset) => {
          let searchName = asset.location_floor
            .toLowerCase()
            .includes(searchList.toLowerCase());
          return searchName;
        });
      }
      if (selectColum === "แผนก") {
        let list = this.lodash.uniqBy(this.data, "location_department");
        let searchList = this.searchList;
        return list.filter((asset) => {
          let searchName = asset.location_department
            .toLowerCase()
            .includes(searchList.toLowerCase());
          return searchName;
        });
      }
      if (selectColum === "โซน") {
        let list = this.lodash.uniqBy(this.data, "location_zone");
        let searchList = this.searchList;
        return list.filter((asset) => {
          let searchName = asset.location_zone
            .toLowerCase()
            .includes(searchList.toLowerCase());
          return searchName;
        });
      }
      return this.data;
      // let list = this.lodash.uniqBy(this.report, "id_fixed_asset");
      // let searchList = this.searchList;
      // return list.filter((asset) => {
      //   let searchName = asset.id_fixed_asset
      //     .toLowerCase()
      //     .includes(searchList);
      //   return searchName;
      // });
    },
    selectAll: {
      get: function() {
        return this.report
          ? this.checked.id.length == this.report.length
          : false;
      },
      set: function(value) {
        var selected = [];
        let selectColum = this.selectColum;
        if (value) {
          if (selectColum === "รหัสสินทรัพย์ถาวร") {
            this.listid.forEach(function(report) {
              selected.push(report.id_fixed_asset);
            });
          }
          if (selectColum === "ชื่อสินทรัพย์") {
            this.listid.forEach(function(report) {
              selected.push(report.name);
            });
          }
          if (selectColum === "รายละเอียดสินทรัพย์") {
            this.listid.forEach(function(report) {
              selected.push(report.description);
            });
          }
          if (selectColum === "หมวดหมู่") {
            this.listid.forEach(function(report) {
              selected.push(report.category);
            });
          }
          if (selectColum === "อาคาร") {
            this.listid.forEach(function(report) {
              selected.push(report.location_building);
            });
          }
          if (selectColum === "ชั้น") {
            this.listid.forEach(function(report) {
              selected.push(report.location_floor);
            });
          }
          if (selectColum === "แผนก") {
            this.listid.forEach(function(report) {
              selected.push(report.location_department);
            });
          }
          if (selectColum === "โซน") {
            this.listid.forEach(function(report) {
              selected.push(report.location_zone);
            });
          }
          // this.report.forEach(function(report) {
          //   selected.push(report.id_fixed_asset);
          // });
        }
        if (selectColum === "รหัสสินทรัพย์ถาวร") {
          this.checked.id = selected;
        }
        if (selectColum === "ชื่อสินทรัพย์") {
          this.checked.name = selected;
        }
        if (selectColum === "รายละเอียดสินทรัพย์") {
          this.checked.description = selected;
        }
        if (selectColum === "หมวดหมู่") {
          this.checked.category = selected;
        }
        if (selectColum === "อาคาร") {
          this.checked.location_building = selected;
        }
        if (selectColum === "ชั้น") {
          this.checked.location_floor = selected;
        }
        if (selectColum === "แผนก") {
          this.checked.location_department = selected;
        }
        if (selectColum === "โซน") {
          this.checked.location_zone = selected;
        }
      },
    },
  },
  watch: {
    async currentPage(value) {
      this.isLoading = true;
      try {
        const id_company = await this.$store.dispatch("getCompanyIdCookie");
        this.company_id = id_company;
        if (this.isSummaryPage) {
          await this.$store.dispatch("getSummaryByStatus", {
            summary_id: this.$route.params.summaryId,
            page: value,
            limit: this.perPage,
            filterOptions: this.filterOptions,
            saveToStore: false,
          });
          this.data = this.summaryData.result.data.rows;
          this.totalRows = this.summaryData.result.data.count;
          this.isFinishedCounting = this.summaryData.summary.is_finish;
        } else {
          await this.$store.dispatch("filterAllAsset_pagination", {
            filterOptions: this.filterOptions,
            saveToStore: false,
            countingStatus: this.countingStatus,
            company_id: id_company,
            page: value,
            limit: this.perPage,
          });
          this.data = this.getAllAssets;
          this.totalRows = this.assets_count;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    getAllAssets() {
      if (!this.isData && this.getAllAssets) {
        this.data = this.getAllAssets;
        this.isData = true;
      } else if (this.isImport) {
        this.data = this.getAllAssets;
        console.log(this.data);
      } else {
        null;
      }
    },
    sortOrder() {
      this.sortData();
    },

    async isImport() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      if (this.isImport) {
        this.$store.dispatch("firstGetAllAsset", {
          company_id: id_company, //this.$store.state.user.id_company
          isCountingDate: false,
        });
      }
    },

    "filterOptions.dateUsed_start"() {
      console.log("watcher", this.filterOptions.dateUsed_start);
      if (
        !this.filterOptions.dateUsed_end ||
        this.filterOptions.dateUsed_end < this.filterOptions.dateUsed_start
      ) {
        this.filterOptions.dateUsed_end = this.filterOptions.dateUsed_start;
      }
    },
    "filterOptions.datePurchase_min"() {
      console.log("watcher", this.filterOptions.datePurchase_min);
      if (
        !this.filterOptions.datePurchase_max ||
        this.filterOptions.datePurchase_max <
          this.filterOptions.datePurchase_min
      ) {
        this.filterOptions.datePurchase_max = this.filterOptions.datePurchase_min;
      }
    },
  },
  methods: {
    moment,
    noChangeAlert() {
      alert(this.$t("dialog.no_import_change_detected"));
      this.$modal.hide("example");
    },
    async getProgressBar(isFirstTime) {
      // console.log("timer", this.timer);
      let type = 1;
      let env = stage();
      try {
        const res = await axios.get(
          `https://api.verscan.com/process_counter/get_process_counter.php?company_id=${this.company_id}&env=${env}&process_type=${type}`
        );
        let { data } = res;
        if (data.current_counting && data.targeted_count) {
          if (+data.current_counting >= +data.targeted_count) {
            this.progressStatus.count = parseInt(
              data.current_counting
            ).toLocaleString();
            this.progressStatus.max_assets = parseInt(
              data.targeted_count
            ).toLocaleString();
            // alert("clear1");
            clearInterval(this.timer);
            if (isFirstTime) {
              this.progressStatus.is_show = false;
            }
          } else {
            this.progressStatus.is_show = true;
            this.progressStatus.username = data.requester_name;
            this.progressStatus.count = parseInt(
              data.current_counting
            ).toLocaleString();
            this.progressStatus.max_assets = parseInt(
              data.targeted_count
            ).toLocaleString();
          }
        } else {
          // alert("clear2");
          clearInterval(this.timer);
        }
      } catch (err) {
        console.log(err);
        // alert("clear3");
        clearInterval(this.timer);
      }
    },

    async loadAssetData() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.id_company = id_company;
      this.company_id = id_company;

      if (this.isSummaryPage) {
        await this.$store.dispatch("getSummaryByStatus", {
          summary_id: this.$route.params.summaryId,
          page: this.currentPage,
          limit: this.perPage,
          filterOptions: this.filterOptions,
          saveToStore: false,
        });
        this.data = this.summaryData.result.data.rows;
        this.totalRows = this.summaryData.result.data.count;
        this.isFinishedCounting = this.summaryData.summary.is_finish;
      } else {
        await this.$store.dispatch("firstGetAllAsset_Pagination", {
          company_id: id_company,
          page: this.currentPage,
          limit: this.perPage,
        });
        this.data = this.getAllAssets;
        this.totalRows = this.assets_count;
      }
    },

    async searchByText() {
      this.isLoading = true;
      this.filterOptions.text = this.searchText;
      this.currentPage = 1;
      if (this.isSummaryPage) {
        await this.$store.dispatch("getSummaryByStatus", {
          summary_id: this.$route.params.summaryId,
          page: 1,
          limit: this.perPage,
          filterOptions: this.filterOptions,
          saveToStore: false,
        });
        this.data = this.summaryData.result.data.rows;
        this.totalRows = this.summaryData.result.data.count;
        this.isFinishedCounting = this.summaryData.summary.is_finish;
      } else {
        await this.$store.dispatch("filterAllAsset_pagination", {
          filterOptions: this.filterOptions,
          saveToStore: false,
          countingStatus: this.countingStatus,
          page: 1,
          company_id: this.company_id,
          limit: this.perPage,
        });
        this.data = this.getAllAssets;
        this.totalRows = this.assets_count;
      }
      this.isLoading = false;
    },
    async handleFilter() {
      this.isLoading = true;
      await this.$store.dispatch("filterAllAsset_pagination", {
        filterOptions: this.filterOptions,
        saveToStore: false,
        countingStatus: this.countingStatus,
        page: this.currentPage,
        company_id: this.company_id,
        limit: this.perPage,
      });
      this.data = this.getAllAssets;
      this.totalRows = this.assets_count;
      this.isLoading = false;
    },
    addCustomFilter(e) {
      this.filterOptions = {
        ...this.filterOptions,
        ...e,
      };
    },
    momentDay(date) {
      if (!date || date === "-") {
        return "-";
      }
      return moment(date).format("DD/MM/YYYY");
    },
    isNull(data) {
      return data ? data : "-";
    },
    async handleclear() {
      this.isLoading = true;
      this.searchText = "";
      this.currentPage = 1;
      this.filterOptions = {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
        dateUsed_start: null,
        dateUsed_end: null,
        datePurchase_min: null,
        datePurchase_max: null,
        useful_life_min: "",
        useful_life_max: "",
        purchase_price_min: "",
        purchase_price_max: "",
        acc_depreciation_min: "",
        acc_depreciation_max: "",
        net_book_min: "",
        net_book_max: "",
      };
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.company_id = id_company;
      await this.$store.dispatch("filterAllAsset_pagination", {
        filterOptions: this.filterOptions,
        saveToStore: false,
        countingStatus: this.countingStatus,
        company_id: id_company,
        page: 1,
        limit: this.perPage,
      });
      this.data = this.getAllAssets;
      this.totalRows = this.assets_count;
      this.isLoading = false;
    },
    async getnewdata() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.$store.dispatch("firstGetAllAsset", {
        company_id: id_company, //this.$store.state.user.id_company
        isCountingDate: false,
      });
    },
    closemodal() {
      this.$modal.hide("example");
      this.$modal.hide("dupmodal");
    },
    // async importcreate() {
    //   // import database
    //   const id_company = await this.$store.dispatch("getCompanyIdCookie");
    //   this.isLoading = true;
    //   await axios
    //     .post(
    //       `${baseUrl()}admin/createimport/`,
    //       {
    //         data: this.getdataimport.unique_item,
    //         id_company: id_company, //this.$store.state.user.id_company
    //       },
    //       authHeader()
    //     )
    //     .then(async (res) => {
    //       this.isImport = true;
    //       await this.$store.dispatch("firstGetAllAsset", {
    //         company_id: id_company, //this.$store.state.user.id_company
    //         isCountingDate: false,
    //       });
    //       this.isLoading = false;
    //     });

    //   this.$modal.hide("example");
    //   this.force = false;
    // },

    //duplicate item
    async updateImport() {
      const dataDup = [...this.getdataimport.change_item];
      if (dataDup.length > 0) {
        console.log("dateDup", dataDup);
        try {
          await axios.post(
            `${baseUrl()}admin/updateimport/`,
            {
              data: dataDup,
              id_company: this.company_id,
            },
            authHeader()
          );
        } catch (err) {
          if (err.response) {
            if (err.response.status === 524) {
              Swal.fire(this.$t("dialog.updateimport_wait"));
            }
          }
        }
      }
    },
    async createImport() {
      let dataUniq = this.getdataimport.unique_item;
      try {
        await axios.post(
          `${baseUrl()}admin/createimport/`,
          {
            data: dataUniq,
            id_company: this.company_id, //this.$store.state.user.id_company
          },
          authHeader()
        );
      } catch (err) {
        if (err.response) {
          if (err.response.status === 524) {
            Swal.fire(this.$t("dialog.createimport_wait"));
          }
        }
      }
    },
    async ImportAsset() {
      let dataUniq = this.getdataimport.all.filter(
        (data) => data.status === "new"
      );
      const dataDup = this.getdataimport.all.filter(
        (data) => data.status === "change"
      );

      try {
        const res = await axios.post(
          `${baseUrl()}admin/importasset/`,
          {
            create: dataUniq,
            update: dataDup,
            id_company: this.company_id, //this.$store.state.user.id_company
          },
          authHeader()
        );
        await this.loadAssetData();
      } catch (err) {
        if (err.response) {
          if (err.response.status === 524) {
            // Swal.fire({ html: this.$t("dialog.createimport_wait") });
            return;
          }
          if (err.response.data.message === "Reach package limit") {
            Swal.fire({
              icon: "error",
              text: this.$t("dialog.register_limit"),
            });
            return;
          }
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
    },

    async importupdate() {
      this.isLoading = true;
      this.ImportAsset();

      await new Promise((r) => setTimeout(r, 3000));
      this.isLoading = false;

      this.timer = setInterval(() => {
        this.getProgressBar(false);
      }, 3000);

      this.$modal.hide("dupmodal");
      this.$store.dispatch("firstGetAllAsset_Pagination", {
        company_id: this.company_id,
        page: this.currentPage,
        limit: this.perPage,
      });

      // this.isLoading = false;
      this.force = false;
    },
    openmodal() {
      this.$modal.hide("example");
      this.$modal.show("dupmodal");
    },
    async onFileChange(e, type) {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      const file = e.target.files[0];
      const fd = new FormData();
      // fd.append("id_user", id_user); // sent name who upload asset
      fd.append("file", file);
      this.$refs.fileInput.value = null;
      this.isLoading = true;
      let data = {
        id_company: id_company, // this.$store.state.user.id_company
        fd,
      };
      this.$bvModal.show("importCheckModal");
      if (type === "SAP") {
        const response = await this.$store.dispatch("importSAPAsset", data);
        if (response) {
          this.isLoading = false;
          this.$bvModal.hide("export-SAP-modal");
          this.$modal.show("example");
        } else {
          this.isLoading = false;
        }
      } else {
        const response = await this.$store.dispatch("importAsset", data);
        if (response) {
          this.isLoading = false;
          this.$modal.show("example");
        } else {
          this.isLoading = false;
        }
      }
      e.target.value = null;
      this.$bvModal.hide("importCheckModal");
    },

    async onExportDocRegisAsset(isMail) {
      this.isLoading = true;
      this.$store
        .dispatch("ExportAsset", {
          is_email: isMail,
          company_id: this.company_id,
          filterOptions: this.filterOptions,
          filename: `${this.$t("asset.as_regdoc")}_${
            this.companyName
          }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`,
          type: "docRegisAsset",
        })
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    async onExportSAP(isMail) {
      this.isLoading = true;
      this.$store
        .dispatch("ExportAsset", {
          is_email: isMail,
          company_id: this.company_id,
          filterOptions: this.filterOptions,
          filename: `${this.$t("asset.as_regdoc")}_${
            this.companyName
          }_SAP_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`,
          type: "SAP",
          id_summary: this.$route.params.summaryId
            ? this.$route.params.summaryId
            : null,
        })
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    searchfunc() {
      this.data = this.searchCom;
    },
    clearSearch() {
      this.data = this.getAllAssets;
    },
    gotoSign() {
      this.$router.push("/sign");
    },
    gotoDashboard() {
      this.$router.push("/dashboard");
    },
    gotoHome() {
      this.$router.go(-1);
    },
    filterbydata(value) {
      this.filterdata = value;
      console.log("filterdata", this.filterdata);
    },
    sortOrderby(value) {
      this.sortOrder = value;
      console.log("test value", value);
    },
    selectColums(value) {
      this.selectColum = value;
    },
    submitdata(value) {
      this.filterbydata(value);
      this.filterData();
      this.sortData();
    },

    sortData() {
      if (this.selectColum === "รหัสสินทรัพย์ถาวร") {
        if (this.sortOrder) {
          this.data = this.lodash.orderBy(
            this.data,
            [(a) => a.id_fixed_asset.toLowerCase()],
            [this.sortOrder]
          );
        }
      }
      if (this.selectColum === "ชื่อสินทรัพย์") {
        if (this.sortOrder) {
          this.data = this.lodash.orderBy(
            this.data,
            [(a) => a.name.toLowerCase()],
            [this.sortOrder]
          );
        }
      }
      if (this.selectColum === "รายละเอียดสินทรัพย์") {
        if (this.sortOrder) {
          this.data = this.lodash.orderBy(
            this.data,
            [(a) => a.description.toLowerCase()],
            [this.sortOrder]
          );
        }
      }
      if (this.selectColum === "หมวดหมู่") {
        if (this.sortOrder) {
          this.data = this.lodash.orderBy(
            this.data,
            [(a) => a.category.toLowerCase()],
            [this.sortOrder]
          );
        }
      }
      if (this.selectColum === "อาคาร") {
        if (this.sortOrder) {
          this.data = this.lodash.orderBy(
            this.data,
            [(a) => a.location_building.toLowerCase()],
            [this.sortOrder]
          );
        }
      }
      if (this.selectColum === "ชั้น") {
        if (this.sortOrder) {
          this.data = this.lodash.orderBy(
            this.data,
            [(a) => a.location_floor.toLowerCase()],
            [this.sortOrder]
          );
        }
      }
      if (this.selectColum === "แผนก") {
        if (this.sortOrder) {
          this.data = this.lodash.orderBy(
            this.data,
            [(a) => a.location_department.toLowerCase()],
            [this.sortOrder]
          );
        }
      }
      if (this.selectColum === "โซน") {
        if (this.sortOrder) {
          this.data = this.lodash.orderBy(
            this.data,
            [(a) => a.location_zone.toLowerCase()],
            [this.sortOrder]
          );
        }
      }

      console.log("Report Orderby > ", this.data);
    },
    filterData() {
      console.log("filter", this.selectColum);
      if (this.selectColum === "รหัสสินทรัพย์ถาวร") {
        if (this.filterdata.length != 0) {
          this.data = this.getAllAssets;
          this.data = this.data.filter(({ id_fixed_asset }) =>
            this.filterdata.includes(id_fixed_asset.toLowerCase())
          );
        }
      } else if (this.selectColum === "ชื่อสินทรัพย์") {
        if (this.listid.length == this.filterdata.length) {
          this.data = this.getAllAssets;
        } else if (this.filterdata.length != 0) {
          // this.data = this.getAllAssets;
          let arr = [];
          for (let i = 0; i < this.data.length; i++) {
            console.log(this.data[i]);
            for (let x = 0; x < this.filterdata.length; x++) {
              console.log(this.filterdata[x]);
              if (this.data[i].name === this.filterdata[x]) {
                arr.push(this.data[i]);
              }
            }
          }
          console.log("arr result >>>>", arr);
          this.data = arr;
        }
      } else if (this.selectColum === "รายละเอียดสินทรัพย์") {
        if (this.listid.length == this.filterdata.length) {
          this.data = this.getAllAssets;
        } else if (this.filterdata.length != 0) {
          // this.data = this.getAllAssets;
          let arr = [];
          for (let i = 0; i < this.data.length; i++) {
            console.log(this.data[i]);
            for (let x = 0; x < this.filterdata.length; x++) {
              console.log(this.filterdata[x]);
              if (this.data[i].description === this.filterdata[x]) {
                arr.push(this.data[i]);
              }
            }
          }
          console.log("arr result >>>>", arr);
          this.data = arr;
        }
      } else if (this.selectColum === "หมวดหมู่") {
        if (this.listid.length == this.filterdata.length) {
          this.data = this.getAllAssets;
        } else if (this.filterdata.length != 0) {
          // this.data = this.getAllAssets;
          let arr = [];
          for (let i = 0; i < this.data.length; i++) {
            console.log(this.data[i]);
            for (let x = 0; x < this.filterdata.length; x++) {
              console.log(this.filterdata[x]);
              if (this.data[i].category === this.filterdata[x]) {
                arr.push(this.data[i]);
              }
            }
          }
          console.log("arr result >>>>", arr);
          this.data = arr;
        }
      } else if (this.selectColum === "อาคาร") {
        if (this.listid.length == this.filterdata.length) {
          this.data = this.getAllAssets;
        } else if (this.filterdata.length != 0) {
          // this.data = this.getAllAssets;
          let arr = [];
          for (let i = 0; i < this.data.length; i++) {
            console.log(this.data[i]);
            for (let x = 0; x < this.filterdata.length; x++) {
              console.log(this.filterdata[x]);
              if (this.data[i].location_building === this.filterdata[x]) {
                arr.push(this.data[i]);
              }
            }
          }
          console.log("arr result >>>>", arr);
          this.data = arr;
        }
      } else if (this.selectColum === "ชั้น") {
        if (this.listid.length == this.filterdata.length) {
          this.data = this.getAllAssets;
        } else if (this.filterdata.length != 0) {
          // this.data = this.getAllAssets;
          let arr = [];
          for (let i = 0; i < this.data.length; i++) {
            console.log(this.data[i]);
            for (let x = 0; x < this.filterdata.length; x++) {
              console.log(this.filterdata[x]);
              if (this.data[i].location_floor === this.filterdata[x]) {
                arr.push(this.data[i]);
              }
            }
          }
          console.log("arr result >>>>", arr);
          this.data = arr;
        }
      } else if (this.selectColum === "แผนก") {
        if (this.listid.length == this.filterdata.length) {
          this.data = this.getAllAssets;
        } else if (this.filterdata.length != 0) {
          // this.data = this.getAllAssets;
          let arr = [];
          for (let i = 0; i < this.data.length; i++) {
            console.log(this.data[i]);
            for (let x = 0; x < this.filterdata.length; x++) {
              console.log(this.filterdata[x]);
              if (this.data[i].location_department === this.filterdata[x]) {
                arr.push(this.data[i]);
              }
            }
          }
          console.log("arr result >>>>", arr);
          this.data = arr;
        }
      } else if (this.selectColum === "โซน") {
        if (this.listid.length == this.filterdata.length) {
          this.data = this.getAllAssets;
        } else if (this.filterdata.length != 0) {
          // this.data = this.getAllAssets;
          let arr = [];
          for (let i = 0; i < this.data.length; i++) {
            console.log(this.data[i]);
            for (let x = 0; x < this.filterdata.length; x++) {
              console.log(this.filterdata[x]);
              if (this.data[i].location_zone === this.filterdata[x]) {
                arr.push(this.data[i]);
              }
            }
          }
          console.log("arr result >>>>", arr);
          this.data = arr;
        }
      }
    },
    downloadStandard() {
      let time = new Date();
      window.location.href = `https://api.verscan.com/download/VERSCAN_Template_V_1_5.xlsx?${time.valueOf()}`;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
#export-modal p {
  font-size: 18px;
}

.delete-asset {
  color: red !important;
  border-color: red !important;
}
.vm--modal {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;
  border-radius: 44px !important;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
}
</style>
<style lang="scss" scoped>
.group-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}
.radiusBodyFirst {
  // border-top-left-radius: 30px;
  border-bottom-left-radius: 30px !important;
}
.radiusBodyLast {
  // border-top-right-radius: 30px;
  border-bottom-right-radius: 30px !important;
}
.filter-box {
  background-color: #e0e0e0;
  width: 100%;
}
.div-export-h {
  padding-top: 2rem;
  height: 50px;
  margin: auto;
}
.text-export-h {
  font-size: 24px;
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.div-export-table {
  width: 90%;
  height: 500px;
  margin: auto;
}
.div-export-table-first {
  width: 90%;
  height: 55vh;
  margin: auto;
}
.div-export-table-dup {
  width: 90%;
  height: 450px;
  margin: auto;
  margin-top: 55px;
}
.filter-filter {
  border: 1px solid #8e8e8e;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
}
.div-no-asset {
  border: 1px solid #bcbaba;
  border-radius: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8rem;
  margin-top: 2rem;
  color: #bcbaba;
  img {
    display: flex;
    margin-bottom: 15px;
  }
  .block {
    display: flex;
    flex-direction: column;
  }
}

.filter-body {
  text-align: left;
  padding: 1rem;
}
.btn-group {
  text-align: left;
  display: flex;
  width: 100%;

  &-custom {
    button {
      width: 100px;
    }
  }
}
.overflow_table {
  padding-top: 0 !important;
}
.grid-signature {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}

.signature-frame {
  p {
    font-size: 14px;
    letter-spacing: 0px;
    margin: 0px;
  }
  img {
    width: 70%;
    height: 70%;
    margin-bottom: 1rem;
  }

  padding: 1rem;
  border: 1px solid #bcbaba;
  border-radius: 20px;
}
</style>
<style lang="scss" scoped>
.table-overflow-scroll {
  overflow-x: hidden;
}
.table {
  tfoot {
    border-radius: 20px;
    height: 20px;
    background: transparent linear-gradient(0deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
  }
  tfoot td {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: none;
  }
  thead,
  tbody {
    display: block;
  }
  thead th {
    min-width: calc(95vw / 11);
    max-width: calc(95vw / 11);
  }

  thead th:last-child {
    min-width: calc(95vw / 11 + 15px);
    max-width: calc(95vw / 11 + 15px);
  }
  tbody td {
    min-width: calc(95vw / 11);
    max-width: calc(95vw / 11);
  }

  tbody {
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1025px) {
  .table-overflow-scroll {
    overflow-x: auto;
  }
  //  table scrollable
  .table-overflow-scroll {
    thead,
    tbody {
      display: block;
    }
    thead th {
      min-width: calc(250vw / 11);
      max-width: calc(250vw / 11);
    }
    tbody td {
      min-width: calc(250vw / 11);
      max-width: calc(250vw / 11);
    }
    thead th:last-child {
      min-width: calc(250vw / 11 + 15px);
      max-width: calc(250vw / 11 + 15px);
    }
    tbody td:last-child {
      min-width: calc(250vw / 11 + 15px);
      max-width: calc(250vw / 11 + 15px);
    }

    tbody {
      height: 30vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.header-text {
  padding-top: 58px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // margin-bottom: 15rem;
  }
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.form-check-scroll {
  height: 150px;
  overflow: scroll;
}
.filter-select {
  border-radius: 10px;
  border: 1px solid #bcbaba;
  padding: 0.4rem;
  width: 100% !important;
}
.btnsortafter {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box !important;
}
.error-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  button {
    margin-right: 0.5rem;
  }
}
.btn-sort {
  background: #b9b9b9;
  width: 200px;
}
.btn-sort-after {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}
.filter-select {
  border-radius: 10px;
  border: 1px solid #bcbaba;
  padding: 0.4rem;
  width: 100% !important;
}

select {
  background: white;
}

.button-bar {
  display: flex;
  justify-content: space-between;
  input {
    padding: 1rem;
    height: 40px;
    // margin-right: 3rem;
  }
  button {
    font-size: 14px;
    margin: 0.1rem;
    width: fit-content;
    height: 45px;
    background: transparent;
    color: #007afe;
    border: solid 2px;
    border-radius: 11px;
  }
  .button-load {
    color: white;
    border: none;
    background: transparent
      linear-gradient(101deg, var(--unnamed-color-007AFE) 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
  }
}
.button-bar-filter {
  display: flex;
  justify-content: center;
  button {
    font-size: 14px;
    width: 120px;
    height: 35px;
    background: $font-grey;
    color: white;
    margin-left: 0.3em;
    border: none;
    border-radius: 11px;
  }
  .button-load {
    // width: 3.5em;
    border: none;
    background: transparent
      linear-gradient(101deg, var(--unnamed-color-007AFE) 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
  }
  .button-load-filter {
    background: transparent
      linear-gradient(101deg, var(--unnamed-color-007AFE) 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    opacity: 0.5;
  }
  .buttonfilter {
    background-color: #bcbaba;
  }
  .opa {
    background-color: #bcbaba;
    opacity: 0.5;
  }
}
@media only screen and (min-width: 1200px) {
  .head-table {
    // width: 100px;
    text-align: center;
    margin: auto;
  }
  th:first-child {
    border-radius: 30px 0 0 0px !important;
  }
  th:last-child {
    border-radius: 0 30px 0px 0 !important;
  }
}
@media only screen and (max-width: 1200px) {
  .head-table {
    // width: 100px;
    text-align: center;
    margin: auto;
  }
  th:first-child {
    border-radius: 30px 0 0 0px !important;
  }
  th:last-child {
    border-radius: 0 30px 0px 0 !important;
  }
}
@media only screen and (max-width: 955px) {
  .button-bar {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    input {
      padding: 1rem;
      height: 35px;
    }
  }
  .button-bar-group {
    display: flex;
    button {
      width: 150px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .head-table {
    // width: 100px;
    text-align: center;
    margin: auto;
  }
  th:first-child {
    border-radius: 30px 0 0 0px !important;
  }
  th:last-child {
    border-radius: 0 30px 0px 0 !important;
  }
}
@media only screen and (max-width: 955px) {
  .button-bar {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    input {
      padding: 1rem;
      height: 35px;
    }
  }
  .button-bar-group {
    display: flex;
    button {
      width: 150px;
    }
  }
}

// @media only screen and (max-width: 700px) {
//   .button-bar-group {
//     button {
//       width: 120px;
//     }
//   }
// }

@media only screen and (max-width: 412px) {
  body,
  html,
  * {
    font-size: 14px;
  }
  .button-bar {
    //background: red;
    input {
      // width: 65vw;
      padding: 10px;
    }
  }
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

.overflow-auto {
  overflow: auto;
}
.drop-down-year {
  width: 100px;
  background-image: url("../assets/down-arrow.svg");
  background-repeat: no-repeat;
  background-position: 97% 50%;
  padding-right: 0.5rem;
}

.searching {
  padding: 15px;
  // background-image: url("../image/search.svg");
  background-repeat: no-repeat;
  background-position: left;
  // padding: 0.5rem;
  &placeholder {
    padding-left: 1rem;
  }
}
.close {
  margin: 10px;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  float: right;
  border: none;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
}
.el3 {
  display: grid;

  grid-template-columns: 2fr 2fr 3fr 3fr;
}
.sub-label {
  line-height: 1.3;
}
.table {
  font-size: 14px;
}
.title {
  color: #ffffff !important;
  letter-spacing: 0px;
  font-size: 50px;
}
.body_table {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #00000033;
  border: 1px solid #d3d3d3;
  border-radius: 50px;
}
.backHome {
  font-size: 16px;
  color: #f4f4f4;
  background: #7c7c7c;
  border-radius: 12px;
}
.input-group-text {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d0d0d0;
}
.input-group {
  display: flex !important;
}

.reletive {
  position: relative;
}
// .search_icon {
//   display: inline-block;
//   position: absolute;
//   z-index: 3;
//   line-height: 42px;
//   text-indent: 106px;
//   right: 15px;
//   top: 20%;
// }

.title_table {
  th {
    background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  }
  th:first-child {
    border-radius: 30px 0 0 0px;
  }
  th:last-child {
    border-radius: 0 30px 0px 0;
  }

  tr:nth-child(odd) {
    background-color: #c4c4c4;
  }
}

.header-text {
  font-size: 30px;
  font-weight: 400;
  padding-top: 3rem;
  padding-bottom: 1rem;
  color: white;
}
.card-report {
  margin: auto;
  width: 95%;
  height: auto;
  background-color: white;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
  padding: 2rem 1.5rem 2.5rem 1.5rem;
  margin-bottom: 2rem;
}

.card-div {
  margin: auto;
  width: calc(95vw + 16px);
  height: 50vh;
  /* background: linear-gradient(90deg, rgb(0, 123, 255), rgb(0, 62, 128)); */
}
.pagination {
  color: #007afe;
  font-size: 11px;
  text-decoration: underline;
}

.whiteBackground {
  background: white;
}
.high_29 {
  height: 29px !important;
}

// .table thead th {
//   vertical-align: middle;
//   border-bottom: 0px solid white;
// }
.buttonLicense {
  font-size: 16px;
  color: #f4f4f4;
  background: #4b4b4b !important;
  border-radius: 12px;
  height: fit-content;
}
.buttonShowLicense {
  font-size: 16px;
  color: #f4f4f4;
  background: #4b4b4b;
  border-radius: 12px;
  height: fit-content;
}
.buttonDowload {
  font-size: 16px;
  color: #f4f4f4;
  background: #013f81 !important;
  border-radius: 12px;
  height: fit-content;
}
.overflow_table {
  overflow: scroll;
}
.grid_button_2 {
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  grid-template-rows: 29px;
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .topic {
    font-size: 28px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
  .topic {
    font-size: 28px;
  }
  .button-bar-group {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .grid_button {
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    grid-template-rows: 29px;
  }
  .backHome {
    font-size: 14px !important;
    color: #f4f4f4;
    background: #7c7c7c;
    border-radius: 16px;
    height: fit-content;
  }
  .buttonLicense {
    // height: 71px !important;
    font-size: 14px;
  }
  .buttonShowLicense {
    // height: 71px !important;
    font-size: 14px;
  }
  .buttonDowload {
    // height: 71px !important;
    font-size: 14px;
  }
  .leftBut_1 {
    padding: 2% !important;
  }
  .leftBut_2 {
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .leftBut_3 {
    padding-right: 0%;

    height: 100%;
    padding-top: 10px;
  }
  .rightButton_1 {
    padding: 2% !important;
  }
  .rightButton_2 {
    padding: 2% !important;
  }
  .rightButton_3 {
    padding: 2% !important;
  }
  .rightButton_4 {
    padding: 2% !important;
  }

  .overflow_table {
    overflow: scroll;
  }
  .el1 {
    justify-content: start;
    display: grid;
    grid-template-columns: 1fr 0.8fr 1fr;
  }
  .el2 {
    display: grid;

    grid-template-columns: 1fr 1fr 1fr;
  }
  .search_box {
    width: 105px;
  }
  .search_icon {
    display: inline-block;
    position: absolute;
    z-index: 3;
    line-height: 42px;
    text-indent: 106px;
    right: -5px;
    top: 16%;
  }
}
@media only screen and (min-width: 765px) and (max-width: 1024px) {
  .el1 {
    justify-content: space-between;
    display: grid;

    grid-template-columns: 150px 200px 130px;
  }

  .search_box {
    width: 40%;
    height: 45px !important;
  }
  .leftBut_1 {
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .leftBut_2 {
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
  .leftBut_3 {
    padding-left: 35%;
    height: 100%;
    padding-top: 10px;
  }

  .rightBut_1 {
    justify-content: center;
    padding: 0% 10% 0% 10%;
  }
  .rightBut_2 {
    justify-content: center;
    padding: 0% 5% 0% 5%;
  }
  .backHome {
    height: 71px !important;
    font-size: 18px !important;
  }
  .buttonLicense {
    height: 71px !important;
    font-size: 18px;
  }
  .buttonShowLicense {
    height: 71px !important;
    font-size: 18px;
  }
  .buttonDowload {
    height: 100px !important;
    font-size: 18px;
  }
  .search_icon {
    top: 20% !important;
    right: -11%;
  }
}
@media only screen and (max-width: 1440px) {
  .el1 {
    justify-content: start;
    display: grid;

    grid-template-columns: 150px 200px 130px;
  }

  .search_box {
    width: 200px;
    height: 49px !important;
  }
  .leftBut_1 {
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .leftBut_2 {
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
  .leftBut_3 {
    padding-left: 35%;
    height: 100%;
    padding-top: 10px;
  }

  .rightBut_1 {
    justify-content: center;
    padding: 0% 10% 0% 10%;
  }
  .rightBut_2 {
    justify-content: center;
    padding: 0% 5% 0% 5%;
  }
  .backHome {
    height: 71px !important;
    font-size: 18px !important;
  }
  .buttonLicense {
    height: 71px !important;
    font-size: 18px;
  }
  .buttonShowLicense {
    height: 71px !important;
    font-size: 18px;
  }
  .buttonDowload {
    height: 100px !important;
    font-size: 18px;
  }
  .search_icon {
    top: 20% !important;
    right: -11%;
  }
}
@media only screen and (min-width: 1625px) {
  .el1 {
    justify-content: start;
    display: grid;
    grid-template-columns: 210px 260px 130px;
  }

  .search_box {
    width: 260px;

    height: 49px !important;
  }
  .leftBut_1 {
    justify-content: center;
  }
  .leftBut_3 {
    padding-left: 35%;
  }

  .rightBut_1 {
    justify-content: center;
    padding: 0% 10% 0% 10%;
  }
  .rightBut_2 {
    justify-content: center;
    padding: 0% 5% 0% 5%;
  }
  .backHome {
    height: 49px !important;
    font-size: 18px !important;
  }
  .buttonLicense {
    height: 49px !important;
    font-size: 18px;
  }
  .buttonShowLicense {
    height: 49px !important;
    font-size: 18px;
  }
  .buttonDowload {
    height: 49px !important;
    font-size: 18px;
  }
  .search_icon {
    top: 27% !important;
    right: -11%;
  }
}
@media only screen and (min-width: 1920px) {
  .el1 {
    justify-content: start;
    display: grid;
    grid-template-columns: 210px 260px 130px;
  }

  .search_box {
    width: 260px;

    height: 49px !important;
  }
  .leftBut_1 {
    justify-content: center;
    padding-top: 5%;
  }
  .leftBut_3 {
    padding-left: 35%;
  }

  .rightBut_1 {
    justify-content: center;
    padding: 0% 10% 0% 10%;
  }
  .rightBut_2 {
    justify-content: center;
    padding: 0% 5% 0% 5%;
  }
  .backHome {
    height: 49px !important;
    font-size: 18px !important;
  }
  .buttonLicense {
    height: 49px !important;
    font-size: 18px;
  }
  .buttonShowLicense {
    height: 49px !important;
    font-size: 18px;
  }
  .buttonDowload {
    height: 49px !important;
    font-size: 18px;
  }
  .search_icon {
    top: 27% !important;
    right: -11%;
  }
  .overflow_table {
    overflow: scroll;
    padding-top: 4%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 370px) {
  .topic {
    font-size: 20px;
  }
  .button-bar-group {
    display: flex;
    justify-content: center;
    width: 100vw;
  }
}
@media only screen and (min-width: 374px) and (max-width: 425px) {
  .topic {
    font-size: 24px;
  }
  .button-bar-group {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .topic {
    font-size: 24px;
  }
  .button-bar-group {
    display: flex;
    justify-content: center;
  }
  .button-bar {
    display: flex;
    justify-content: space-between !important;
  }
}
.download-template {
  padding: 0 10px 0 10px;
  color: white !important;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#007afe),
      to(#013f81)
    ) 0% 0% no-repeat padding-box !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// input[type="date"]::-webkit-calendar-picker-indicator {
//   color: rgba(0, 0, 0, 0);
//   opacity: 1;
//   display: none;
//   background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png)
//     no-repeat;
//   width: 20px;
//   height: 20px;
//   border-width: thin;
// }

.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}
.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}

button {
  &:disabled {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: default !important;
  }
}

.progress_bar {
  padding: 0.25rem;
  background-color: #fff5d0;
}
</style>
