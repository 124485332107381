<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <template v-if="canUseCustom1 || canUseCustom5">
      <b-modal
        class="p-2"
        id="replace-modal"
        hide-header-close
        hide-header
        hide-footer
      >
        <b-row align-h="end">
          <button
            class="close_modal text-light border-none p-0 mx-2 mb-1"
            @click="$bvModal.hide('replace-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-row>
        <h4 class="text-center pb-3">{{ $t("asset.replace_steps_header") }}</h4>
        <div class="mx-auto" style="width: fit-content;">
          <div
            class="procedure-step"
            v-for="(step, index) in $t('asset.replace_steps')"
            :key="index"
          >
            <div class="number">
              <p class="m-0">{{ index + 1 }}</p>
            </div>
            <p>{{ step }}</p>
            <u v-if="index === 0" class="download" @click="onExport"
              ><nobr>{{ $t("asset.here") }}</nobr></u
            >
          </div>
        </div>
        <b-row align-h="center"
          ><button class="p-2 replace-btn" @click="$refs.fileInput.click()">
            <div class="d-flex align-items-center justify-content-evenly">
              <img
                src="@/assets/replace.svg"
                style="margin-right: 5px"
                alt="replace-icon"
              />
              {{ $t("btn.import_replace") }}
            </div>
          </button>
          <input
            id="fusk"
            type="file"
            ref="fileInput"
            style="display: none;"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="onFileChange($event)"
          />
        </b-row>
      </b-modal>
      <button class="p-2 replace-btn" @click="$bvModal.show('replace-modal')">
        <div class="d-flex align-items-center justify-content-evenly">
          <img
            src="@/assets/replace.svg"
            style="margin-right: 5px"
            alt="replace-icon"
          />
          <nobr>{{ $t("btn.replace_tmp") }}</nobr>
        </div>
      </button>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { baseUrl } from "../../util/backend";
import { exportUrl } from "../../util/backend";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
export default {
  components: { Loading },
  data() {
    return {
      isLoading: false,
      id_company: null,
    };
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
      canUseCustom1: "canUseCustom1",
      canUseCustom5: "canUseCustom5",
    }),
  },

  methods: {
    async onExport() {
      this.isLoading = true;
      try {
        const res = await axios.post(
          `${exportUrl()}admin/export/excel/replace/temp/${this.id_company}`,
          { is_email: false },
          { ...authHeader(), responseType: "blob" }
        );
        const blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        const elink = document.createElement("a");
        elink.download = `${this.$t("asset.tmp_file_name")} (TMP).xlsx`;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } catch (err) {
        console.log(err.response);
      }
      this.isLoading = false;
    },
    async onFileChange(e) {
      this.isLoading = true;
      try {
        const file = e.target.files[0];
        const fd = new FormData();
        fd.append("file", file);
        this.$refs.fileInput.value = null;
        this.isLoading = true;
        const res = await axios.post(
          `${baseUrl()}admin/importcheck/replace/temp/${this.id_company}`,
          fd,
          authHeader()
        );
        await this.$parent.handleFilter();
        this.$bvModal.hide("replace-modal");
      } catch (err) {
        if (err.response) {
          if (
            err.response.data.message === "New ID Fixed Asset has duplicate"
          ) {
            alert(this.$t("asset.new_fixed_asset_id_dup"));
          } else {
            alert(err.response.data.message);
          }
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },
    async loadData() {
      this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.procedure-step {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 5px;
  p {
    margin: 0;
  }
  .number {
    border: 1px solid #007afe;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #007afe;
    margin-right: 5px;
  }
  .download {
    color: #007afe;
    padding-left: 5px;
    cursor: pointer;
  }
}

.replace-btn {
  font-size: 14px;
  margin: 0.1rem;
  width: fit-content;
  height: 45px;
  background: transparent;
  color: #007afe;
  border: solid 2px;
  border-radius: 11px;
}
</style>
