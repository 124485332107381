<template>
  <div class="dropdown">
    <div class="dropdown-content">
      <b-dropdown
        class=""
        id="edit-menu"
        size="lg"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        style="outline: none;"
      >
        <template #button-content>
          <div class="technician-box d-flex">
            <p class="current-technician" v-if="!change">
              {{ dataFromApi == '-' ? "เลือกช่าง" : dataFromApi }}
            </p>
            <p class="current-technician" v-else>
              {{ technician.name }}
            </p>
            <img
              src="@/assets/Maintenancing/maintenance-drop-arrow.svg"
              alt="dropdown"
              width="100%"
            />
          </div>
        </template>
        <b-dropdown-item
          v-for="(option, index) in options"
          :key="index"
          @click="changeStatus(option)"
        >
          <p>{{ option.name }}</p>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  props: {
    technicianData: {
      type: Array,
      default: null,
    },
    dataFromApi: {
      type: String,
    },
  },
  directives: {
    ClickOutside,
  },
  watch: {
    // technician() {
    //   console.log(this.technician);
    // },
    technicianData() {
      this.options = this.technicianData.map((item) => {
        return { name: item.name, value: item.id_technician };
      });
      // console.log(this.options);
    },
  },
  data() {
    return {
      technician: { name: "", id: null },
      change: false,
      // options: [{ name: "nienying" }, { name: "poi" }, { name: "prew" }],
      options: [],
    };
  },
  methods: {
    changeStatus(value) {
      this.change = true;
      this.technician.name = value.name
      this.technician.id = value.value
      this.$emit("upper-assign", this.technician.id)
      // console.log(this.technician)
    },
  },
};
</script>

<style lang="scss" scoped>
.current-technician {
  font-size: 16px;
  font-weight: 500;
  color: black;
  padding: 4px 10px;
  margin: auto;
}
.technician-box {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
img {
  padding: 0 5px;
}
</style>
