<template>
  <ag-grid-vue
    style="width: 100%; height: 450px"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    suppressDragLeaveHidesColumns
    suppressMovableColumns
    @grid-ready="onGridReady"
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import iconCheck from "../Table/components/iconCheck.vue";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridApi: "sizeColumnsToFit",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    page: { type: Number },
    dataTable: [],
    searchText: {
      type: String,
    },
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        maxWidth: 110,
        suppressSizeToFit: true,

        getQuickFilterText: (params) => {
          return "";
        },
      },
      {
        headerName: this.$t("home.fixedas_num"),
        field: "id_fixed_asset",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.as_name"),
        field: "name",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("count.more_dt"),
        field: "description",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("count.verification_note"),
        field: "note",
        sortable: true,
        minWidth: 130,
      },
      {
        headerName: this.$t("count.qr"),
        field: "qrcode",
        sortable: true,
        suppressSizeToFit: true,
        cellStyle: (params) => {
          if (params.value === this.$t("count.not_reg")) {
            return { color: "#888888" };
          }
          return null;
        },
        cellClass: "text-center",
      },
      {
        headerName: this.$t("asset.status"),
        field: "status",
        maxWidth: 130,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return params.value || "-";
        },
        cellClass: "text-center",
      },
      {
        headerName: this.$t("count.ver_date_time"),
        field: "ver_date_time",
        minWidth: 130,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return params.value || "-";
        },
      },
      {
        headerName: this.$t("count.verifier"),
        field: "verifier",
        minWidth: 300,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return params.value || "-";
        },
      },
    ];
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
  computed: {
    rowData() {
      //console.log("dataTable", this.dataTable[5]);
      return this.dataTable.map((counting_item, index) => {
        if (counting_item.id_counting_item) {
          let counting_true = {
            index: (this.page - 1) * 100 + index + 1,
            id_fixed_asset: counting_item.id_fixed_asset,
            name: counting_item.name ? counting_item.name : "-",
            description: counting_item.description
              ? counting_item.description
              : "-",
            note: counting_item.note_counting
              ? counting_item.note_counting
              : "-",
            status:
              counting_item.is_lost === "lost"
                ? this.$t("count.lost")
                : this.assetStatusTranslate(
                    counting_item.damaged_status || counting_item.status
                  ),
            qrcode: counting_item.id_qrcode || this.$t("count.not_reg"),
            ver_date_time: moment(counting_item.counting_time).isValid()
              ? moment(counting_item.counting_time).format("DD/MM/YYYY - HH:mm")
              : null,
            verifier: counting_item.inspector,
          };
          return counting_true;
        } else {
          let counting_false = {
            index: (this.page - 1) * 100 + index + 1,
            id_fixed_asset: counting_item.id_fixed_asset,
            name: counting_item.name ? counting_item.name : "-",
            description: counting_item.description
              ? counting_item.description
              : "-",
            note: "-",
            // note: counting_item.note ? counting_item.note : "-",
            use: false,
            not_use: false,
            fix: false,
            not_fix: false,
            wait_confirm: false,
            lost: false,
            qrcode: this.$t("count.not_reg"),
          };
          return counting_false;
        }
      });
    },
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  methods: {
    assetStatusTranslate(text) {
      switch (text) {
        case "ใช้งานได้":
          return this.$t("count.inuse");
        case "ไม่ได้ใช้งาน":
          return this.$t("count.not_inuse");
        case "ชำรุด":
          return this.$t("count.defect");
        case "อยู่ระหว่างการซ่อม":
          return this.$t("count.repairing");
        case "ซ่อมได้":
          return this.$t("count.repar");
        case "ซ่อมไม่ได้":
          return this.$t("count.irrepar");
        case "รอการประเมิน":
          return this.$t("count.wait_as");
        case "กำลังซ่อม":
          return this.$t("maintenance.fixing");
        case "ซ่อมแล้ว":
          return this.$t("maintenance.fixed");
        default:
          return "";
      }
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.dataTable.find(
          (asset) =>
            node.data.id_fixed_asset ===
            (asset.id_fixed_asset || asset.asset.id_fixed_asset)
        )
      );
      return selectedData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
  components: {
    AgGridVue,
    iconCheck,
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
