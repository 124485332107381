<template>
  <div>
   <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
  <div v-if="showMaintenanceReport" class="maintenance-report">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <transition name="slide">
      <div class="maintenance-report-data-wrapper" v-if="showReport">
        <MaintenanceReportData
          :assetData="assetData"
          @close-report-data="closeReportData"
          @submit-report-data="openReportRecheck"
        />
      </div>
    </transition>
    <transition name="slide">
      <div class="maintenance-report-data-wrapper" v-if="showReportRecheck">
        <MaintenanceReportRecheck
          :assetData="assetData"
          :recheckData="recheckData"
          @close-report-data="closeReportData"
          @close-report-data-recheck="showReportRecheck = false"
          @show-report-detail="openReportDetail"
        />
      </div>
    </transition>
    <transition name="slide">
      <div class="maintenance-report-data-wrapper" v-if="showDetail">
        <!-- มาจาก Table ข้อมูลbind selectedOption, id -->
        <MaintenanceReportDetail
          :maintenanceRole="maintenanceRole"
          :dataFromApi="dataFromApi"
          :canChangeStatus="canChangeStatus"
          :ItemIdFromTable="ItemIdFromTable"
          @close-report-data="closeReportData"
          @reload-table="reloadTable"
        />
      </div>
    </transition>
  </div>
</div>
</template>

<script>
import MaintenanceReportData from "./MaintenanceReportData.vue";
import MaintenanceReportRecheck from "./MaintenanceReportRecheck.vue";
import MaintenanceReportDetail from "./MaintenanceReportDetail.vue";
import Loading from "vue-loading-overlay";
import axios from "axios";
import baseUrl from "@/util/backend";
import { authHeader } from "@/store/actions";

export default {
  emits: ["close-report-data", "show-report-detail"],
  data() {
    return {
      id_company: "",
      dataFromApi: {},
      isLoading: false,
      showDetail: false,
      showReport: false,
      showMaintenanceReport: false,
      showReportRecheck: false,
      recheckData: null,
      ItemIdFromTable: null,
    };
  },
  props: {
    maintenanceRole: {
      type: String,
      default: "user",
    },
    assetData: {
      type: Object,
      default: null,
    },
    canChangeStatus: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loading,
    MaintenanceReportData,
    MaintenanceReportRecheck,
    MaintenanceReportDetail,
  },
  methods: {
    reloadTable() {
      this.$emit("reload-table");
    },
    async openReportDetail(ItemId) {
      this.isLoading= false
      try {
        this.ItemIdFromTable = ItemId;
        const res = await axios.get(
          `${baseUrl()}maintain/${this.ItemIdFromTable}/detail`,
          {
            params: { id_company: this.id_company },
            ...authHeader(),
          }
        );
        this.dataFromApi = res.data.message;
        // this.dataFromApi = {
        //   maintain_ref: 1234,
        //   asset_name: "test",
        //   asset_image_url:
        //     "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
        //   description: "test",
        //   type: "common",
        //   status: "pending",
        //   priority: "null",
        //   request_user_info: {
        //     name: "test",
        //     department: "test",
        //     email: "test",
        //     telephone: "test",
        //   },
        //   technician_info: {
        //     name: "test",
        //     department: "test",
        //     email: "test",
        //     telephone: "test",
        //   },
        //   imagesFile: [],
        //   images: [],
        //   asset_img: [],
        // };
        // console.log(this.dataFromApi);
        setTimeout(() => {
          this.showMaintenanceReport = true;
          this.showDetail = true;
        }, 100);
        // console.log(this.ItemIdFromTable);
      } catch (error) {
        this.isLoading = false;
        // if (error.response && error.response.data && error.response.data.message) {
        //   alert(error.response.data.message);
        // } else {
        //   alert(error.message || error)
        // }
        console.log(error);
      }
    },
    openReportData() {
      //เรียกผ่าน ref (maintenanceReport)
      this.showMaintenanceReport = true;
      setTimeout(() => {
        this.showReport = true;
      }, 100);
    },
    closeReportData() {
      this.showDetail = false;
      this.showReport = false;
      this.showReportRecheck = false;
      setTimeout(() => {
        this.showMaintenanceReport = false;
        this.$emit("close-report-data");
      }, 400);
    },
    openReportRecheck(data) {
      this.recheckData = data;
      this.showReportRecheck = true;
    },
  },
  async mounted() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
  },
};
</script>

<style lang="scss" scoped>
.maintenance-report {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
}

.maintenance-report-data-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.4s;
}

.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}

.slide-enter {
  transform: translateX(200%);
}

.slide-leave-to {
  transform: translateX(200%);
}
@media screen and (max-width: 426px) {
  .maintenance-report-data-wrapper {
    width: 100%;
  }
}

@media screen and (min-width: 426px) and (max-width: 992px) {
  .maintenance-report-data-wrapper {
    position: absolute;
    left: 50%;
    margin-left: -200px;
    width: 400px;
  }
}
</style>
