<template>
  <div class="responsive-bar">
    <Footer></Footer>
    <div class="register">
      <!-- <Background></Background> -->
      <Loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        loader="dots"
      ></Loading>
      <div class="card-div-2">
        <div class="back-icon">
          <img src="../assets/previous.svg" width="30" @click="goBack()" />
        </div>
        <h1 class="header-text">
          <div class="topic">{{ $t("registeritem.as_reg") }}</div>
        </h1>

        <!-- Form -->
        <div class="card-form-login">
          <!-- PROFILE -->
          <div class="frame-profile">
            <img v-if="url" :src="url" class="banner-profile" />
            <img
              v-else
              class="banner"
              src="../assets/image.svg"
              style="cursor: pointer"
              alt=""
              @click="$refs.photo.click()"
            />
            <img
              v-if="assetsPicture !== ''"
              class="banner"
              :src="assetsPicture"
              alt=""
            />
            <div class="upload-icon-plus">
              <label for="fusk">
                <img src="../assets/add.svg" style="cursor: pointer" />
              </label>
              <input
                ref="photo"
                id="fusk"
                type="file"
                accept="image/png, image/jpeg"
                name="photo"
                style="display: none; margin: 0px !important"
                @change="onFileChange($event)"
              />
            </div>
          </div>
          <div class="mb-5">
            <label>
              {{ $t("registeritem.qr_id") }}: {{ data.id_qrcode }}
            </label>
          </div>

          <form @submit.prevent="registerItem">
            <div class="input-group">
              <label
                >{{ $t("registeritem.as_num") }}:
                <span class="require-red">*</span>
              </label>

              <!-- <v-select
                :options="optionSelect"
                v-model="assetFixedId"
                disabled
                style="background: #e0e0e0;"
              /> -->
              <input type="text" v-model="assetFixedId" required disabled />
            </div>

            <div class="input-group">
              <label
                >{{ $t("registeritem.as_name")
                }}<span class="require-red">*</span></label
              >

              <input
                type="text"
                v-model="data.name"
                required
                :disabled="!assetFixedId"
              />
            </div>

            <div class="input-group">
              <label
                >{{ $t("asset.as_cat")
                }}<span class="require-red">*</span></label
              >
              <select
                v-model="data.category"
                class="dropdown form-control w-100"
                :disabled="!assetFixedId"
              >
                <option :value="null">{{ $t("home.not_specified") }}</option>
                <option
                  v-for="cate in assetOptions.optionCategory"
                  :key="cate.id_category"
                  :value="cate.id_category"
                >
                  {{ cate.name }}
                </option>
              </select>
            </div>

            <div class="input-group">
              <label
                >{{ $t("asset.as_sub_cat")
                }}<span class="require-red">*</span></label
              >
              <select
                v-model="data.sub_category"
                class="dropdown form-control w-100"
                :disabled="!assetFixedId"
              >
                <option :value="null">{{ $t("home.not_specified") }}</option>
                <option
                  v-for="sub in subOptionCategory"
                  :key="sub.id_sub_category"
                  :value="sub.id_sub_category"
                >
                  {{ sub.name }}
                </option>
              </select>
            </div>
            <div class="input-group" v-if="canUseCustom4">
              <label
                >{{ $t("model.model") }}
                <!-- <span class="require-red">*</span> -->
              </label>
              <v-select
                :clearable="false"
                class="search-select"
                label="name"
                :reduce="(option) => option.id_sub_category2"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_sub_category2: null,
                  },
                  ...subOptionCategory2,
                ]"
                v-model="data.id_sub_category2"
                :disabled="!data.sub_category || !assetFixedId"
              />
            </div>

            <div class="input-group">
              <label>{{ $t("asset.as_dt") }}</label>
              <textarea
                v-model="data.description"
                :disabled="!assetFixedId"
              ></textarea>
            </div>

            <div class="input-group">
              <label>{{ $t("asset.note") }}</label>
              <textarea
                v-model="data.note"
                :disabled="!assetFixedId"
              ></textarea>
            </div>

            <div class="input-group">
              <label
                >{{ $t("home.as_loc")
                }}<span class="require-red">*</span></label
              >

              <label
                >{{ $t("registeritem.build")
                }}<span class="require-red" v-if="canUseCustom5">*</span></label
              >
              <v-select
                :clearable="false"
                class="search-select"
                label="name"
                :reduce="(option) => option.id_location_building"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_location_building: null,
                  },
                  ...(assetOptions.optionBuilding
                    ? assetOptions.optionBuilding
                    : []),
                ]"
                v-model="data.location_building"
                :disabled="!assetFixedId"
              >
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!data.location_building && canUseCustom5"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>
            <div class="input-group">
              <label>{{ $t("registeritem.fl") }}</label>
              <select
                class="dropdown form-control w-100"
                v-model="data.location_floor"
              >
                <option :value="null">{{ $t("home.not_specified") }}</option>
                <option
                  v-for="floor in floorOptions"
                  :key="floor.id_location_floor"
                  :value="floor.id_location_floor"
                >
                  {{ floor.name }}
                </option>
              </select>
            </div>
            <div class="input-group">
              <label>{{ $t("registeritem.dep") }}</label>
              <v-select
                class="search-select"
                label="label"
                :reduce="(option) => option.value"
                :options="optionDepartment"
                v-model="data.location_department"
                :disabled="!assetFixedId"
              />
            </div>

            <div class="input-group">
              <label
                >{{ $t("registeritem.zone")
                }}<span class="require-red" v-if="canUseCustom5">*</span></label
              >
              <select
                class="dropdown form-control w-100"
                v-model="data.location_zone"
                :required="canUseCustom5"
              >
                <option :value="null">{{ $t("home.not_specified") }}</option>
                <option
                  v-for="zone in assetOptions.optionZone"
                  :key="zone.id_location_zone"
                  :value="zone.id_location_zone"
                >
                  {{ zone.name }}
                </option>
              </select>
            </div>

            <div class="modal fade">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <label class="font-18"
                    >{{ $t("registeritem.specify_loc") }}
                  </label>
                  <button
                    type="button"
                    class="btn btn-indoor-modal"
                    data-dismiss="modal"
                  >
                    {{ $t("registeritem.conf") }}
                  </button>
                </div>
              </div>
            </div>

            <div class="input-group py-3">
              <image-map :disabled="!isMap">
                <image-map-display v-if="isMap" class="mt-3" />
              </image-map>
            </div>

            <div v-if="!isRoleLower">
              <template v-if="canUseDepreciation">
                <ExpandHeightHeader
                  :expand="expandDpInfo"
                  :label="$t('registeritem.add_dp_info')"
                  @toggle="expandDpInfo = !expandDpInfo"
                />
                <ExpandHeight>
                  <div class="py-3" v-if="expandDpInfo">
                    <div class="input-group">
                      <label>
                        {{ $t("asset.acc_note") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="(option) => option.name || '-'"
                        :reduce="(option) => option"
                        :options="[
                          {
                            name: $t('home.not_specified'),
                            id_location_zone: null,
                          },
                          ...(assetOptions.optionsNoteAccounting
                            ? assetOptions.optionsNoteAccounting
                            : []),
                        ]"
                        v-model="data.note_accounting"
                        :disabled="!assetFixedId"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("registeritem.note_accounting_code") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_asset && option.name_asset
                              ? `${option.code_asset} - ${option.name_asset}`
                              : `-`
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="data.sub_note_accounting"
                        :disabled="!data.note_accounting"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("depreciation.code_acc") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_acc && option.name_acc
                              ? `${option.code_acc} - ${option.name_acc}`
                              : '-'
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="data.sub_note_accounting"
                        :disabled="!data.note_accounting"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("asset.accumulate_dep_acc_code") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_acc_dp && option.name_acc_dp
                              ? `${option.code_acc_dp} - ${option.name_acc_dp}`
                              : '-'
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="data.sub_note_accounting"
                        :disabled="!data.note_accounting"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.purchase_date") }}</label>
                      <!-- <p>{{data.purchase_date}}</p>  -->
                      <date-picker
                        class="inline-block h-full"
                        v-model="data.purchase_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.start_date") }}</label>

                      <date-picker
                        class="inline-block h-full"
                        v-model="data.start_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.purchase_price") }}</label>

                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.purchase_price"
                        :disabled="!assetFixedId"
                      />
                    </div>
                    <div class="input-group">
                      <label>{{ $t("asset.useful_life") }}</label>

                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model="data.useful_life"
                        :disabled="!assetFixedId"
                      />
                    </div>
                    <div class="input-group">
                      <label>{{ $t("asset.scrap_value") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.scrap_value"
                        :disabled="!assetFixedId"
                      />
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.dep_date") }}</label>
                      <date-picker
                        class="inline-block h-full"
                        v-model="data.depreciation_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.prior_dep") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.prior_depreciation"
                        :disabled="!assetFixedId"
                      />
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.prior_nb") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.prior_net_book"
                        :disabled="!assetFixedId"
                      />
                    </div>
                  </div>
                </ExpandHeight>
              </template>
            </div>

            <div v-if="otherColumnAsset.length >= 0">
              <ExpandHeightHeader
                :expand="expandAdditionalInfo"
                :label="$t('registeritem.additional_info')"
                @toggle="expandAdditionalInfo = !expandAdditionalInfo"
              />
              <ExpandHeight>
                <div class="py-3" v-if="expandAdditionalInfo">
                  <div
                    class="input-group"
                    v-for="(item, index) in otherColumnAsset"
                    :key="index"
                  >
                    <label>
                      {{ item.name }}
                      <span
                        class="require-red"
                        v-if="
                          (canUseCustom5 && item.column_no == 2) ||
                            (canUseCustom7 && item.column_no == 1)
                        "
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="item.value"
                      :disabled="!assetFixedId"
                      :required="
                        (canUseCustom5 && item.column_no == 2) ||
                          (canUseCustom7 && item.column_no == 1)
                      "
                    />
                  </div>
                </div>
              </ExpandHeight>
            </div>

            <button class="btn btn-login" type="submit">
              {{ $t("registeritem.regist") }}
            </button>
          </form>
        </div>
        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Swal from "sweetalert2";
import ImageMap from "@/components/ImageMap.vue";
import ImageMapDisplay from "@/components/ImageMapDisplay.vue";
// import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import { mapGetters, mapActions } from "vuex";

// import Background from "@/components/Background.vue";
import Footer from "@/components/Footer.vue";
import Compressor from "compressorjs";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import axios from "axios";
import { authHeader } from "../store/actions";
import moment from "moment";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import baseUrl from "../util/backend";
import ExpandHeight from "@/components/Animation/expandHeight.vue";
import ExpandHeightHeader from "@/components/ExpandHeightHeader.vue";

import customInputAssetInfo from "../components/CustomComponent/customInputAssetInfo.vue";
//  ?? lib auto complete https://www.npmjs.com/package/@trevoreyre/autocomplete-vue#getresultvalue

export default {
  data() {
    return {
      isMap: false,
      isAllowToRegis: false,
      id_asset: "",
      isLoading: true,
      fullPage: true,
      showSucces: true,
      onCancel: false,
      assetsPicture: "",
      url: "",
      pictureFile: null,
      assetOptions: {},
      otherColumn: [],
      otherColumnAsset: [],
      data: {
        id_company: null,
        name: null,
        category: null,
        sub_category: null,
        id_sub_category2: null,
        description: "",
        location_building: null,
        location_floor: null,
        location_department: "",
        location_zone: "",
        location_mapX: "",
        location_mapY: "",
        id_fixed_asset: "",
        registrant: "",
        id_qrcode: "",
        note: "",
        start_date: "",
        purchase_date: "",
        purchase_price: "",
        useful_life: "",
        acc_depreciation: null,
        net_book: null,
        sector: null,
        branch: null,
        custom_start_time: null,
        custom_end_time: null,
        vendor: null,
        asset_details: [],
        note_accounting: {},
        sub_note_accounting: {},
      },
      new_building: "",
      new_floor: "",
      map: "",
      user: "",
      regisid: "",
      assetFixedId: null,
      expandDpInfo: false,
      expandAdditionalInfo: false,
    };
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  components: {
    autocomplete,
    Footer,
    Loading,
    ImageMap,
    ImageMapDisplay,
    DatePicker,
    customInputAssetInfo,
    ExpandHeight,
    ExpandHeightHeader,
  },

  watch: {
    "data.category"(_, oldItem) {
      if (oldItem) {
        this.data.sub_category = null;
      }
    },
    "data.location_building"(_, oldItem) {
      if (oldItem) {
        this.data.location_floor = null;
      }
    },
    "data.location_floor"() {
      this.checkMap();
      this.$store.dispatch("setMapPosition", { x: null, y: null });
    },
    "data.note_accounting"(val, oldVal) {
      if (oldVal.note_accounting_id !== val.note_accounting_id) {
        this.data.sub_note_accounting = null;
      }
    },
  },

  computed: {
    ...mapGetters({
      getMapPosition: "getMapPosition",
      getAllMap: "getAllMap",
      getRegisAsset: "getRegisAsset",
      getAllAssets: "getAllAssetsStore",
      regisManual: "regisManual",
      detailAssetRegisterNow: "detailAssetRegisterNow",
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
      canUseDepreciation: "canUseDepreciation",
      canUseCustom4: "canUseCustom4",
      canUseCustom5: "canUseCustom5",
      canUseCustom7: "canUseCustom7",
    }),
    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
    subOptionCategory() {
      const subOptions = this.assetOptions.optionSubCategory?.filter(
        (opt) => opt.id_category === this.data.category
      );
      return subOptions || [];
    },
    subOptionCategory2() {
      const subOptions = this.assetOptions.optionSubCategory2?.filter(
        (opt) => opt.id_sub_category === this.data.sub_category
      );
      return subOptions || [];
    },
    optionDepartment() {
      let department = this.assetOptions?.optionDepartment?.map((opt) => {
        return {
          label: opt.name,
          value: opt.id_location_department,
        };
      });
      if (department) {
        department.unshift({
          label: this.$t("home.not_specified"),
          value: null,
        });
      } else {
        department = [
          {
            label: this.$t("home.not_specified"),
            value: null,
          },
        ];
      }
      return department;
    },
    floorOptions() {
      // return [];
      const floorOptions = this.assetOptions.optionFloor?.filter(
        (opt) => opt.id_location_building === this.data.location_building
      );
      return floorOptions || [];
    },
    getSubNoteAccountingOptions() {
      // return this.assetOptions.optionsNoteAccounting
      const noteAccouting = this.assetOptions?.optionsNoteAccounting?.find(
        (note_acc) => {
          return (
            note_acc.note_accounting_id ==
            this.data?.note_accounting.note_accounting_id
          );
        }
      );
      return noteAccouting?.sub_note_accountings || [];
    },
  },

  methods: {
    ...mapActions(["regisAsset", "sendAllMap"]),
    setDataData() {
      console.log("this.detailAssetRegisterNow", this.detailAssetRegisterNow);
      this.assetFixedId = this.detailAssetRegisterNow.asset.id_fixed_asset;
      this.data.name = this.detailAssetRegisterNow.asset.name;
      this.data.id_fixed_asset = this.detailAssetRegisterNow.asset.id_fixed_asset;
      this.data.category = this.detailAssetRegisterNow.asset.categories?.id_category;
      this.data.sub_category = this.detailAssetRegisterNow.asset.sub_category?.id_sub_category;
      this.data.description = this.detailAssetRegisterNow.asset.description;
      this.data.note = this.detailAssetRegisterNow.asset.note;
      this.data.location_building = this.detailAssetRegisterNow.asset.id_location_building;
      this.data.location_floor = this.detailAssetRegisterNow.asset.id_location_floor;
      this.data.location_department = this.detailAssetRegisterNow.asset.id_location_department;
      this.data.location_zone = this.detailAssetRegisterNow.asset.id_location_zone;
      this.id_asset = this.detailAssetRegisterNow.asset.id_asset;
      this.data.start_date = this.detailAssetRegisterNow.asset.start_date;
      this.data.purchase_date = this.detailAssetRegisterNow.asset.purchase_date;
      this.data.purchase_price = this.detailAssetRegisterNow.asset.purchase_price;
      this.data.useful_life = this.detailAssetRegisterNow.asset.useful_life;
      this.data.acc_depreciation = this.detailAssetRegisterNow.asset.acc_depreciation;
      this.data.net_book = this.detailAssetRegisterNow.asset.net_book;
      this.data.asset_details = this.detailAssetRegisterNow.asset.asset_details;
      this.data.sector = this.detailAssetRegisterNow.asset.sector?.sector_id;
      this.data.branch = this.detailAssetRegisterNow.asset.branch?.branch_id;
      this.data.vendor = this.detailAssetRegisterNow.asset.vendor?.vendor_id;
      this.data.custom_start_time = this.detailAssetRegisterNow.asset.custom_start_time;
      this.data.custom_end_time = this.detailAssetRegisterNow.asset.custom_end_time;
      this.loadOtherColumn();
    },

    setCustomData(e) {
      this.data = { ...this.data, ...e };
    },
    checkMap() {
      const floor = this.assetOptions.optionFloor?.find(
        (opt) => opt.id_location_floor === this.data.location_floor
      );
      if (floor) {
        this.isMap = true;
        this.$store.dispatch("setMapUrl", floor.image_url || "");
      } else {
        this.isMap = false;
      }
    },

    goBack() {
      this.$router.back();
    },

    getResultValue(result) {
      return result.id_fixed_asset;
    },
    selectRegion(e) {
      console.log(e);
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },

    async upload() {
      this.isLoading = true;
      const fileforupload = await this.compressImage(this.pictureFile);
      var file = new File([fileforupload], this.pictureFile.name, {
        type: "image/jpeg",
      });

      // const id_asset = this.data.id_qrcode;
      // this.spinner_profile = true;

      console.log(`before compress`, this.pictureFile);
      console.log(`after compress`, fileforupload);

      let fd = new FormData();
      let formdata = new FormData();
      formdata.append("folder_id", 2);
      formdata.append("file", file);
      // console.log(`fileforupload`, fileforupload);
      // console.log("fddd", file);
      const res = await this.$store.dispatch("uploadImage", formdata);
      console.log("res.data.data;", res.data.data);
      this.url_new = res.data.data.uploadFileAtLevel.url;
      this.data.image_url = res.data.data.uploadFileAtLevel.url;
      fd.append("image_url", this.url_new);
      // axios
      //   .post("https://api.verscan.com/file/api/file/create", formdata, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((res) => {
      //     console.log("res.data.data;", res.data.data);
      //     this.url_new = res.data.data.uploadFileAtLevel.url;
      //     this.data.image_url = res.data.data.uploadFileAtLevel.url;
      //     fd.append("image_url", this.url_new);
      //     //this.img1 = res.data.data; // แก้ตรงนี้
      //   });

      // fd.append("id_company", id_company);
      // fd.append("id_asset", id_asset);
      console.log(`fileforupload`, fileforupload);

      this.data.data_form = fd;
      this.isLoading = false;
    },

    async onFileChange(e) {
      const file = e.target.files[0];
      this.pictureFile = file;
      if (!(await this.$store.dispatch("checkFileType", file))) {
        e.target.value = null;
        return Swal.fire(this.$t("home.up_img"));
      }
      this.url = URL.createObjectURL(file);
      await this.upload();
      e.target = null;
      e.preventDefault();
    },
    momentDay(date) {
      if (date) {
        return moment(date).isValid()
          ? moment(date)
              .startOf("d")
              .toISOString()
          : null;
      } else {
        return null;
      }
    },
    async validateCustomInput() {
      if (!this.canUseCustom7) return true;
      const other3 = this.data.asset_details.find(
        (detail) => detail.column_no == 3
      );
      const errorDetail = [];
      if (!other3?.value.includes(" - ")) {
        errorDetail.push(other3.name);
      }
      if (!this.data.description.includes(" - ")) {
        errorDetail.push(this.$t("asset.as_dt"));
      }
      if (errorDetail.length > 0) {
        const errorText = errorDetail.join(", ");
        const result = await Swal.fire({
          icon: "error",
          text: `${errorText} ${this.$t("dialog.incorrect_confirm")}`,
          showCancelButton: true,
          confirmButtonText: this.$t("btn.conf"),
          cancelButtonText: this.$t("btn.canc"),
          confirmButtonColor: "#007AFE",
          cancelButtonColor: "#C4C4C4",
          reverseButtons: true,
        });
        return result.isConfirmed;
      } else {
        return true;
      }
    },
    async registerItem(e) {
      e.preventDefault();
      if (this.pictureFile != null) {
        const check = await this.validateCustomInput();
        if (!check) return;
        this.isLoading = true;
        let position = await this.getMapPosition;
        this.data.registrant = this.user.name;
        this.data.purchase_date = this.momentDay(this.data.purchase_date);
        this.data.start_date = this.momentDay(this.data.start_date);
        this.data.location_mapX = position.x;
        this.data.location_mapY = position.y;
        this.data.asset_details = this.otherColumnAsset;

        try {
          await this.$store.dispatch("regisAsset", {
            ...this.data,
            id_company: this.id_company,
            id_asset: this.id_asset,
            page: "registerNow",
          });
        } catch (err) {
          if (err === "Reach package limit") {
            Swal.fire({
              icon: "error",
              text: this.$t("dialog.register_limit"),
            });
          }
        }
        this.isLoading = false;
      } else {
        alert(this.$t("dialog.upload_asset_img"));
      }
    },

    hide() {
      setTimeout(() => {
        this.showSucces = false;
      }, 3000);
    },
    async loadAssetOptions() {
      try {
        const res = await axios.get(
          `${baseUrl()}asset/get/option/${this.id_company}`,
          authHeader()
        );
        this.assetOptions = res.data.data;
        console.log(this.assetOptions);
      } catch (err) {
        console.log(err);
      }
    },
    async loadOtherColumn() {
      try {
        const res = await axios.get(
          `${baseUrl()}admin/other_column?id_company=${this.id_company}`,
          authHeader()
        );
        this.otherColumn = res.data.data;
        this.mapOtherColumn();
      } catch (err) {
        console.log(err);
      }
    },
    async mapOtherColumn() {
      const otherColumnArr = [];
      for (const column of this.otherColumn) {
        let pushed = false;
        for (const detail of this.data.asset_details) {
          if (detail.column_no === column.column_no) {
            otherColumnArr.push(detail);
            pushed = true;
          }
        }
        if (!pushed) {
          otherColumnArr.push({ ...column, value: null });
        }
      }
      console.log("otherColumnArr");
      console.log(otherColumnArr);
      this.otherColumnAsset = otherColumnArr;
    },
  },
  async mounted() {
    this.data.id_qrcode = this.$route.params.id;
    if (!this.detailAssetRegisterNow) {
      await this.$store.dispatch("getDetailAssetsRegisterNow", {
        asset_id: localStorage.getItem("id_asset"),
        id_qrcode: this.data.id_qrcode,
      });
    }
    await this.$store
      .dispatch("getAssetRegisterNow", this.data.id_qrcode)
      .then((res) => {
        if (res) {
          this.$router.push(`/check/${this.$route.params.id}/registerNow`);
        }
      });
    this.user = this.$store.getters.getUserDetail;
    // console.log("get name >>>>>>>>", this.user.name);
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    // await this.$store.dispatch("firstGetAllAsset", {
    //   company_id: id_company, //this.user.id_company
    //   isCountingDate: true,
    // });
    await this.loadAssetOptions();
    this.setDataData();

    // this.$store.dispatch("sendAllMap", id_company); // must change to id company
    // this.$store.dispatch("getimportRegisAsset", id_company); //must change to id company
    //
    // this.sendAllMap(id_company).then(() => {
    //   this.getAllMap();
    // });
    this.isLoading = false;
    this.hide();
    console.log("mounted");
    this.checkMap();
  },

  created() {
    this.data.id_qrcode = this.$route.params.id;
  },
};
</script>
<style lang="scss" scoped>
.search-select {
  ::v-deep .vs__search::placeholder,
  ::v-deep .vs__dropdown-toggle {
    background: #f4f4f4;
    border: none;
    height: 40px;
  }
}

.sub-text {
  margin-top: 0px !important;
  margin-bottom: 2px !important;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  border: 2px solid #eee;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}
.btn-indoor-modal {
  width: 77px;
  text-align: center;
  margin: auto;
}

.indoor-map-modal {
  padding-bottom: 1rem;
}
.modal-content {
  padding: 1rem;
  border-radius: 35px;
  height: auto;

  & label {
    color: #4b4b4b;
    padding: 0.4rem;
  }
}

.indoor-map {
  display: flex;
  justify-content: center;
  height: 400px;
  width: 100%; // or width instead of max-width
  background: white;
  position: relative;
  border: 1px solid #d0d0d0 !important;
  border-radius: 11px;
  overflow: hidden;

  /* border-radius: 0.75rem; */

  img {
    height: 200%; /* probably looks neater if auto */
    width: 200%; /* double width image to show only first quarter */
    vertical-align: bottom; /* moves image to true text bottom */

    /* width: auto;
    max-width: 100% !important;
    height: auto; */
    /* object-fit: scale-down; */
  }
}

.indoor-map-none {
  border-radius: 12px;
  border: 1px solid #bcbaba;
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("https://api.verscan.com/download/no-img.jpg");
  background-size: cover;
  p {
    opacity: 1;
    font-size: 1.25rem;
    box-shadow: 0 0 black;
    background: white;
  }
}
.frame-profile {
  // top: -0.2rem;
  transform: translate(0%, -10%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  /* text-align: center; */
  margin: auto;
  background: #e0e0e0;
  border-radius: 10px;
  border: 2px solid white;
  z-index: 90;
}
.upload-icon-plus {
  z-index: 90;
  position: absolute;
  bottom: 30%;
  right: 1px;

  img {
    display: inline-block;
    position: absolute;
    top: 2rem;
    right: 30%;
    width: 31px;
    height: 31px;
  }
}
.upload-icon-content {
  display: flex;
  font-size: 4rem;
  align-items: center;
}
.banner-profile {
  height: 104px;
  width: 104px;
  border-radius: 10px;
  z-index: 10;
}
.banner {
  // margin-top: -0.5rem;
  height: 4rem;
  width: 4rem;
  z-index: 10;
}

.register {
  overflow: auto;
  position: relative;
  z-index: 2;
}
.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

input,
select,
textarea {
  border-radius: 8px !important;
  background-color: #e0e0e0;
  border: none;
  padding: 0.5rem 1rem;
}

// select {
//   background: white;
// }

// textarea {
//   padding-bottom: 1.5rem;
// }

.btn {
  padding: 0.55rem 1rem 0.55rem 1rem;
}

.btn-asset {
  padding: 0 !important;

  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 12px;
}

.custom-input-file {
  cursor: pointer;
  padding: 0.55rem 1rem 0.55rem 1rem;
}

.header-text {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
}
.card-div-2 {
  margin: auto;
  /* width: 100vw; */
  height: 100vh;
  // background: linear-gradient(90deg, rgb(0, 123, 255), rgb(0, 62, 128));
}

.card-form-login {
  padding-top: 3rem;
}

// @media only screen and (min- {
//   .footer[data-v-40ab164b] {
//     position: relative;
//     width: 100%;
//     height: 180% !important;
//     margin: 0 !important;
//     padding-top: 0;
//     box-shadow: 0px 0px 10px #00000067;
//   }
// }
@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .card-form-login {
    padding-top: 3rem;
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media only screen and (min-width: 1025px) {
  .card-form-login {
    padding-top: 3rem;
    margin-left: 15%;
    margin-right: 15%;
  }
  .footer {
    position: relative;
    top: 0;
    width: 100%;
    height: 100vh !important;
    margin: 0 !important;
    padding-top: 0;
    box-shadow: 0px 0px 10px #00000067;
  }
}
@media only screen and (min-width: 2500px) {
  .card-form-login {
    padding-top: 3rem;
    margin-left: 15%;
    margin-right: 15%;
  }
  .footer[data-v-40ab164b] {
    position: relative;
    width: 100%;
    height: 100% !important;
    margin: 0 !important;
    padding-top: 0;
    box-shadow: 0px 0px 10px #00000067;
  }
}
.upper-canvas {
  position: relative;
  width: 100%;
  height: 100%;
}
.canvas-container {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  // user-select: none;
}
.__view {
  position: relative;
}
.upload-map {
  background: white;
}
</style>
