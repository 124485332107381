<template>
  <div class="responsive-bar">
    <Footer></Footer>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div class="scan-qr">
      <!-- B-Modal Filter -->
      <b-modal
        id="filter-modal"
        @cancel="handleclear"
        :cancel-title="$t('btn.clear')"
        hide-header-close
        hide-header
        :ok-title="$t('btn.conf')"
        @ok="handleFilter"
      >
        <b-row>
          <b-col class="text-end" cols="2"> </b-col>

          <b-col class="text-center" cols="8">
            <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
          </b-col>
          <b-col class="text-end" cols="2">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('filter-modal')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <customFilter
          :customid="permissionStatus.company.custom_id"
          :options="optionChoice"
          @set-filter-option="addCustomFilter"
        />
        <label class="mt-2">{{ $t("home.cat") }}</label>
        <v-select
          :options="optionCategory"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_category"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.sub_cat") }}</label>
        <v-select
          :options="optionSubCategory"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_sub_category"
          class="dropdown-style"
          :clearable="false"
        />

        <div v-if="!id_qrcode">
          <label class="mt-2">{{ $t("home.as_stat") }}</label>
          <select
            class="form-control form-border"
            v-model="filterOptions.status"
          >
            <option
              v-for="(stat, index) in statusData"
              :key="index"
              :value="stat.value"
            >
              {{ stat.name }}
            </option>
          </select>
        </div>

        <label class="mt-2">{{ $t("home.build") }}</label>
        <v-select
          :options="optionBuilding"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_building"
          class="dropdown-style"
          :clearable="false"
        />
        <label class="mt-2">{{ $t("home.fl") }}</label>
        <v-select
          :options="optionFloor"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_floor"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.department") }}</label>
        <v-select
          :options="optionDepartment"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_department"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.zone") }}</label>
        <v-select
          :options="optionZone"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_zone"
          class="dropdown-style"
          :clearable="false"
        />

        <div v-if="!isRoleLower">
          <label class="mt-2">{{ $t("filter.purchase_date") }}</label>

          <div class="d-flex align-items-center w-100">
            <input
              type="date"
              class="form-control form-border"
              v-model="filterOptions.datePurchase_min"
            />

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <input
              type="date"
              class="form-control form-border"
              :min="filterOptions.datePurchase_min"
              v-model="filterOptions.datePurchase_max"
            />
          </div>

          <label class="mt-2">{{ $t("filter.purchase_price") }}</label>

          <div class="d-flex align-items-center w-100">
            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest_price')"
              v-model="filterOptions.purchase_price_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest_price')"
              v-model="filterOptions.purchase_price_max"
            ></vue-numeric>
          </div>

          <label class="mt-2">{{ $t("filter.useful_life") }}</label>
          <div class="d-flex align-items-center w-100">
            <vue-numeric
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest')"
              v-model="filterOptions.useful_life_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest')"
              v-model="filterOptions.useful_life_max"
            ></vue-numeric>
          </div>

          <label class="mt-2">{{ $t("filter.date_start") }}</label>

          <div class="d-flex align-items-center w-100">
            <input
              type="date"
              class="form-control form-border"
              v-model="filterOptions.dateUsed_start"
            />

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <input
              type="date"
              class="form-control form-border"
              :min="filterOptions.dateUsed_start"
              v-model="filterOptions.dateUsed_end"
            />
          </div>

          <!-- <label class="mt-2">{{ $t("filter.acc_depreciation") }}</label>
          <div class="d-flex align-items-center w-100">
            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest_price')"
              v-model="filterOptions.acc_depreciation_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest_price')"
              v-model="filterOptions.acc_depreciation_max"
            ></vue-numeric>
          </div>

          <label class="mt-2">{{ $t("filter.net_book") }}</label>
          <div class="d-flex align-items-center w-100">
            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest_price')"
              v-model="filterOptions.net_book_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest_price')"
              v-model="filterOptions.net_book_max"
            ></vue-numeric>
          </div> -->
        </div>
      </b-modal>

      <!-- Page -->
      <h1 class="header-text">
        <div class="topic" style="" v-if="!id_qrcode">
          {{ $t("asset.all_as") }}
        </div>
        <div class="topic" style="" v-else>
          {{ $t("registeritem.search_asset_reg") }}
        </div>
      </h1>
      <div class="inRemind" v-if="isCounting">
        <p id="inReminder">{{ $t("home.counting") }}</p>
      </div>
      <div class="remind" v-if="!isCounting && nextDate">
        <p id="reminder">
          {{ $t("home.as_countnext") }}
          {{ moment(nextDate).format("DD/MM/YYYY") }}
        </p>
      </div>
      <div class="">
        <div class="card-form-login">
          <div class="sub-menu">
            <div class="input-group">
              <TmpFilter
                :searchTerm="searchText"
                @set-search-term="
                  (text) => {
                    searchText = text;
                    searchByText();
                  }
                "
              />
              <span
                class="input-group-text"
                id="basic-addon1"
                style="border-radius: 10px 0 0 10px"
              >
                <!-- @click="resetSearch" -->
                <img src="../assets/g-search.svg" width="20px" />
              </span>
              <input
                type="text"
                class="form-control filter-box"
                style="
                  border-right-style: none;
                  margin-right: 1rem;
                  border-radius: 0 10px 10px 0;
                "
                v-debounce:700ms="searchByText"
                :placeholder="$t('home.search')"
                v-model="searchText"
              />
              <button
                class="filter"
                style="border: none"
                type="button"
                id="button-addon2"
                @click="$bvModal.show('filter-modal')"
              >
                <!-- @click="filterShow = true" -->
                <img src="../assets/filter-white.svg" />
              </button>
            </div>
            <div
              class="d-flex align-items-center px-3 filter-count-box pb-3"
              v-if="filterCount > 0"
            >
              <div class="filter-count me-3">
                <p class="px-2 m-0">
                  {{ $t("filter.filter_count") }}:
                  <span class="font-weight-bold">{{ filterCount }}</span>
                </p>
              </div>
              <u @click="handleclear" class="m-0" style="cursor: pointer">{{
                $t("filter.clear_filter")
              }}</u>
            </div>
            <p class="text-start px-3" style="font-size: 14px; color: #7c7c7c;">
              {{ totalpages }} {{ $t("asset.total_items") }}
            </p>
            <div
              v-if="page === 'maintenance_plan'"
              class="d-flex justify-content-between my-3 align-items-center"
            >
              <p class="fw-bold text-black m-0">
                <nobr>{{
                  $t("maintenance_plan.selected_amount", {
                    amount: selectedAssetList.length,
                  })
                }}</nobr>
              </p>
              <div>
                <button
                  class="btn px-4 cancel"
                  style="min-width: 0"
                  @click="$router.back()"
                >
                  <p class="m-0">
                    <nobr>{{ $t("btn.canc") }}</nobr>
                  </p>
                </button>
                <button
                  class="btn px-4"
                  style="min-width: 0"
                  @click="setSelectedAsset"
                >
                  <p class="m-0">
                    <nobr>{{ $t("btn.conf_select") }}</nobr>
                  </p>
                </button>
              </div>
            </div>
          </div>

          <div class="asset-box" v-if="getAllAssets.length > 0">
            <!-- Detail Each Asset -->
            <div
              v-for="asset in getAllAssets"
              :key="asset.id_asset"
              class="item-asset"
              :class="{
                'selected-item': selectedAssetList.some(
                  (item) => item.id_asset === asset.id_asset
                ),
              }"
              @click="onAssetClick(asset)"
            >
              <Lazyloadimage
                :image="checkImage(asset.image_url)"
                :holderimg="holderimg"
                v-if="asset.image_url"
              ></Lazyloadimage>
              <!-- <img
                v-if="asset.image_url"
                class="banner"
                :src="checkImage(asset.image_url)"
                alt=""
              /> -->
              <img v-else class="banner" :src="noimg" alt="" />

              <div class="detail-asset">
                <p class="asset-id">
                  {{ asset.id_fixed_asset }}
                </p>
                <p class="asset-detail">{{ asset.name }}</p>
                <p class="asset-detail">{{ asset.description || "-" }}</p>
              </div>
            </div>

            <!-- End Here -->
          </div>
          <div
            class="d-flex justify-content-center"
            style="margin-top: 25%"
            v-else
          >
            <h1 class="">{{ $t("asset.dt_not") }}</h1>
          </div>
        </div>
        <div
          class="d-flex justify-content-center mt-3"
          v-if="getAllAssets.length > 0"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalpages"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
        <TotalPageCount
          v-if="getAllAssets.length > 0"
          :page="currentPage"
          :perPage="perPage"
          :currentAmount="getAllAssets.length"
          :total="totalpages"
        />
        <div class="line-break"></div>
      </div>
      <!-- back to top visibleoffset = 600px -->
      <!-- <back-to-top bottom="10%" right="20px" visibleoffset="100">
        <div class="icon">
          <img src="../assets/up-chevron.svg" />
        </div>
      </back-to-top> -->
    </div>
  </div>
</template>

<script>
// import Background from "@/components/Background.vue";
import Footer from "@/components/Footer.vue";
import TmpFilter from "@/components/CustomComponent/customTmpFilter.vue";
import moment from "moment";
// import BackToTop from "vue-backtotop";
// import PaginationAssets from "@/components/PaginationAssets.vue";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Lazyloadimage from "../components/Lazyloadimage.vue";
import customFilter from "../components/CustomComponent/customFilterJGT.vue";
import TotalPageCount from "../components/TotalPageCount.vue";

// ? -> back to top button from lib -> https://github.com/caiofsouza/vue-backtotop

export default {
  data() {
    return {
      page: null,
      currentPage: 1,
      perPage: 60,
      isLoading: false,
      isCounting: false,
      isCountingDate: false,
      nextDate: null,
      searchText: "",
      onClick: true,
      filterShow: false,
      rows: 10,
      company_id: null,
      countingStatus: this.$t("filter.all"),
      id_qrcode: null,
      filterOptions: {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
        dateUsed_start: null,
        dateUsed_end: null,
        datePurchase_min: null,
        datePurchase_max: null,
        useful_life_min: "",
        useful_life_max: "",
        purchase_price_min: "",
        purchase_price_max: "",
        acc_depreciation_min: "",
        acc_depreciation_max: "",
        net_book_min: "",
        net_book_max: "",
      },
      saveToStore: true,
      noimg: require("../image/Not-regis-qr-code.png"),
      holderimg: require("../image/Pic_Loading.jpg"),
      categoryData: [
        { name: this.$t("filter.all"), value: "ทั้งหมด" },
        { name: this.$t("filter.mach"), value: "เครื่องจักร" },
        { name: this.$t("filter.off_eq"), value: "อุปกรณ์สำนักงาน" },
        { name: this.$t("filter.fur"), value: "เครื่องตกแต่งสำนักงาน" },
        { name: this.$t("filter.off_sup"), value: "เครื่องใช้สำนักงาน" },
        { name: this.$t("filter.tools"), value: "เครื่องมือและอุปกรณ์" },
        { name: this.$t("filter.veh"), value: "ยานพาหนะ" },
        { name: this.$t("filter.lease_as"), value: "สินทรัพย์ตามสัญญาเช่า" },
        { name: this.$t("filter.sp_part"), value: "อะไหล่/ชิ้นส่วนสำรอง" },
        { name: this.$t("filter.util"), value: "ระบบสาธารณูปโภค" },
        { name: this.$t("filter.others"), value: "สินทรัพย์อื่น" },
      ],
      statusData: [
        { name: this.$t("filter.all"), value: "ทั้งหมด" },
        { name: this.$t("count.usable"), value: "ใช้งานได้" },
        { name: this.$t("count.not_inuse"), value: "ไม่ได้ใช้งาน" },
        { name: this.$t("count.wait_as"), value: "รอการประเมิน" },
        { name: this.$t("count.repar"), value: "ซ่อมได้" },
        { name: this.$t("count.irrepar"), value: "ซ่อมไม่ได้" },
        { name: this.$t("filter.rep_lost"), value: "report" },
        { name: this.$t("filter.lost"), value: "lost" },
        { name: this.$t("asset.not_reg"), value: "ยังไม่ได้ลงทะเบียน" },
        { name: this.$t("asset.reg"), value: "ลงทะเบียนแล้ว" },
      ],
      selectedAssetList: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    this.id_qrcode = this.$route.params.id_qrcode;
    if (this.id_qrcode) {
      this.saveToStore = false;
    }
    if (this.$route.query.page === "maintenance_plan") {
      this.page = this.$route.query.page;
      this.selectedAssetList = [...this.getSelectedAsset];
      this.saveToStore = false;
    }
    // await this.setFilterFromStore();
    const checkCounting = await this.$store.dispatch("checkCountingDate");
    this.isCounting = checkCounting.is_counting;
    this.nextDate = checkCounting.recent_counting;
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.company_id = id_company;

    this.id_qrcode
      ? (this.filterOptions.status = "ยังไม่ได้ลงทะเบียน")
      : this.filterOptions;

    // if (this.getPageState.pageName === "AllAssetListToFloor") {
    //   this.currentPage = this.getPageState.pageNum;
    // }

    await this.$store.dispatch("filterAllAsset_pagination", {
      filterOptions: this.filterOptions,
      saveToStore: this.saveToStore,
      countingStatus: this.countingStatus,
      company_id: id_company,
      isCountingDate: this.isCountingDate,
      page: this.currentPage,
      limit: this.perPage,
    });

    // if (this.$route.name === "All_asset" && !this.page) {
    //   this.$store.dispatch("setPageState", {
    //     pageName: "AllAssetListToFloor",
    //     pageNum: this.currentPage,
    //   });
    // }
    this.isLoading = false;
  },
  methods: {
    moment,
    choicename({ name }) {
      return `${name}`;
    },
    setFilterFromStore() {
      if (this.getAllAssetsFilter) {
        let filter = { ...this.getAllAssetsFilter };
        console.log("filter:", filter);
        if (filter.filterOptions) {
          this.filterOptions = filter.filterOptions;
          this.searchText = this.filterOptions.text;
        }
        // if (filter.countingStatus) {
        //   this.countingStatus = filter.countingStatus;
        //   switch (this.countingStatus) {
        //     case "ทั้งหมด":
        //       this.setFilterAll();
        //       break;
        //     case "ตรวจนับแล้ว":
        //       this.setFilterCounted();
        //       break;
        //     case "ยังไม่ได้ตรวจนับ":
        //       this.setFilterWaiting();
        //       break;
        //     default:
        //     // code block
        //   }
        // }
        if (filter.isCountingDate) {
          this.isCountingDate = filter.isCountingDate;
        }
      }
    },
    async handleclear() {
      this.isLoading = true;
      // this.countingStatus = "ทั้งหมด";
      this.currentPage = 1;
      this.searchText = "";
      this.filterOptions = {
        text: "",
        category: null,
        sub_category: null,
        status: this.id_qrcode ? "ยังไม่ได้ลงทะเบียน" : "ทั้งหมด",
        building: null,
        floor: null,
        department: null,
        zone: null,
        dateUsed_start: null,
        dateUsed_end: null,
        datePurchase_min: null,
        datePurchase_max: null,
        useful_life_min: "",
        useful_life_max: "",
        purchase_price_min: "",
        purchase_price_max: "",
        acc_depreciation_min: "",
        acc_depreciation_max: "",
        net_book_min: "",
        net_book_max: "",
      };
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.company_id = id_company;
      await this.$store.dispatch("filterAllAsset_pagination", {
        filterOptions: this.filterOptions,
        saveToStore: this.saveToStore,
        countingStatus: this.countingStatus,
        company_id: id_company,
        isCountingDate: this.isCountingDate,
        page: 1,
        limit: this.perPage,
      });
      this.isLoading = false;
    },
    async handleFilter() {
      this.isLoading = true;
      this.currentPage = 1;
      await this.$store.dispatch("filterAllAsset_pagination", {
        filterOptions: this.filterOptions,
        saveToStore: this.saveToStore,
        countingStatus: this.countingStatus,
        isCountingFinish: this.isCountingDate,
        page: this.currentPage,
        company_id: this.company_id,
        limit: this.perPage,
      });
      this.isLoading = false;
    },
    checkImage(image) {
      if (typeof image === "string") {
        return JSON.parse(image).url;
      } else {
        return image.url;
      }
    },
    searchByText() {
      this.filterOptions.text = this.searchText;
      this.$store.dispatch("filterAllAsset_pagination", {
        filterOptions: this.filterOptions,
        saveToStore: this.saveToStore,
        countingStatus: this.countingStatus,
        page: 1,
        limit: this.perPage,
        company_id: this.company_id,
      });
    },

    async onAssetClick(asset) {
      if (this.id_qrcode) {
        if (asset.qrcode) {
          alert("สินทรัพย์นี้ลงทะเบียนแล้ว");
        } else {
          this.$store.dispatch("setAssetSelectedRegister", asset.id_asset);
          this.$router.push(
            `/registerItem/${encodeURIComponent(this.id_qrcode)}`
          );
          return;
        }
      } else if (this.page === "maintenance_plan") {
        const checkIndex = this.selectedAssetList.findIndex(
          (item) => asset.id_asset === item.id_asset
        );
        if (checkIndex >= 0) {
          this.selectedAssetList.splice(checkIndex, 1);
        } else {
          this.selectedAssetList.push({
            id_asset: asset.id_asset,
            image_url: asset.image_url,
            name: asset.name,
            id_fixed_asset: asset.id_fixed_asset,
          });
        }
      } else {
        this.$router.push(`/detailOneAsset/${asset.id_asset}`);
      }
    },
    setSelectedAsset() {
      this.$store.dispatch("setMaintenancePlanAsset", this.selectedAssetList);
      this.$router.back();
    },
    eventClick() {
      this.onClick = !this.onClick;
    },
    addCustomFilter(e) {
      this.filterOptions = {
        ...this.filterOptions,
        ...e,
      };
    },
  },
  watch: {
    async currentPage(value) {
      this.isLoading = true;
      try {
        const id_company = await this.$store.dispatch("getCompanyIdCookie");
        this.company_id = id_company;
        await this.$store.dispatch("filterAllAsset_pagination", {
          filterOptions: this.filterOptions,
          saveToStore: this.saveToStore,
          countingStatus: this.countingStatus,
          company_id: id_company,
          isCountingDate: this.isCountingDate,
          page: value,
          limit: this.perPage,
        });
        // if (this.$route.name === "All_asset" && !this.id_qrcode) {
        //   this.$store.dispatch("setPageState", {
        //     pageName: "AllAssetListToFloor",
        //     pageNum: this.currentPage,
        //   });
        // }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    "filterOptions.dateUsed_start"() {
      console.log("watcher", this.filterOptions.dateUsed_start);
      if (
        !this.filterOptions.dateUsed_end ||
        this.filterOptions.dateUsed_end < this.filterOptions.dateUsed_start
      ) {
        this.filterOptions.dateUsed_end = this.filterOptions.dateUsed_start;
      }
    },
    "filterOptions.datePurchase_min"() {
      console.log("watcher", this.filterOptions.datePurchase_min);
      if (
        !this.filterOptions.datePurchase_max ||
        this.filterOptions.datePurchase_max <
          this.filterOptions.datePurchase_min
      ) {
        this.filterOptions.datePurchase_max = this.filterOptions.datePurchase_min;
      }
    },
  },
  computed: {
    ...mapGetters({
      counting_date: "getCountingDate",
      getAllAssets: "getAllAssets",
      getUserDetail: "getUserDetail",
      getAllAssetsStore: "getAllAssetsStore",
      getAllAssetsFilter: "getAllAssetsFilter",
      getDetailAsset: "getDetailAsset",
      isRoleLower: "isRoleLower",
      assets_count: "assets_count",
      optionChoice: "getfilterOption",
      getSelectedAsset: "getSelectedMaintenanceAsset",
      permissionStatus: "permissionStatus",
      getPageState: "getPageState",
    }),
    totalpages() {
      console.log("totalpages", this.assets_count);
      return Math.ceil(this.assets_count);
    },
    filterCount() {
      if (this.getAllAssetsFilter?.filterOptions) {
        let filter = JSON.parse(
          JSON.stringify(this.getAllAssetsFilter.filterOptions)
        );
        console.log("filter", filter);
        let count = 0;
        if (filter.custom_end_time_max && filter.custom_end_time_min) count++;
        if (filter.custom_start_time_max && filter.custom_start_time_min)
          count++;
        if (filter.vendor_id) count++;
        if (filter.sector_id) count++;
        if (filter.branch_id) count++;
        if (filter.category && filter.category !== "ทั้งหมด") count++;
        if (filter.sub_category && filter.sub_category !== "ทั้งหมด") count++;
        if (filter.status && filter.status !== "ทั้งหมด" && !this.id_qrcode)
          count++;
        if (filter.building && filter.building !== "ทั้งหมด") count++;
        if (filter.floor && filter.floor !== "ทั้งหมด") count++;
        if (filter.department && filter.department !== "ทั้งหมด") count++;
        if (filter.zone && filter.zone !== "ทั้งหมด") count++;
        if (filter.dateUsed_start || filter.dateUsed_end) {
          if (filter.dateUsed_start !== null || filter.dateUsed_end !== null) {
            count++;
          }
        }
        if (filter.datePurchase_min || filter.datePurchase_max) {
          if (
            filter.datePurchase_min !== null ||
            filter.datePurchase_min != null
          ) {
            if (
              filter.datePurchase_min != "" ||
              filter.datePurchase_max != ""
            ) {
              count++;
            }
          }
        }

        if (filter.useful_life_min || filter.useful_life_max) {
          if (
            filter.useful_life_min !== null ||
            filter.useful_life_max !== null
          ) {
            if (filter.useful_life_min != "" || filter.useful_life_max != "") {
              count++;
            }
          }
        }

        if (filter.purchase_price_min || filter.purchase_price_max) {
          if (
            filter.purchase_price_min !== null ||
            filter.purchase_price_max !== null
          ) {
            if (
              filter.purchase_price_min != "" ||
              filter.purchase_price_max != ""
            ) {
              count++;
            }
          }
        }
        if (filter.acc_depreciation_min || filter.acc_depreciation_max) {
          if (
            filter.acc_depreciation_min !== null ||
            filter.acc_depreciation_max !== null
          ) {
            if (
              filter.acc_depreciation_min != "" ||
              filter.acc_depreciation_max != ""
            ) {
              count++;
            }
          }
        }

        if (filter.net_book_min || filter.net_book_max) {
          if (filter.net_book_min !== null || filter.net_book_max !== null) {
            if (filter.net_book_min != "" || filter.net_book_max != "") {
              count++;
            }
          }
        }

        return count;
      } else {
        return 0;
      }
    },
    // optionFloor() {
    //   console.log("..");
    //   if (
    //     this.filterOptions.building !== "ทั้งหมด" ||
    //     this.filterOptions.department !== "ทั้งหมด" ||
    //     this.filterOptions.zone !== "ทั้งหมด"
    //   ) {
    //     const floorList = [
    //       "ทั้งหมด",
    //       ...new Set(
    //         this.optionChoice.optionFloor
    //           .filter((item) => {
    //             if (this.filterOptions.building !== "ทั้งหมด") {
    //               let building = this.optionChoice.optionBuilding.find(
    //                 (building) => building.name == this.filterOptions.building
    //               );
    //               if (
    //                 building.id_location_building == item.id_location_building
    //               ) {
    //                 console.log(item.id_location_building);
    //                 return true;
    //               } else {
    //                 return false;
    //               }
    //             }
    //             return true;
    //           })
    //           .map((each) => each.name)
    //       ),
    //     ];
    //     //console.log("Floor list: ", floorList);
    //     return floorList.filter((x) =>
    //       x != "-" && x ? x.trimStart() != "" : false
    //     );
    //   } else {
    //     let floorList = [
    //       "ทั้งหมด",
    //       ...new Set(this.optionChoice.optionFloor.map((item) => item.name)),
    //     ];
    //     // console.log("Floor list: ", floorList);
    //     return floorList.filter((x) =>
    //       x != "-" && x ? x.trimStart() != "" : false
    //     );
    //   }
    // },

    optionCategory() {
      if (this.optionChoice.optionCategory) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionCategory.map((item) => {
              return { label: item.name, value: item.id_category };
            })
          ),
        ];
        return list;
      }
      return [{ label: this.$t("filter.all"), value: null }];
    },
    optionSubCategory() {
      if (this.filterOptions.id_category) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionCategory
            .find(
              (category) =>
                category.id_category === this.filterOptions.id_category
            )
            ?.sub_categories.map((item) => {
              return {
                label: item.name,
                value: item.id_sub_category,
              };
            }),
        ];
        return list;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }

      // console.log("?????", this.getAllAssetsStore[0]);
    },
    optionBuilding() {
      if (this.optionChoice.optionBuilding) {
        const buildingList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionBuilding.map((each) => {
            return {
              label: each.name,
              value: each.id_location_building,
            };
          }),
        ];
        // console.log("Building list: ", buildingList);
        return buildingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionFloor() {
      if (this.filterOptions.id_location_building) {
        const floorList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice?.optionBuilding
            .find(
              (item) =>
                item.id_location_building ===
                this.filterOptions.id_location_building
            )
            ?.location_floors.map((each) => {
              return { label: each.name, value: each.id_location_floor };
            }),
        ];
        return floorList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionDepartment() {
      if (this.optionChoice.optionDepartment) {
        let departmentList = [
          { id: null, label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionDepartment.map((item) => {
              return {
                label: item.name,
                value: item.id_location_department,
              };
            })
          ),
        ];
        return departmentList;
      } else {
        return [{ id: null, label: this.$t("filter.all"), value: null }];
      }
    },
    optionZone() {
      if (this.optionChoice.optionZone) {
        const zoneList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionZone.map((each) => {
              return {
                label: each.name,
                value: each.id_location_zone,
              };
            })
          ),
        ];
        return zoneList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
  },
  components: {
    Footer,
    Loading,
    TotalPageCount,
    Lazyloadimage,
    TmpFilter,
    customFilter,
  },
};
</script>

<style lang="scss" scoped>
.icon-back {
  top: 22px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  margin-bottom: 24px;
  position: absolute;
}
.b-find {
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  margin: auto !important;
  margin-top: 1rem !important;
  width: 50% !important;
  background: $background-gradient-btn-blue !important;
  z-index: 1000;
  -webkit-appearance: none !important;
}
.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}

.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 1rem;
  // margin-left: 1rem;
  display: grid;
  gap: 1rem 1rem;
}

.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
    padding: 0.4rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#basic-addon1 {
  height: 45px;
  border: none;
  background-color: #e0e0e0;
}
select {
  padding-left: 0.5rem !important;
}

.filter {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-radius: 10px;
  // border: 1px solid #007bff;
  background-color: white;
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.filter-box {
  height: 45px;
  border: 0px;
  background-color: #e0e0e0;
}

.input-group {
  // border-right-style: none;
  // width: 80%;
  // height: 38px;
  margin: 0 auto;
  display: flex;
  // padding: 0;
  // margin-top: 60px;
}

.input-group label {
  justify-self: start;
  padding-bottom: 0.3rem;
  margin-bottom: 0 !important;
  color: #7c7c7c;
}
input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

.asset-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  // grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  word-wrap: break-word;
}

.banner {
  width: 70px;
  height: 70px;
  z-index: 10;
  object-fit: cover;
  border: #c4c4c4 2px solid;
  border-radius: 15px;
  margin: 0 auto;
}

.item-asset {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2px;
  // width: 100%;
  margin: 0 auto;
}

@media only screen and (min-width: 375px) {
  .banner {
    height: 90px;
    width: 90px;
  }
}
@media only screen and (min-width: 440px) {
  .banner {
    height: 130px;
    width: 130px;
  }
}
@media only screen and (min-width: 640px) {
  // .item-asset {
  //   width: 70%;
  // }
  .banner {
    height: 140px;
    width: 140px;
  }
}
@media only screen and (min-width: 768px) {
  .banner {
    height: 180px;
    width: 180px;
  }
}

@media only screen and (min-width: 1024px) {
  .asset-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  // .card-form {
  //   width: 700px !important;
  // }
  .banner {
    width: 200px;
    height: 200px;
    z-index: 10;
    object-fit: cover;
  }
}

.icon-back-to-top {
  width: 35px;
  height: 35px;
  background: $color-active-blue;
  padding: 0.5rem;
  border-radius: 2px;

  img {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.btn-to-top {
  width: 30px;
  height: 30px;
  font-size: 22px;
  line-height: 22px;
  background: #007afe 0% 0% no-repeat padding-box;
}
.detail-seemore {
  font-size: 11px;
  padding-top: 5px;
  color: #271a1a;
}

.detail-asset {
  @extend .banner;
  padding-top: 1rem;
  font-size: 16px;
  text-align: left;
  margin: 0 auto;
  border: none;
  height: auto;

  .asset-id {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }

  .asset-detail {
    @extend .asset-id;
    color: $font-grey;
    word-break: break-all;
  }
}

.sub-menu {
  color: $font-grey;
  // padding-bottom: 2rem;
  // display: flex;
  // grid-template-columns: 1fr;
  // grid-gap: 0.5rem;
  // justify-content: center;
}

// select {
//   color: $color-active-blue;
//   background: white;
//   box-shadow: 0px 3px 6px #00000029;
//   border: none;
//   padding: 0.2rem 0.5rem 0.2rem 0.2rem;
//   border-radius: 15px;
//   font-size: 11px;
// }

.sub-menu button {
  margin-right: 1rem;
  cursor: pointer;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.btn-seemore {
  margin-top: 1rem;
  border-radius: 12px;
  font-size: 14px;
  padding: 0.3rem 1rem;
}

.btn-category {
  background: white;
  // color: #007afe;
  border-radius: 15px;
  padding: 0rem 2rem 0rem 2rem;
}

.detail {
  font-size: 11px;
  color: #7c7c7c;
}

.header-text {
  padding-top: 30px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font-size: 28px;
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}

.remind {
  display: flex;
  align-items: center;
  height: 37px;
  text-align: center;
  background-color: #e0e0e0;
}

#reminder {
  font-size: 18px;
  margin: auto;
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.inRemind {
  display: flex;
  align-items: center;
  height: 37px;
  text-align: center;
  background-color: #d2e2f8;
}

#inReminder {
  font-size: 18px;
  margin: auto;
  color: #434343;
}

.card-form {
  width: 80%;
  padding-top: 50px;
  // padding: 2rem 1.5rem 2.5rem 1.5rem;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin-top: 8%;
  margin-bottom: 10%;
  height: 530px;
  overflow: hidden;
}

.card-form-login {
  margin: 5%;
  margin-bottom: 0px;
}

#button-addon2 {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  width: 45px;
  height: 45px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  // border: 1px solid #7c7c7c;
  img {
    width: 40px;
    padding: 0.3rem !important;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1025px) {
  .card-form {
    width: 85%;
    // padding: 2rem 1.5rem 2.5rem 1.5rem;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin-top: 10%;
    margin-bottom: 17%;
    height: 530px;
    overflow: hidden;
  }
  .filter-block-inside {
    width: 90%;
    // margin: auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
    // margin-left: 1rem;
    display: grid;
    gap: 1rem 1rem;
  }
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    // padding-bottom: 2rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .card-form {
    padding-top: 30px;
    height: 530px;
    left: 0%;
    right: 0%;
    top: 5%;
    bottom: 10%;
    overflow: hidden;
  }

  .filter-block-inside {
    width: 90%;
    // margin: auto;
    margin-top: 1rem;
    // margin-left: 1rem;
    display: grid;
    gap: 1rem 1rem;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .card-form {
    width: 85%;
    height: 530px;
    padding-top: 50px;
    // padding: 2rem 1.5rem 2.5rem 1.5rem;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin-top: 10%;
    overflow: hidden;
  }
  .filter-block-inside {
    // width: 90%;
    // margin: auto;
    // margin-top: 1rem;
    // margin-bottom: 2rem;
    // margin-left: 1rem;
    display: grid;
    gap: 1rem 1rem;
  }
}

.btn-login {
  // margin-left: 2rem;
  // padding: 0.55rem 2.75rem 0.55rem 2.75rem;
  // background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
  //   0% no-repeat padding-box;
  width: 160px;
  height: auto;
  color: white;
  padding: 0.5rem 12px 0.5rem 12px;
  border-radius: 10px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: $background-gradient-btn-blue;
}

.btn-regist {
  width: 160px;
  height: auto;
  padding: 0.5rem 12px 0.5rem 12px;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

.filter-count {
  border: 2px solid #007afe;
  border-radius: 5px;
}

.filter-count-box {
  color: #007afe;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}

.selected-item {
  background-color: #c5e1ff;
}

button.cancel {
  margin-right: 10px;
  // margin: 15px 10px 20px 30px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}
</style>
