import axios from "axios";
import { authHeader } from "../../store/actions";
import baseUrl from "@/util/backend";
import Cookies from "js-cookie";

export const actions = {
  async setDashboard(context) {
    const { company } = JSON.parse(Cookies.get("selectedcompany"));
    try {
      const res = await axios.get(`${baseUrl()}maintain/year/summary`, {
        params: {
          id_company: company.id_company,
        },
        ...authHeader(),
      });
      context.commit("setStatusSummary", res.data.message.status_summary);
      context.commit("setDashboardData", res.data.message);
    } catch (error) {
      console.log(error);
    }
  },
  setMaintenanceRole({ commit }, payload) {
    commit("setMaintenanceRole", payload);
  },
  setConfirmSuccess({ commit }, payload) {
    commit("setComfirmModalSuccess", payload);
  }
};
