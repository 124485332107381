<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></Loading>
    <Footer></Footer>
    <!-- <Background></Background> -->
    <div class="scan-qr">
      <div class="">
        <h1 class="header-text">
          <div class="topic">{{ $t("scan.scanqr") }}</div>
        </h1>
      </div>
      <div class="pt-4">
        <div
          class="d-flex justify-content-center align-items-center"
          v-if="!isNative"
        >
          <!-- <img class="banner" src="../assets/camera.svg" alt="" /> -->

          <!-- <p class="detail">
            โปรดเคลื่อนย้ายกล้องของคุณ <br />ให้ตรงกับ QR Code
          </p> -->
          <!-- <div id="div1" /> -->
          <div class="camera-frame camera-frame-corner">
            <qrcode-stream
              @decode="onDecode"
              @init="onInit"
              v-if="!waitConfirm"
            >
              <!-- <img src="../assets/camera_inner.svg" class="camera_inner"> -->
            </qrcode-stream>
          </div>
          <div class="v-else">{{ error }}</div>
        </div>

        <!-- not found asset,  register asset -->

        <!-- found asset -->
        <!-- อยากเปลี่ยนทั้ง div ให้กดได้ -->
        <div id="toReplace">
          <div :is="currentComponent"></div>
        </div>

        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Loading from "vue-loading-overlay";
// import Background from "@/components/Background.vue";
import Swal from "sweetalert2";
import { QrcodeStream } from "vue-qrcode-reader";
import baseUrl from "@/util/backend";
import axios from "axios";
import { authHeader } from "@/store/actions";
// ? ใช้ lib จาก ->  https://github.com/gruhn/vue-qrcode-reader
// import NotFoundAsset from "../components/NotFoundAsset.vue";
// import FoundAsset from "../components/FoundAsset.vue";
// import FoundAssetCounting from "../components/FoundAssetCounting.vue";

import base62 from "base62/lib/ascii";

export default {
  data() {
    return {
      isNative: false,
      isLoading: false,
      error: "",
      page: "",
      waitConfirm: false,
      assetData: null,
      isInMaintain: false,
      // currentComponent: NotFoundAsset,
      // currentComponent: FoundAsset,
      // currentComponent: FoundAssetCounting
    };
  },
  components: { Footer, QrcodeStream, Loading },

  created() {
    if (window.isNativeApp) {
      this.isNative = true;
    }
  },
  async mounted() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.page = this.$route.params.page;
    // console.log(this.page);
    this.$store.commit("getDetailAssetsMutations", "");
  },
  methods: {
    async onDecode(decodedString) {
      // const id_company = await this.$store.dispatch("getCompanyIdCookie");

      const checkPattern = /(^https?:\/\/verscan.com\/check.*)/g;
      const checkPatternType2 = /(^vers.ly.*)/g;
      if (checkPattern.test(decodedString)) {
        // const param = decodedString.match(/([^/]+)$/);
        // const qrCode = param[0];
        const param = decodedString.replace("https://verscan.com/check/", "");
        const qrCode = encodeURIComponent(param);
        // const checkQRPattern = /\d{3}-\d{4}-\d{4}/g;
        // const id_company = await this.$store.dispatch("getCompanyIdCookie");
        // if (this.page === "registerNow") {
        //   this.$router.push(`/check/${qrCode}/${this.page}`);
        //   return;
        // }
        // if (this.page === "changeQR") {
        //   this.$router.replace({
        //     path: `/check/${qrCode}/changeQR`,
        //     query: { qr: this.$route.query.qr, id: this.$route.query.id },
        //   });
        //   return;
        // }
        await this.checkFromQr(qrCode);
        if (this.assetData === null) {
          Swal.fire({
          title: this.$t("maintenance_report.invalidQr-title"),
          text: this.$t("maintenance_report.invalidQr-text"),
          timer: 3000,
          imageUrl: "https://api.verscan.com/download/sweet-alert-alert.svg",
          confirmButtonText: this.$t("maintenance_report.close"),
          imageHeight: 48,
          imageWidth: 48,
        });
        } else if(this.isInMaintain) {
          Swal.fire({
          text: this.$t("maintenance_report.error-not-finish"),
          timer: 3000,
          imageUrl: "https://api.verscan.com/download/sweet-alert-alert.svg",
          confirmButtonText: this.$t("maintenance_report.close"),
          imageHeight: 48,
          imageWidth: 48,
        });
        } else {
          this.$router.push({
            name: this.$route.query.callback,
            params: { assetId: this.assetData.id_asset },
          });
        }
      } else if (checkPatternType2.test(decodedString)) {
        const param = decodedString.match(/([^/]+)$/);
        const baseNumber = param[0];
        const decodedNumber = base62.decode(baseNumber);
        const paddedNumber = String(decodedNumber).padStart(11, "0");
        const result = String(paddedNumber)
          .splice(3, 0, "-")
          .splice(8, 0, "-");
        const qrCode = result;
        // if (this.page === "registerNow") {
        //   this.$router.push(`/check/${qrCode}/${this.page}`);
        //   return;
        // }
        // if (this.page === "changeQR") {
        //   this.$router.push({
        //     path: `/check/${qrCode}/changeQR`,
        //     query: { qr: this.$route.query.qr, id: this.$route.query.id },
        //   });
        //   return;
        // }
        await this.checkFromQr(qrCode);
        if (this.assetData === null) {
          Swal.fire({
          title: this.$t("maintenance_report.invalidQr-title"),
          text: this.$t("maintenance_report.invalidQr-text"),
          timer: 3000,
          imageUrl: "https://api.verscan.com/download/sweet-alert-alert.svg",
          confirmButtonText: this.$t("maintenance_report.close"),
          imageHeight: 48,
          imageWidth: 48,
        });
        } else if(this.isInMaintain) {
          Swal.fire({
          text: this.$t("maintenance_report.error-not-finish"),
          timer: 3000,
          imageUrl: "https://api.verscan.com/download/sweet-alert-alert.svg",
          confirmButtonText: this.$t("maintenance_report.close"),
          imageHeight: 48,
          imageWidth: 48,
        });
        } else {
          this.$router.push({
            name: this.$route.query.callback,
            params: { assetId: this.assetData.id_asset },
          });
        }
      } else {
        Swal.fire({
          title: this.$t('maintenance_report.invalidQr-title'),
          text: this.$t("maintenance_report.invalidQr-text"),
          timer: 3000,
          imageUrl: "https://api.verscan.com/download/sweet-alert-alert.svg",
          confirmButtonText: this.$t("maintenance_report.close"),
          imageHeight: 48,
          imageWidth: 48,
        });
      }
    },
    //check asset detail from qrcode
    async checkFromQr(qrCode) {
      try {
        const res = await axios.post(
          `${baseUrl()}counting`,
          { id_qrcode: qrCode },
          {
            params: {
              id_company: this.id_company,
            },

            ...authHeader(),
          }
        );
        this.assetData = res.data.data.asset;
        // console.log(this.assetData.asset_status)
        if(this.assetData.asset_status == 'maintain'){
          return this.isInMaintain = true;
        } else if(this.assetData.asset_status == 'broke'){
          return this.isInMaintain = true;
        } else {
          return this.isInMaintain = false;
        }
      } catch (err) {
        console.log(err);
      }
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.scan-qr {
  z-index: 2;
}
.header-text {
  padding-top: 58px;
  height: 173px;
  background: transparent linear-gradient(180deg, #ffa800 0%, #ea7509 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // margin-bottom: 15rem;
  }
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}
.card-form {
  padding-top: 2rem;
  width: 80%;
  height: auto;
  padding: 1.5rem;
  padding-bottom: 1rem !important;
}

.banner {
  width: 40px;
  z-index: 1;
}

.detail {
  padding-top: 1rem;
  padding-bottom: 1rem;

  font-size: 11px;
  color: #7c7c7c;
}
.camera-frame {
  height: 300px;
  width: 300px;
  border: 1px solid black;
  // margin: auto;
}
.swal2-icon.swal2-warning {
  border-color: red !important;
  color: red !important;
}
.swal2-styled.swal2-confirm {
  font-size: 14px;
}
.swal2-title {
  font-size: 14px !important;
  color: #000 !important;
}
.swal2-html-container {
  font-size: 12px !important;
  color: #7c7c7c !important;
  margin-top: 5px;
}
.swal2-popup {
  border-radius: 10px !important;
  padding: 0.75rem;
}
.swal2-styled.swal2-confirm {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border-radius: 8px !important;
}

.camera-frame-corner {
  z-index: 3;
  width: 100px;
  height: 100px;
  background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
    linear-gradient(to right, black 4px, transparent 4px) 0 100%,
    linear-gradient(to left, black 4px, transparent 4px) 100% 0,
    linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
    linear-gradient(to top, black 4px, transparent 4px) 0 100%,
    linear-gradient(to top, black 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

@media only screen and (max-width: 420px) {
  .card-form {
    width: 90%;
    height: auto;
    padding: 1rem !important;
    // padding-bottom: 1rem !important;
  }

  .camera-frame {
    height: 280px !important;
    width: 280px !important;
    border: 1px solid black;
    // margin: auto;
    // margin-top: 1rem;
  }
}

@media only screen and (min-width: 320px) {
  .card-form {
    width: 90%;
    height: auto;
    // padding: 1rem;
    // padding-bottom: 1rem !important;
  }

  .camera-frame {
    width: 255px !important;
    height: 285px !important;
    border: 1px solid black;
    // margin: auto;
    // margin-top: 1rem;
  }
}
@media only screen and (min-width: 375px) {
  .card-form {
    // width: 90%;
    // height: auto;
    padding: 1rem !important;
    // padding-bottom: 1rem !important;
  }
  .camera-frame {
    width: 255px !important;
    height: 285px !important;
    border: 1px solid black;
    // margin: auto;
    // margin-top: 1rem;
  }
}
@media only screen and (min-width: 768px) {
  .card-form {
    width: 310px !important;
    padding: 1rem !important;
  }

  .camera-frame {
    width: 255px !important;
    height: 285px !important;
    border: 1px solid black;
    // margin: auto;
    // margin-top: 1rem;
  }
}

div#toReplace {
  margin-top: 20px;
}

// button.sty-button {
//   width: 120px;
//   height: 40px;
//   margin-top: 5px;
//   border-radius: 15px;
//   border-color: transparent;
//   background: transparent linear-gradient(90deg, #0069da 0%, #004ea1 100%)
//     0% 0% no-repeat padding-box;
//   color: #fff;
//   font-size: 20px;
// }

// img.asset-image {
//   width: 80px;
//   height: auto;
// }

// button.see-more {
//   width: 36px;
//   height: auto;
//   padding-left: 20px;
//   border-color: transparent;
//   background: #fff;
//   img {
//     width: 12px;
//     height: auto;
//     opacity: 0.7;
//   }
// }

// .font-14 {
//   font-size: 14px;
// }

// .font-18 {
//   font-size: 18px;
// }
</style>
