<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <AdminMenuBar />
    <div class="admin">
      <AdminSlideMenu />
      <div class="backHome-icon">
        <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ $t("home.dt_manage") }}</div>
      </h1>

      <div class="pt-4 pb-4 gradient-text">
        <h2 class="head-table-text">{{ $t("home.buildpl") }}</h2>
      </div>
      <div class="container" v-show="!editShow">
        <div class="row">
          <!-- upload map -->
          <div class="col-sm-12 col-lg-5 col-md-5 col-xl-5">
            <div class="row">
              <h5 class="gradient-text">{{ $t("home.add_buildpl") }}</h5>
            </div>
            <div
              class="row pt-4"
              style="
                  justify-content: center;
                  padding-left: 1rem;
                  padding-right: 1rem;
                "
            >
              <div class="row upload-zone">
                <div
                  class="frame-profile frame-map"
                  style="padding: 0px !important"
                >
                  <img
                    v-if="newFloorImg"
                    :src="newFloorImg.url"
                    class="preview-map"
                  />
                  <img
                    v-else
                    class="banner"
                    src="../../assets/image.svg"
                    alt=""
                  />
                  <!-- <div class="grey-bg" v-else></div> -->
                </div>

                <input
                  id="addFloorImageInput"
                  type="file"
                  name="photo"
                  ref="fileInput"
                  style="display: none; margin: 0px !important"
                  accept="image/png, image/jpeg"
                  required
                  @change="newFloorImgChange($event)"
                />
              </div>
              <span style="color: red; font-size: 10px; margin-top: 0.5em">{{
                $t("home.up_img")
              }}</span>
              <br />
              <span style="color: red; font-size: 10px; margin-top: 0.5em">{{
                $t("home.suggest_img")
              }}</span>

              <div class="upload-map-zone-in pt-3">
                <label
                  class="label-upload-map"
                  @click="isEditing = false"
                  for="addFloorImageInput"
                >
                  <span>{{ $t("home.up_buildpl") }}</span>
                </label>
              </div>
              <!-- <form> -->
              <div class="input-group">
                <label>{{ $t("home.build") }}</label>
                <div>
                  <span style="width:60%">
                    <v-select
                      :options="optionBuildingSelect"
                      v-model="map.location_building"
                      :clearable="false"
                      required
                      class="w-full"
                    />
                  </span>
                  <!-- <input type="text" v-model="map.location_building" required /> -->
                  <button
                    v-if="map.location_building"
                    type="button"
                    class="btn-outline-danger m-2"
                    data-toggle="modal"
                    data-target="#delete-building"
                    @click="setBuildingToDelete(map.location_building)"
                  >
                    ลบอาคาร
                  </button>
                  <button
                    type="button"
                    class="btn-outline-primary m-2"
                    @click="
                      () => {
                        showCreate = true;
                      }
                    "
                  >
                    + {{ $t("dialog.add_bl") }}
                  </button>
                </div>
              </div>

              <div class="input-group">
                <label>{{ $t("home.fl") }}</label>
                <input type="text" v-model="map.location_floor" required />
              </div>

              <button class="btn btn-upload-map mt-3 mb-3" @click="createMap()">
                {{ $t("btn.save") }}
              </button>
              <!-- </form> -->
            </div>
          </div>
          <!-- display all maps -->
          <div class="col-sm-12 col-lg-7 col-md-7 col-xl-7">
            <div class="row">
              <h5 class="gradient-text">{{ $t("home.all_buildpl") }}</h5>
            </div>
            <div class="row">
              <div class="filter-input-group">
                <div class="d-flex justify-content-center">
                  <select
                    class="dropdown_map form-control"
                    v-model="filterOptions.Building"
                  >
                    <option value="ทั้งหมด">{{ $t("home.build") }}</option>
                    <option
                      v-for="(list, index) in optionBuilding"
                      :key="index"
                      :value="list"
                    >
                      {{ list.name }}
                    </option>
                  </select>
                  <select
                    class="dropdown_map form-control"
                    style="margin-left: 10px"
                    v-model="filterOptions.Floor"
                  >
                    <option value="ทั้งหมด">{{ $t("home.fl") }}</option>
                    <option
                      v-for="(list, index) in optionFloor"
                      :key="index"
                      :value="list"
                    >
                      {{ list }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- loop display all map that sorted -->
            <div class="row map pt-4" style="justify-content: center">
              <div
                style="display: flex; flex-wrap: wrap"
                v-if="getFloor.length != 0"
              >
                <div
                  class="map-box p-2"
                  style="flex: 40%"
                  v-for="(floor, index) in getFloor"
                  :key="index"
                >
                  <img
                    @click="goToManageRoom(floor.id_location_floor)"
                    v-bind:src="
                      floor.image_url ||
                        'https://api.verscan.com/download/no-img.jpg'
                    "
                    alt="No image"
                    class="row map-image"
                    :class="{ 'cursor-pointer': canUseCustom8 }"
                  />
                  <div class="row map-info-edit">
                    <p class="pt-2">
                      {{ $t("home.build") }} {{ floor.location_building.name }}
                      {{ $t("home.fl") }}
                      {{ floor.name }}
                      <img
                        @click="
                          editDetail(
                            floor.location_building.name,
                            floor.name,
                            floor.id_location_floor,
                            floor.image_url,
                            floor.location_building.id_location_building,
                            floor.can_delete
                          )
                        "
                        class="img-button"
                        style="cursor: pointer;"
                        width="20px"
                        src="../../assets/edit_pencil.svg"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div v-else class="result-map-zero">
                {{ $t("dialog.no_buildpl") }}
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-center mt-3"
            v-if="getFloor.length > 0"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalpages"
              :per-page="limit"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>

      <!-- edit map -->
      <b-modal
        class="fade"
        id="editModal"
        v-model="showEdit"
        :hide-header="true"
        :hide-footer="true"
        :centered="true"
      >
        <b-row align-h="end">
          <button
            class="close_modal text-light border-none p-0 mx-2 mb-1"
            @click="$bvModal.hide('editModal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-row>
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content container-fluid pt-2 pb-0"
            style="border-radius: 10px"
          >
            <div class="modal-content-config">
              <div class="row">
                <h3 class="gradient-text pt-2 pb-2">
                  {{ $t("dialog.edit_pl") }}
                </h3>
              </div>
              <div class="frame-profile frame-map" style="">
                <img
                  class="frame-edit"
                  v-if="editFloorImg"
                  :src="editFloorImg.url"
                  style="border-radius: 10px"
                />
                <img
                  class="frame-edit"
                  v-else
                  :src="map_url"
                  style="border-radius: 10px"
                />
              </div>

              <div class="row" style="">
                <input
                  id="editFloorImageInput"
                  type="file"
                  accept="image/png, image/jpeg"
                  style="display: none; margin: 0px !important"
                  @change="editFloorImgChange($event)"
                />

                <div class="upload-map-zone-in pt-2 justify-content-center">
                  <label class="label-upload-map" for="editFloorImageInput">
                    <span>{{ $t("dialog.change_pl") }}</span>
                  </label>
                </div>
              </div>

              <div class="row">
                <form @submit.prevent="updateMap">
                  <div class="input-group">
                    <label>{{ $t("home.build") }}</label>
                    <input
                      type="text"
                      v-model="edit_location_building"
                      required
                    />
                  </div>

                  <div class="input-group">
                    <label>{{ $t("home.fl") }}</label>
                    <input type="text" v-model="edit_location_floor" required />
                  </div>
                  <div style="display:flex" class="justify-content-center">
                    <button
                      type="button"
                      :disabled="!canDeleteFloor"
                      class="btn-upload-map cancle"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      @click="
                        () => {
                          showEdit = false;
                        }
                      "
                    >
                      {{ $t("btn.del_dt") }}
                    </button>
                    <button type="submit" class="btn btn-upload-map">
                      {{ $t("btn.saveedit") }}
                    </button>
                  </div>
                </form>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </b-modal>
      <!-- ยืนยันลบ floor -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content container-fluid pt-4 pb-2"
            style="border-radius: 10px"
          >
            <div class="row">
              <p>
                {{ $t("count.press") }} <b>{{ $t("btn.conf") }}</b>
                {{ $t("dialog.todel_pl") }}<br />
                {{ $t("home.build") }} {{ this.edit_location_building }}
                {{ $t("home.fl") }}
                {{ this.edit_location_floor }}
              </p>
            </div>
            <div class="row">
              <button
                type="button"
                class="btn cancle-del col"
                data-toggle="modal"
                data-target="#editModal"
                data-dismiss="modal"
              >
                {{ $t("btn.canc") }}
              </button>
              <button
                type="button"
                class="confirm col"
                data-dismiss="modal"
                @click="confirmDeleteFloor()"
              >
                {{ $t("btn.conf") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- ยืนยันลบ building -->
      <div
        class="modal fade"
        id="delete-building"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteBuildingModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content container-fluid pt-4 pb-2"
            style="border-radius: 10px"
          >
            <div class="row">
              <p>
                {{ $t("count.press") }} <b>{{ $t("btn.conf") }}</b>
                {{ $t("dialog.todel_building") }}<br />
                {{ $t("home.build") }} {{ this.edit_location_building }}
              </p>
            </div>
            <div class="row">
              <button
                type="button"
                class="btn cancle-del col"
                data-toggle="modal"
                data-target="#editModal"
                data-dismiss="modal"
              >
                {{ $t("btn.canc") }}
              </button>
              <button
                type="button"
                class="confirm col"
                data-dismiss="modal"
                @click="confirmDeleteBuilding()"
              >
                {{ $t("btn.conf") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- ลงทะเบียนอาคาร -->
      <b-modal
        class="fade"
        id="createModal"
        v-model="showCreate"
        :hide-header="true"
        :hide-footer="true"
        :centered="true"
      >
        <b-row align-h="end">
          <button
            class="close_modal text-light border-none p-0 mx-2 mb-1"
            @click="
              () => {
                showCreate = false;
              }
            "
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-row>
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content container-fluid"
            style="border-radius: 10px"
          >
            <div class="modal-content-config">
              <div class="row">
                <h3 class="gradient-text pb-2">
                  {{ $t("dialog.add_bl") }}
                </h3>
              </div>
              <div class="row">
                <form>
                  <div class="input-group">
                    <input
                      type="text"
                      v-model="register_location_building"
                      required
                    />
                  </div>
                </form>
                <div style="display:flex" class="justify-content-center">
                  <button
                    type="submit"
                    class="btn btn-upload-map cancle"
                    data-dismiss="modal"
                    @click="register_location_building = null"
                  >
                    {{ $t("btn.canc") }}
                  </button>
                  <button
                    type="submit"
                    class="btn btn-upload-map"
                    :class="{ cancle: !register_location_building }"
                    :disabled="!register_location_building"
                    @click="createBuilding()"
                  >
                    {{ $t("btn.save") }}
                  </button>
                </div>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Compressor from "compressorjs";
import axios from "axios";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import AdminSlideMenu from "@/components/AdminSlideMenu.vue";
import AdminMenuBar from "@/components/AdminMenuBar.vue";
// import the CSS transitions you wish to use, in this case we are using `Slide`
// import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      isLoading: true,
      register_location_building: null,
      showEdit: false,
      showCreate: false,
      id_map: null,
      edit_location_building: "",
      edit_location_floor: "",
      edit_id_location_building: "",
      canDeleteFloor: false,
      editShow: false,
      url_new: null,
      map_url: null,
      userSendData: "",
      url: "",
      preview: "",
      newFloorImg: null,
      newFloorImgUrl: null,
      editFloorImg: null,
      editFloorImgUrl: null,
      map: {
        id_company: 1,
        location_building: "",
        location_floor: "",
        pictureFile: "",
      },
      filterOptions: {
        Building: "ทั้งหมด",
        Floor: "ทั้งหมด",
      },
      currentPage: 1,
      limit: 6,
    };
  },
  components: {
    AdminSlideMenu,
    AdminMenuBar,
    Loading,
  },
  async mounted() {
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    await this.getFloorDetail();
    await this.$store.dispatch("sendAllMap", id_company);
    // this.optionBuildingSelect = [
    //   ...new Set(this.getAllMap.map((item) => (item = item.name))),
    // ];
    this.isLoading = false;
  },
  watch: {
    "filterOptions.Building"() {
      this.filterOptions.Floor = "ทั้งหมด";
      this.getFloorDetail();
    },
    "filterOptions.Floor"(newVal, oldVal) {
      this.getFloorDetail();
    },
    currentPage(newVal, oldVal) {
      this.getFloorDetail();
    },
  },
  computed: {
    ...mapGetters({
      getAllMap: "getAllMap",
      userDetail: "getUserDetail",
      getFloor: "getFloor",
      getCountFloor: "getCountFloor",
      canUseCustom7: "canUseCustom7",
      canUseCustom8: "canUseCustom8",
    }),
    optionBuilding() {
      if (this.getAllMap) {
        let buildingList = [
          ...new Set(
            this.getAllMap.map(
              (item) =>
                (item = {
                  name: item.name,
                  id_location_building: item.id_location_building,
                })
            )
          ),
        ];
        console.log("B list: ", buildingList);
        return buildingList.filter((x) => x);
      } else {
        return null;
      }
    },
    optionBuildingSelect() {
      if (this.getAllMap) {
        let buildingList = [
          ...new Set(this.getAllMap.map((item) => (item = item.name))),
        ];
        return buildingList;
      } else {
        return [];
      }
    },
    totalpages() {
      return this.getCountFloor;
    },
    optionFloor() {
      if (this.getAllMap) {
        let floorInBuilding = [];
        for (const building of this.getAllMap) {
          if (building.name === this.filterOptions.Building.name) {
            for (const floor of building.location_floors) {
              floorInBuilding.push(floor.name);
            }
          }
        }
        return floorInBuilding;
      } else {
        return null;
      }
    },
    // optionFloor() {
    //   if (this.getAllMap) {
    //     let floorList = [
    //       ...new Set(this.getAllMap.map((item) => item.location_floor)),
    //     ];
    //     console.log(">>>>>>>>.Floor list: ", this.getAllMap);
    //     return floorList;
    //   } else {
    //     return null;
    //   }

    // },
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    },
    goToManageRoom(floorId) {
      if (this.canUseCustom8) {
        this.$router.push({ name: "ManageRoom", params: { floorId: floorId } });
      }
    },
    async confirmDeleteFloor() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      let payload = {
        id_company,
        id_location_floor: this.id_map,
      };
      this.$store.dispatch("deleteFloor", payload).then((res) => {
        if (res) {
          this.getFloorDetail();
          Swal.fire(this.$t("dialog.success"));
          this.$router.go();
        }
      });

      // this.editShow = false;
    },
    async confirmDeleteBuilding() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      let payload = {
        id_company,
        id_location_building: this.edit_id_location_building,
      };
      try {
        await this.$store.dispatch("deleteBuilding", payload);
        this.getFloorDetail();
        Swal.fire(this.$t("dialog.success"));
        this.$router.go();
      } catch (err) {
        if (err === "Remove Asset in Building before delete.") {
          Swal.fire({
            icon: "error",
            title: "ไม่สำเร็จ",
            text:
              "ไม่สามารถลบอาคารนี้ได้ เนื่องจากมีสินทรัพย์ที่ลงทะเบียนในอาคารนี้อยู่ กรุณาแก้ไขสินทรัพย์ที่อยู่ในอาคารนี้ก่อน",
            type: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        } else if (err === "Remove floor in Building before delete.") {
          Swal.fire({
            icon: "error",
            title: "ไม่สำเร็จ",
            text:
              "ไม่สามารถลบอาคารนี้ได้ เนื่องจากมีชั้นที่อยุ่ในอาคารนี้อยู่ กรุณาลบชั้นที่ผูกกับอาคารนี้ก่อน",
            type: "error",
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          alert(err);
        }
      }

      // this.editShow = false;
    },
    async getFloorDetail() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      let payload = {
        location_floor: this.filterOptions.Floor,
        id_location_building:
          this.filterOptions.Building.id_location_building || "ทั้งหมด",
        id_company,
        page: this.currentPage,
        limit: this.limit,
      };
      this.$store.dispatch("getFloor", payload);
    },
    async createBuilding() {
      this.register_location_building = this.register_location_building.trim();
      const check = await this.validateCustomInput(
        this.register_location_building
      );
      if (!check) return;
      if (this.register_location_building) {
        const id_company = await this.$store.dispatch("getCompanyIdCookie");
        let payload = {
          name: this.register_location_building,
          id_company,
        };
        this.$store.dispatch("createBuilding", payload).then((res) => {
          this.register_location_building = null;
          if (res === "Name is already exist") {
            this.$store.dispatch("sendAllMap", id_company);
            Swal.fire(this.$t("dialog.buildpl_existed"));
          } else {
            this.getFloorDetail();
            this.$store.dispatch("sendAllMap", id_company);
            Swal.fire(this.$t("dialog.success"));
            this.showCreate = false;
          }
        });
      } else {
        this.register_location_building = null;
        // Swal.fire(this.$t("dialog.upload_imgbuildfl"));
      }
    },
    editDetail(building, floor, id_map, url, id_location_building, can_delete) {
      this.editFloorImg = null;
      this.id_map = id_map;
      this.map_url = url;
      this.edit_location_building = building;
      this.edit_location_floor = floor;
      this.isEditing = true;
      this.edit_id_location_building = id_location_building;
      this.canDeleteFloor = can_delete;
      // this.editShow = !this.editShow;
      // console.log(this.editShow);
      this.url = "";
      this.url_new = "";
      this.$refs.fileInput.value = null;
      this.showEdit = true;
    },
    setBuildingToDelete(buildingName) {
      this.edit_location_building = buildingName;
      this.edit_id_location_building = this.getAllMap.find(
        (building) => building.name === buildingName
      )?.id_location_building;
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 1,
          // width: 320,
          maxWidth: 800,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },
    async createMap() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      if (
        // this.url_new &&
        this.map.location_building &&
        this.map.location_floor &&
        this.map.location_building.trimEnd() != "" &&
        this.map.location_floor.trimStart() != ""
      ) {
        const url = window.location.hostname;
        let noImgUrl = "";
        if (url === "app.verscan.com" || url === "prod-app-verscan.web.app") {
          noImgUrl =
            "https://imagedelivery.net/M71RFlp5fMNiKNdFVAedNA/bc53acd2-3441-44c3-dc62-04545b451600/public";
        } else {
          noImgUrl = "https://api.verscan.com/download/no-img.jpg";
        }
        let map = this.map;
        let finalUrl = "";
        this.isLoading = true;
        if (this.newFloorImg) {
          if (!this.newFloorImgUrl) {
            const floorImageUrl = await this.upload(this.newFloorImg.file);
            this.newFloorImgUrl = floorImageUrl;
            finalUrl = floorImageUrl;
          } else {
            finalUrl = this.newFloorImgUrl;
          }
        } else {
          finalUrl = noImgUrl;
        }
        const fd = {
          id_company,
          image_url: finalUrl,
          location_building: map.location_building.trim(),
          location_floor: map.location_floor.trim(),
        };

        await this.$store.dispatch("createMap", fd).then((res) => {
          this.isLoading = false;
          if (
            res.data.message !== "Floor and building are already in the system."
          ) {
            // this.url_new = null;
            // this.preview = null;
            // this.url = null;
            this.map.location_building = null;
            this.map.location_floor = null;
            this.newFloorImg = null;
            this.newFloorImgUrl = null;
            this.getFloorDetail();
            Swal.fire(this.$t("dialog.success"));
          } else {
            this.getFloorDetail();
            Swal.fire(this.$t("dialog.buildpl_existed"));
          }
        });
      }
    },
    async onFileChange(e) {
      let file;
      if (e[0]) {
        file = e[0];
      } else {
        if (e.target) {
          file = e.target.files[0];
          e.target.value = null;
        }
      }
      if (!(await this.$store.dispatch("checkFileType", file))) {
        return Swal.fire(this.$t("home.up_img"));
      }
      this.isLoading = true;
      this.url = URL.createObjectURL(file);
      this.isEditing
        ? (this.map_url = URL.createObjectURL(file))
        : (this.preview = URL.createObjectURL(file));
      await this.upload(file);
      // e.target = null;
      this.isLoading = false;
    },
    async newFloorImgChange(e) {
      let file;
      if (e[0]) {
        file = e[0];
      } else {
        if (e.target) {
          file = e.target.files[0];
          e.target.value = null;
        }
      }
      if (!(await this.$store.dispatch("checkFileType", file))) {
        return Swal.fire(this.$t("home.up_img"));
      }
      this.newFloorImgUrl = null;
      this.newFloorImg = { file, url: URL.createObjectURL(file) };
    },
    async editFloorImgChange(e) {
      let file;
      if (e[0]) {
        file = e[0];
      } else {
        if (e.target) {
          file = e.target.files[0];
          e.target.value = null;
        }
      }
      if (!(await this.$store.dispatch("checkFileType", file))) {
        return Swal.fire(this.$t("home.up_img"));
      }
      this.editFloorImgUrl = null;
      this.editFloorImg = { file, url: URL.createObjectURL(file) };
    },
    async upload(pictureFile) {
      const fileforupload = await this.compressImage(pictureFile);
      var file = new File([fileforupload], pictureFile.name, {
        type: "image/jpeg",
      });

      const formdata = new FormData();
      formdata.append("folder_id", 2);
      formdata.append("file", file);
      const res = await this.$store.dispatch("uploadImage", formdata);
      //   console.log("res.data.data;", res.data.data);
      //   this.url_new = res.data.data.uploadFileAtLevel.url;
      //   this.map_url = res.data.data.uploadFileAtLevel.url;
      const uploadedUrl = res.data.data.uploadFileAtLevel.url;
      return uploadedUrl;
    },
    async validateCustomInput(text) {
      if (this.canUseCustom7 && !text.includes(" - ")) {
        const result = await Swal.fire({
          icon: "error",
          text: `${this.$t("home.build")} ${this.$t(
            "dialog.incorrect_confirm"
          )}`,
          showCancelButton: true,
          confirmButtonText: this.$t("btn.conf"),
          cancelButtonText: this.$t("btn.canc"),
          confirmButtonColor: "#007AFE",
          cancelButtonColor: "#C4C4C4",
          reverseButtons: true,
        });
        return result.isConfirmed;
      } else {
        return true;
      }
    },
    async updateMap() {
      this.edit_location_building = this.edit_location_building.trim();
      this.edit_location_floor = this.edit_location_floor.trim();
      const check = await this.validateCustomInput(this.edit_location_building);
      if (!check) return;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.isLoading = true;
      if (this.edit_location_building && this.edit_location_floor) {
        let finalUrl = "";
        if (this.editFloorImg) {
          if (!this.editFloorImgUrl) {
            const floorImageUrl = await this.upload(this.editFloorImg.file);
            this.editFloorImgUrl = floorImageUrl;
            finalUrl = floorImageUrl;
          } else {
            finalUrl = this.editFloorImgUrl;
          }
        } else {
          finalUrl = this.map_url;
        }
        this.$store
          .dispatch("updateMap", {
            new_url: finalUrl,
            id_map: this.id_map,
            location_building: this.edit_location_building.trim(),
            location_floor: this.edit_location_floor.trim(),
            id_company: id_company,
            id_location_building: this.edit_id_location_building,
          })
          .then((res) => {
            this.isLoading = false;
            if (res) {
              Swal.fire(this.$t("dialog.buildpl_existed"));
            } else {
              this.showEdit = false;
              this.getFloorDetail();
              this.editFloorImg = null;
              this.editFloorImgUrl = null;
              Swal.fire(this.$t("dialog.success"));
              this.$router.go();
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bm-menu {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.2) !important;
}

::v-deep .vs__search {
  background: #e0e0e0 !important;
}
::v-deep .vs--searchable {
  background: #e0e0e0 !important;
}
::v-deep .modal-content {
  border: none !important;
}
::v-deep .modal-content-config {
  display: flex;
  flex-direction: column;
  align-items: center;
  .frame-map {
    display: flex;
    justify-content: center;
  }
  .label-upload-map {
    padding: 1rem;
    border: 2px solid black;
    border-radius: 13px;
    border-style: dashed;
    cursor: pointer;
  }
}
.banner {
  width: 4rem;
}
.result-map-zero {
  width: 35vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.btn-outline-primary {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #0d6efd !important;
  border-color: #0d6efd !important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-outline-primary:active {
  color: #fff !important;
  border-color: #fff !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #0d6efd !important;
  border-color: #fff !important;
}

.btn-outline-danger {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  border-color: red !important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-outline-danger:active {
  color: #fff !important;
  border-color: #fff !important;
}

.btn-outline-danger:hover {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #fff !important;
}

.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.uploading {
  padding: 0px !important;
}

.dropdown_map {
  background-color: white;
  width: 100px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: window;
  padding: 2px 10px 2px 10px;
}

.filter-input-group {
  //   // width: 30%;
  // display: flex;
  // flex-direction: column;
  text-align: center;
  // margin-left: 20px;
  label {
    font: normal normal normal 18px Mitr;
    color: $font-grey;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
// .grey-bg {
//   width: 200px;
//   height: 200px;
//   background: #c7c7c7;
// }

.btn-upload-map {
  // margin-top: 10px;
  width: 140px;
  height: 45px;
  margin: 15px 10px 20px;
  padding: 0px;
  border-radius: 10px;
}

.label-upload-map {
  padding: 1rem;
  border: 2px solid black;
  border-radius: 13px;
  border-style: dashed;
  cursor: pointer;
}

.frame-map {
  width: 100%;
  .frame-edit {
    width: 90%;
  }
}

.preview-map {
  // text-align: center;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  object-fit: scale-down;
  margin: 0 auto;
  // padding-bottom: 1rem;
}

.upload-zone {
  padding: 0px !important;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(205, 235, 255); */
  background-color: #c2c2c2;
  border-radius: 15px;
}

.upload-block {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 2fr);
  grid-template-rows: minmax(400px, 1fr);

  gap: 1rem;
}

.input-group {
  padding: 0.2rem;
  border-radius: 10px !important;
}

input,
select,
textarea {
  // width: auto;
  font-size: 14px;
  border-radius: 10px !important;
  background-color: #e0e0e0;
}

p {
  // width: 120px;
  // display: flex;
  // flex-wrap: wrap;
  color: $font-grey;
  // text-align: left;
}

.input-group {
  &-custom {
    display: flex;
  }
}

.map {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.map-box {
  // width: 200px;
  height: auto;
  // padding: 0%;
  margin-top: 10px;
  // margin-bottom: 60px;
}

.map-image {
  width: 250px;
  height: 150px;
  border-radius: 10px;
  padding: 0%;
  object-fit: fill;
  margin: 0 auto;
}

.map-info-edit {
  display: inline-flex;
  height: 20px;
  padding: 0%;
  margin: 0%;
}

.map-info-edit:hover {
  display: inline-flex;
  height: 20px;
  padding: 0%;
  margin: 0%;
  color: rgb(39, 39, 39);
}

.img-button {
  width: 20px;
  margin-left: 1rem;
  padding: 0%;
}

.back-icon {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
}

.backHome-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 1025px) {
  .backHome-icon {
    display: none;
  }
}

.delete-dialog {
  width: 300px;
  height: 180px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 5px 0.1px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 30%;
  left: 50%;
  z-index: 10;
  p {
    margin-top: 30px;
    color: #000;
  }
}
button.cancle-del {
  width: 120px;
  height: 30px;
  margin: 15px 10px 20px 30px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

button.cancle {
  width: 140px;
  height: 45px;
  // margin: 15px 10px 20px 30px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
  &:disabled {
    opacity: 0.3;
    cursor: default !important;
  }
}

button.confirm {
  width: 122px;
  height: 32px;
  margin: 14px 30px 20px 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(-100deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;
}
@media only screen and (min-width: 320px) and (max-width: 430px) {
  .main {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  // .input-group {
  //   width: 80%;
  // }
  h5 {
    margin-top: 20px;
  }
}

.responsive-bar-admin-menu {
  cursor: pointer;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
}

@media only screen and (max-width: 1024px) {
  .responsive-bar-admin {
    display: none;
    box-shadow: $shadow-default;
  }
}

.admin {
  width: 100%;
  // background: transparent linear-gradient(180deg, #013f81 0%, #007afe 100%) 0%
  //   0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .menu-admin {
    display: none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
