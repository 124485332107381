<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :v-else-if="1"
      loader="dots"
    ></Loading>

    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>

    <!-- Filter Modal -->
    <!-- <b-modal
      hide-header-close
      hide-header
      id="filter-modal"
      :cancel-title="$t('btn.clear')"
      @cancel="handleclear"
      :ok-title="$t('btn.conf')"
    >
      <b-row>
        <b-col class="text-end" cols="2"> </b-col>

        <b-col class="text-center" cols="8">
          <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
        </b-col>
        <b-col class="text-end" cols="2">
          <button
            class="close_modal text-light border-none"
            @click="$bvModal.hide('filter-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-col>
      </b-row>
      <label>{{ $t("count.pos") }}</label>
      <select class="form-control" v-model="filterOptions.role">
        <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
        <option
          v-for="(list, index) in position"
          :key="index"
          :value="list.text"
        >
          {{ list.text }}
        </option>
      </select>
      <label>{{ $t("filter.dep") }}</label>
      <select class="form-control" v-model="filterOptions.department">
        <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
        <option
          v-for="(list, index) in department"
          :key="index"
          :value="list.text"
        >
          {{ list.text }}
        </option>
      </select>
    </b-modal> -->

    <!-- navbar -->
    <AdminMenuBar />
    <div class="admin">
      <AdminSlideMenu />

      <div class="back-icon close-min1025">
        <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ $t("home.dt_manage") }}</div>
      </h1>

      <div style="padding: 0.4em">
        <div class="tab-content" id="myTabContent">
          <div class="pt-4 pb-2 gradient-text">
            <h2 class="head-table-text">{{ $t("asset.general_log") }}</h2>
          </div>
        </div>
        <!-- <div class="mb-4">
          <b-row class="w-100 m-0 justify-content-center">
            <b-col class="d-flex" cols="10" md="9">
              <span
                class="filter-box p-2 d-flex mr-2"
                id="basic-addon1"
                style="border-radius: 10px 0 0 10px"
              >
                <img src="../../assets/search-white.svg" width="20px" />
              </span>
              <input
                type="text"
                class="form-control filter-box"
                style="
                  border-right-style: none;
                  border-radius: 0 10px 10px 0 !important;
                "
                :placeholder="$t('home.search')"
                v-model="searchText"
              />
            </b-col>
            <b-col cols="2" md="1" class="d-flex align-items-center">
              <button
                class="filter"
                style="border: none"
                type="button"
                id="button-addon2"
                @click="$bvModal.show('filter-modal')"
              >
                <img src="../../assets/filter-white.svg" width="20px" />
              </button>
            </b-col>
          </b-row>
        </div> -->

        <div class="main">
          <div
            class="date-filter d-flex flex-column flex-sm-row gap-3 justify-content-sm-between mb-3"
          >
            <div>
              <button
                v-if="!isNativeApp"
                class="button-load p-sm-1 px-1"
                style="min-width: max-content"
                @click="$bvModal.show('export-modal')"
              >
                <b-icon-upload
                  class="p-1"
                  font-scale="2"
                  style="color: #007afe"
                ></b-icon-upload
                >{{ $t("btn.ex_file") }}
              </button>
            </div>

            <div class="d-flex h-100 gap-3">
              <input
                class="input-date h-sm-100"
                type="date"
                data-date-inline-picker="true"
                v-model="startDate"
                @change="getLog"
              />
              <p class="arrow-form-to">
                >
              </p>
              <input
                class="input-date h-sm-100"
                type="date"
                data-date-inline-picker="true"
                v-model="endDate"
                @change="getLog"
                :min="startDate"
              />
            </div>
          </div>
          <div class="log-section">
            <!-- <div class="d-flex justify-content-between mb-3">
              <p class="count">
                {{
                  $t("asset.log_amount", {
                    amount: totalRows,
                  })
                }}
              </p>
            </div> -->
            <div
              class="log-container"
              v-for="log in assetLog"
              :key="log.log_asset_id"
            >
              <p
                class="log-detail"
                style="cursor: pointer;"
                @click="gotoLogDetail(log.id_asset)"
              >
                {{ log.createdAt | formatDate }}, {{ $t("asset.as_num") }}:{{
                  log.id_fixed_asset
                }}, {{ $t("asset.edit_by") }} {{ log.user.name }}, QR Code ID:{{
                  log.id_qrcode
                }}, {{ $t("asset.as_name") }}:
                {{ log.name }}
                <span v-if="log.type === 'ลงทะเบียน'">{{
                  $t("registeritem.regist")
                }}</span>
                <span v-if="log.type === 'แก้ไข'">
                  <span v-for="(edit, i) in log.edit" :key="i">
                    {{ translateKeyToText(edit) }}
                    <!-- <span v-if="i < log.edit.length - 1">, </span> -->
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <b-pagination
              v-if="totalRows > 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminSlideMenu from "@/components/AdminSlideMenu.vue";
import AdminMenuBar from "@/components/AdminMenuBar.vue";
import Loading from "vue-loading-overlay";
import moment from "moment";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import { baseUrl } from "../../util/backend";
import { authHeader } from "../../store/actions";

export default {
  data() {
    return {
      isLoading: false,
      searchText: "",
      filterOptions: {
        department: "ทั้งหมด",
        role: "ทั้งหมด",
      },
      assetLog: null,
      otherColumn: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 20,
    };
  },
  components: {
    AdminSlideMenu,
    AdminMenuBar,
    Loading,
  },
  watch: {
    selected() {
      console.log(this.selected == this.position[5].id_permission);
    },
    currentPage() {
      // this.$router.push({
      //   path: this.$route.path,
      //   query: { page: this.currentPage },
      // });
      this.getLog();
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
    }),
    roleFilterArr() {
      let choice = [...new Set(this.role_choice)];
      // console.log("filter role",choice.length,this.role_choice)

      return choice[0] === undefined ? ["ไม่พบข้อมูล"] : choice;
    },
    optionPosition() {
      return this.position
        .map((position) => {
          return position.text;
        })
        .filter((x) => x);
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY - HH:mm:ss");
    },
  },
  methods: {
    handleclear() {
      this.filterOptions = {
        department: "ทั้งหมด",
        role: "ทั้งหมด",
      };
    },
    translateKeyToText(key) {
      // const customKey = this.otherColumn.some((x) => {
      //   console.log(x.name === key);
      //   return x.name === key;
      // });
      // if (customKey) {
      //   return `${this.$t("asset.edit")}${key}`;
      // }
      switch (key) {
        case "id_fixed_asset":
          return `${this.$t("asset.edit")}${this.$t("home.fixedas_num")}`;
        case "name":
          return `${this.$t("asset.edit")}${this.$t("home.as_name")}`;
        case "category":
          return `${this.$t("asset.edit")}${this.$t("home.cat")}`;
        case "sub_category":
          return `${this.$t("asset.edit")}${this.$t("home.sub_cat")}`;
        case "description":
          return `${this.$t("asset.edit")}${this.$t("home.as_dt")}`;
        case "description2":
          return `${this.$t("asset.edit")}${this.$t("home.more_description")}`;
        case "location_building":
          return `${this.$t("asset.edit")}${this.$t("home.build")}`;
        case "location_floor":
          return `${this.$t("asset.edit")}${this.$t("home.fl")}`;
        case "location_room":
          return `${this.$t("asset.edit")}${this.$t("home.room")}`;
        case "first_img_url":
          return `${this.$t("asset.edit")}${this.$t("detailasset.img_reg")}`;
        case "image_url":
          return `${this.$t("asset.edit")}${this.$t("home.image")}`;
        case "location_department":
          return `${this.$t("asset.edit")}${this.$t("home.department")}`;
        case "location_zone":
          return `${this.$t("asset.edit")}${this.$t("home.zone")}`;
        case "selected_location":
          return `${this.$t("asset.edit")}${this.$t("home.as_loc")}`;
        case "note":
          return `${this.$t("asset.edit")}${this.$t("home.note")}`;
        case "purchase_date":
          return `${this.$t("asset.edit")}${this.$t("asset.purchase_date")}`;
        case "purchase_price":
          return `${this.$t("asset.edit")}${this.$t("asset.purchase_price")}`;
        case "useful_life":
          return `${this.$t("asset.edit")}${this.$t("asset.useful_life")}`;
        case "start_date":
          return `${this.$t("asset.edit")}${this.$t("asset.start_date")}`;
        case "acc_depreciation":
          return `${this.$t("asset.edit")}${this.$t("asset.acc_depreciation")}`;
        case "net_book":
          return `${this.$t("asset.edit")}${this.$t("asset.net_book")}`;
        case "qrcode":
          return `${this.$t("asset.edit")}QR Code`;
        case "vendor":
          return `${this.$t("asset.edit")}${this.$t("asset.vendor")}`;
        case "custom_start_time":
          return `${this.$t("asset.edit")}${this.$t("asset.insurance_start")}`;
        case "custom_end_time":
          return `${this.$t("asset.edit")}${this.$t("asset.insurance_end")}`;
        case "sector":
          return `${this.$t("asset.edit")}${this.$t("asset.region_province")}`;
        case "branch":
          return `${this.$t("asset.edit")}${this.$t("asset.branch")}`;
        case "land_status":
          return `${this.$t("asset.edit")}${this.$t(
            "depreciation.land_status"
          )}`;
        case "note_acc":
          return `${this.$t("asset.edit")}${this.$t("asset.acc_note")}`;
        case "code_asset":
          return `${this.$t("asset.edit")}${this.$t("asset.asset_acc_code")}`;
        case "name_asset":
          return `${this.$t("asset.edit")}${this.$t("asset.asset_acc_name")}`;
        case "code_acc":
          return `${this.$t("asset.edit")}${this.$t("asset.acc_code")}`;
        case "name_acc":
          return `${this.$t("asset.edit")}${this.$t("asset.acc_name")}`;
        case "code_acc_dp":
          return `${this.$t("asset.edit")}${this.$t(
            "asset.accumulate_dep_acc_code"
          )}`;
        case "name_acc_dp":
          return `${this.$t("asset.edit")}${this.$t(
            "asset.accumulate_dep_acc_name"
          )}`;
        case "":
          return;
        default:
          // for other_column
          return `${this.$t("asset.edit")}${key}`;
      }
    },

    gotoHome() {
      this.$router.push("/");
    },
    gotoManageAsset() {
      this.$router.push("/admin/ManageAsset");
    },
    gotoManageUser() {
      this.$router.push("/admin/ManageUser");
    },
    gotoMap() {
      this.$router.push("/admin/Map");
    },
    gotoCatAssets() {
      this.$router.push("/admin/CatAssets");
    },
    gotoManageDepartments() {
      this.$router.push("/admin/ManageDepartment");
    },
    gotoManageZone() {
      this.$router.push("/admin/ManageZone");
    },
    gotoLogDetail(idAsset) {
      this.$router.push(`/assetlogdetail/${idAsset}`);
    },
    setFilter() {
      this.userSearchResult = this.userFilter;
    },
    resetFilter() {
      this.filterOptions.department = "ทั้งหมด";
      this.filterOptions.role = "ทั้งหมด";
    },

    async getLog() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}asset/getLogAllAssetPagination/${id_company}`,
          {
            params: {
              page: this.currentPage,
              limit: this.perPage,
              start: this.startDate
                ? moment(this.startDate)
                    .startOf("d")
                    .toISOString()
                : undefined,
              end: this.endDate
                ? moment(this.endDate)
                    .endOf("d")
                    .toISOString()
                : undefined,
            },

            ...authHeader(),
          }
        );
        let logArr = res.data.data;
        this.otherColumn = res.data.other_column;
        this.totalRows = res.data.count;
        for (const log of logArr) {
          const uniqueEdit = [];
          for (const edit of log.edit) {
            if (edit === "location_mapX" || edit === "location_mapY") {
              uniqueEdit.push("selected_location");
            } else {
              uniqueEdit.push(edit);
            }
          }
          log.edit = [...new Set(uniqueEdit)];
        }
        this.assetLog = logArr;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
    },

    async onExport(isMail) {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const filename = `${this.$t("asset.asset_history")} ${moment().format(
          "DD-MM-YYYY_HH-mm"
        )}.xlsx`;
        const res = await axios.get(
          `${baseUrl()}asset/getLogAllAsset/${id_company}/download`,
          {
            ...authHeader(),
            responseType: "blob",
            params: {
              start: this.startDate
                ? moment(this.startDate)
                    .startOf("d")
                    .toISOString()
                : undefined,
              end: this.endDate
                ? moment(this.endDate)
                    .endOf("d")
                    .toISOString()
                : undefined,
            },
          }
        );
        if (!isMail) {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const elink = document.createElement("a");
          elink.download = filename;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
  },

  mounted() {
    // if (this.$route.query.page) {
    //   this.currentPage = this.$route.query.page || 1;
    // }
    this.getLog();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bm-menu {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.2) !important;
}
input {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
select {
  border-radius: 10px !important;
}

.position-box {
  background: #fdfdfd;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-back {
  top: 15px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  // margin-bottom: 24px;
  position: absolute;
}

.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}

.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 1rem;
  display: grid;
  gap: 2rem 1rem;
}

.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
  }
}

.sub-menu {
  color: $font-grey;
}

.input-group {
  // width: 100%;
  display: flex;
  // padding: 0;
}
.custom-control custom-radio {
  height: max-content;
  align-self: center;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.filter-box {
  background-color: #e0e0e0;
}

.log-section {
  width: 85%;
  text-align: left;
  margin: 20px auto 0 auto;
  .log-container {
    border-bottom: 1px solid #c4c4c4;
    padding: 10px 0px;
  }
  .log-detail {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 12px;
  }
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  // border: 1px solid #007AFE;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.table {
  font-size: 14px;
  width: 100%;
}

.table-header {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  // border-radius: 30px;
  // background-color: #007AFE;
  color: white;
  th {
    font-weight: 400;
  }
  th:first-child {
    border-radius: 30px 0 0 0px;
  }
  th:last-child {
    border-radius: 0 30px 0px 0;
  }
}
// .table:last-child {
//   border-radius: 30px 30px 0 0;
// }

.edit-pencil {
  cursor: pointer;
}

.btn-lost {
  font-size: 13px;
  padding: 0.5rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

button.cancle {
  width: 120px;
  height: 35px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

.btn-disabled {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #cecece 0%, #7c7c7c 100%) 0%
    0% no-repeat padding-box;
  cursor: not-allowed !important;
}

button.confirm {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}

.confirm-btn {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #fe0000 0%, #810101 100%) 0%
    0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .card-form {
    height: fit-content;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;

    button {
      margin-top: 40px;
    }
  }
}
.modal {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.del-icon {
  height: 50px;
  margin-bottom: 15px;
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

.admin {
  width: 100%;
  // background: transparent linear-gradient(180deg, #013f81 0%, #007afe 100%) 0%
  //   0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .menu-admin {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .close-min1025 {
    display: none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.button-load {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  font-size: 14px;
  margin: 0.1rem;
  width: fit-content;
  height: 45px;
  background: transparent;
  color: #007afe;
  border: solid;
  border-radius: 11px;
  padding: 5px;
  color: #007afe;
  border: 2px solid #007afe;
}

.arrow-form-to {
  display: flex;
  align-items: center;
}
</style>
