<template>
  <div>
    <Background style="height: 100%"> </Background>
    <h1 class="header-text">{{ $t("dashboard.sum_re") }}</h1>

    <div class="card-div">
      <div class="card-form">
        <div class="text-left pb-2 col text-xl">
          <p>บริษัท ตรวจนับสินทรัพย์ จำกัด</p>
          <p>{{ $t("import.br") }} xxxx {{ $t("import.dep") }} xxxx</p>
          <p>
            {{ $t("import.as_counton") }} 31 ธันวาคม 2563
            {{ $t("import.annual") }} 2563
          </p>
        </div>
        <div class="grid_button">
          <Signature></Signature>

          <div class="el1">
            <div class="col pl-0">
              <button class="form-control backHome" @click="gotoDashboard()">
                {{ $t("import.back_main") }}
              </button>
            </div>
            <div class="d-flex">
              <div class="col-11 pl-1 pr-0">
                <input
                  type="text"
                  class="form-control reletive align-items-center search_box"
                  :placeholder="$t('btn.search')"
                  :aria-label="$t('btn.search')"
                />
                <img src="../../src/image/search.svg" class="search_icon" />
              </div>
            </div>
            {{ setReport }}

            <div class="justify-content-start col-5 dropdownMargin">
              <select class="form-control" style="width: 117px">
                <option selected disabled>{{
                  $t("import.select_year")
                }}</option>
                <option
                  style="color: black"
                  v-for="(item, index) in filter_year"
                  :key="index"
                >
                  {{ item + 543 }}
                </option>
              </select>
            </div>
          </div>
          <div></div>

          <div class="el2">
            <div class="col">
              <button
                class="form-control backHome buttonLicense"
                @click="gotoSign()"
              >
                {{ $t("import.sign_doc") }}
              </button>
            </div>
            <div class="col">
              <button
                class="form-control backHome buttonLicense"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                {{ $t("import.cert_sig") }}
              </button>
            </div>
            <div class="col">
              <button class="btn backHome buttonDowload text-white">
                {{ $t("import.dl_sum") }}
              </button>
            </div>
          </div>
        </div>

        <div class="mt-4 overflow_table">
          <table class="table table-striped mt-5">
            <thead style="border-radius: 10px">
              <tr class="text-center align-item-center justify-content-center">
                <th
                  v-for="(item, index) in title_table"
                  :key="index"
                  class="title_table text-white"
                  :class="{
                    radiusFirst: index == 0,
                    radiusLast: index == title_table.length - 1,
                  }"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in report" :key="index" scope="row">
                <th class="radiusFirst">
                  {{ item.id_asset }}
                </th>
                <td>{{ item.id_fixed_asset }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
                <td>1</td>
                <td>{{ item.counting_items.length }}</td>
                <td>{{ item.counting_items.length }}</td>

                <td
                  v-if="
                    !item.counting_items[
                      parseInt(`${item.counting_items.length - 1}`)
                    ] ||
                      item.counting_items[item.counting_items.length - 1]
                        .note == ''
                  "
                >
                  -
                </td>
                <td v-else>
                  {{ item.counting_items[item.counting_items.length - 1].note }}
                </td>
                <td
                  v-if="
                    item.counting_items[item.counting_items.length - 1] &&
                      item.counting_items[item.counting_items.length - 1]
                        .status == $t('count.usable')
                  "
                >
                  1
                </td>
                <td v-else>-</td>
                <td
                  v-if="
                    item.counting_items[item.counting_items.length - 1] &&
                      item.counting_items[item.counting_items.length - 1]
                        .status == $t('count.not_inuse')
                  "
                >
                  1
                </td>
                <td v-else>-</td>
                <td
                  v-if="
                    item.counting_items[item.counting_items.length - 1] &&
                      item.counting_items[item.counting_items.length - 1]
                        .damaged_status &&
                      item.counting_items[item.counting_items.length - 1]
                        .damaged_status == $t('count.repar')
                  "
                >
                  1
                </td>
                <td v-else>-</td>
                <td
                  v-if="
                    item.counting_items[item.counting_items.length - 1] &&
                      item.counting_items[item.counting_items.length - 1]
                        .damaged_status &&
                      item.counting_items[item.counting_items.length - 1]
                        .damaged_status == $t('count.irrepar')
                  "
                >
                  1
                </td>
                <td v-else>-</td>
                <td
                  v-if="
                    item.counting_items[item.counting_items.length - 1] &&
                      item.counting_items[item.counting_items.length - 1]
                        .damaged_status &&
                      item.counting_items[item.counting_items.length - 1]
                        .damaged_status == $t('count.wait_as')
                  "
                >
                  1
                </td>
                <td v-else>-</td>
                <td
                  v-if="
                    item.counting_items[item.counting_items.length - 1] &&
                      item.counting_items[item.counting_items.length - 1]
                        .damaged_status &&
                      item.counting_items[item.counting_items.length - 1]
                        .damaged_status == $t('count.lost')
                  "
                >
                  1
                </td>
                <td v-else>-</td>
              </tr>

              <!--  <td>{{ item.id_asset }}</td>

                {{
                  item.name_asset
                }}
                <td>
                  {{ item.detail_asset }}
                </td>
                <td>
                  {{ item.amount }}
                </td>
                <td>
                  {{ item.amount_real }}
                </td>
                <td>
                  {{ item.difference }}
                </td>
                <td>
                  {{ item.ps }}
                </td>
                <td>
                  {{ item.used }}
                </td>
                <td>
                  {{ item.noUsed }}
                </td>
                <td>
                  {{ item.canFix }}
                </td>
                <td>
                  {{ item.cannotFix }}
                </td>
                <td class="radiusLast">
                  {{ item.rating }}
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <div class="mt-3 d-flex justify-content-center">
          <span class="pagination">1</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Background from "@/components/Background.vue";
import Signature from "@/components/Signature.vue";

import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      title_table: [
        this.$t("asset.order"),
        this.$t("asset.fixedas_num"),
        this.$t("asset.as_name"),
        this.$t("count.more_dt"),
        this.$t("count.amount_as"),
        this.$t("count.amount_count"),
        this.$t("count.amount_diff"),
        this.$t("count.note"),
        this.$t("count.inuse_pre"),
        this.$t("count.not_inuse"),
        this.$t("count.repar"),
        this.$t("count.irrepar"),
        this.$t("count.wait_repar"),
        this.$t("count.lost"),
      ],
      data_mock: [
        {
          id: 1,
          id_asset: "FA-77780124",
          name_asset: "เครื่องจักร A10",
          detail_asset: "อาคาร A ชั้น 1 แผนกซ่อมบำรุง Zone C",
          amount: 1,
          amount_real: 1,
          difference: 0,
          ps: "Tag ชำรุด, ต้องติด Tag ใหม่",
          used: 1,
          noUsed: "-",
          canFix: "-",
          cannotFix: "-",
          rating: "-",
          lost: "-",
        },
        {
          id: 2,
          id_asset: "FA-71556120",
          name_asset: "เครื่องจักร A10",
          detail_asset: "อาคาร A ชั้น 1 แผนกซ่อมบำรุง Zone C",
          amount: 1,
          amount_real: 1,
          difference: 0,
          ps: "Tag ชำรุด, ต้องติด Tag ใหม่",
          used: 1,
          noUsed: "-",
          canFix: "-",
          cannotFix: "-",
          rating: "-",
        },
        {
          id: 3,
          id_asset: "FA-71556121",
          name_asset: "เครื่องจักร A11",
          detail_asset: "อาคาร A ชั้น 1 แผนกซ่อมบำรุง Zone C",
          amount: 1,
          amount_real: 1,
          difference: 0,
          ps: "Tag ชำรุด, ต้องติด Tag ใหม่",
          used: 1,
          noUsed: "-",
          canFix: "-",
          cannotFix: "-",
          rating: "-",
        },
      ],
      filter_year: [],
    };
  },
  components: { Background, Signature },
  methods: {
    gotoSign() {
      this.$router.push("/sign");
    },
    gotoDashboard() {
      this.$router.push("/dashboard");
    },
  },
  created() {
    this.$store.dispatch("getReportCount", 1);
    console.log("getYear", new Date().getFullYear());

    // this.$root.$moment.locale("th");
  },
  computed: {
    ...mapGetters({ report: "getReport" }),
    setReport() {
      let report = this.report;
      this.lodash.each(this.report, (a) => {
        console.log("aaa", a);
        this.lodash.each(a.counting_items, (b) => {
          this.filter_year.push(
            parseInt(moment(b.counting_date).format("YYYY"))
          );
          console.log("bbb", moment(b.counting_date).format("YYYY"));
        });
        this.filter_year = this.lodash.uniqBy(this.filter_year);
        // a.counting_date = this.$moment(a.counting_date).format("MM");
      });
      console.log(report);
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  color: #ffffff !important;
  letter-spacing: 0px;
  font-size: 50px;
}
.body_table {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #00000033;
  border: 1px solid #d3d3d3;
  border-radius: 50px;
}
.backHome {
  font-size: 16px;
  color: #f4f4f4;
  background: #7c7c7c;
  border-radius: 12px;
  height: fit-content;
}
.input-group-text {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d0d0d0;
}
.reletive {
  position: relative;
}
.search_icon {
  display: inline-block;
  position: absolute;
  z-index: 3;
  line-height: 42px;
  text-indent: 106px;
  right: 15px;
  top: 20%;
}
.form-control {
  border-radius: 11px;
}
.title_table {
  font-size: 14px;

  // background: #007AFE;
}
.header-text {
  font-size: 30px;
  font-weight: 400;
  padding-top: 3rem;
  padding-bottom: 1rem;
  color: white;
}
.card-form {
  margin: auto;
  width: 95%;
  height: auto;
  background-color: white;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
  padding: 2rem 1.5rem 2.5rem 1.5rem;
}

.card-div {
  margin: auto;
  width: 100vw;
  height: 50vh;
  /* background: linear-gradient(90deg, rgb(0, 123, 255), rgb(0, 62, 128)); */
}
.grid_button {
  display: grid;
  grid-template-columns: 1fr 300px 1fr;
  grid-template-rows: 29px;
}
.el1 {
  justify-content: start;
  display: grid;
  margin-top: 2%;
  grid-template-columns: 1fr 1fr 1fr;
}
.el2 {
  display: grid;

  margin-top: 2%;
  grid-template-columns: 1fr 1.2fr 1.2fr;
}

.buttonDowload {
  background: $button-primary-color;
  width: 100% !important;
}
.buttonLicense {
  background: $font-grey;
  width: 100% !important;
}
.whiteBackground {
  background: white;
}
.high_29 {
  height: 29px !important;
}
.radiusFirst {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.radiusLast {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.table thead th {
  vertical-align: middle;
  border-bottom: 2px solid white;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 2px solid white;
}

@media only screen and (min-width: 1024px) {
  .grid_button {
    display: grid;
    grid-template-columns: 1fr 100px 1fr;
    grid-template-rows: 29px;
  }
  .backHome {
    font-size: 14px !important;
    color: #f4f4f4;
    background: #7c7c7c;
    border-radius: 12px;
    height: fit-content;
  }
  .el1 {
    justify-content: start;
    display: grid;
    margin-top: 2%;
    grid-template-columns: 1fr 0.8fr 1fr;
  }
  .el2 {
    display: grid;

    margin-top: 2%;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .search_box {
    width: 155px;
  }
  .search_icon {
    display: inline-block;
    position: absolute;
    z-index: 3;
    line-height: 42px;
    text-indent: 106px;
    right: -5px;
    top: 16%;
  }
}
@media only screen and (min-width: 1440px) {
  .backHome {
    font-size: 14px !important;
    color: #f4f4f4;
    background: #7c7c7c;
    border-radius: 12px;
    height: fit-content;
  }
  .el1 {
    justify-content: start;
    display: grid;
    margin-top: 2%;
    grid-template-columns: 1fr 0.8fr 1fr;
  }
  .search_box {
    width: 220px;
  }
  .search_icon {
    display: inline-block;
    position: absolute;
    z-index: 3;
    line-height: 42px;
    text-indent: 106px;
    right: -10px;
    top: 16%;
  }

  .el2 {
    display: grid;

    margin-top: 2%;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (min-width: 1920px) {
  .search_box {
    width: 260px;
  }
}
@media only screen and (min-width: 2560px) {
  .search_box {
    width: 300px;
  }
  .search_icon {
    display: inline-block;
    position: absolute;
    z-index: 3;
    line-height: 42px;
    text-indent: 106px;
    right: 1px;
    top: 20%;
  }
}
</style>
