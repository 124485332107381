<template>
  <div class="footer">
    <template v-if="canUseCustom8">
      <div class="side-bar">
        <div class="sub-menu-custom" :class="changeColorFooter" @click="gotoHome">
          <img src="../assets/Logo2.png" />
        </div>
        <div class="menu">
          <div class="sub-menu pt-lg-2 pt-xl-3" @click="gotoHome">
            <img
              src="../assets/icon-footer/home-v2.svg"
              v-if="!imgAcctive.dashboard"
            />
            <img src="../assets/footer-active/home-active-v2.svg" v-else />
            <span>{{ $t("home.mainmenu") }}</span>
          </div>

          <div class="sub-menu pt-lg-2" @click="gotoAllasset">
            <img
              src="../assets/icon-footer/asset-v2.svg"
              v-if="!imgAcctive.allAssets"
            />
            <img src="../assets/footer-active/asset-active-v2.svg" v-else />
            <span>{{ $t("asset.fixedas") }}</span>
          </div>

          <div class="sub-menu pt-lg-2" @click="$router.push({ name: 'org' })">
            <img
              src="../assets/icon-footer/orgTree.svg"
              v-if="!imgAcctive.assetGraph"
            />
            <img src="../assets/footer-active/orgTree.svg" v-else />
            <span>{{ $t("home.asset_graph") }}</span>
          </div>

          <div class="sub-menu pt-lg-2" @click="gotoProfile">
            <img
              src="../assets/icon-footer/profile-v2.svg"
              v-if="!imgAcctive.profile"
            />
            <img src="../assets/footer-active/profile-active-v2.svg" v-else />
            <span>{{ $t("profile.prof") }}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="side-bar">
        <div class="sub-menu-custom" :class="changeColorFooter" @click="gotoHome">
          <img src="../assets/Logo2.png" />
        </div>
        <div class="menu">
          <div class="sub-menu pt-lg-2 pt-xl-3" @click="gotoHome">
            <img
              src="../assets/icon-footer/home-v2.svg"
              v-if="!imgAcctive.dashboard"
            />
            <img src="../assets/footer-active/home-active-v2.svg" v-else />
            <span>{{ $t("home.mainmenu") }}</span>
          </div>

          <div class="sub-menu pt-lg-2" @click="gotoAllasset">
            <img
              src="../assets/icon-footer/asset-v2.svg"
              v-if="!imgAcctive.allAssets"
            />
            <img src="../assets/footer-active/asset-active-v2.svg" v-else />
            <span>{{ $t("asset.fixedas") }}</span>
          </div>

          <div class="sub-menu pt-lg-3" @click="gotoScan" v-if="isScan">
            <button>
              <img src="../assets/icon-footer/qr-code-v2.svg" />
            </button>
            <span>{{ $t("scan.scan") }}</span>
          </div>

          <div
            class="sub-menu pt-lg-2"
            @click="gotoHistory()"
            v-if="isCheckResult"
          >
            <img
              src="../assets/icon-footer/history-v2.svg"
              v-if="!imgAcctive.history"
            />
            <img src="../assets/footer-active/history-active-v2.svg" v-else />
            <span>{{ $t("count.count") }}</span>
          </div>

          <div class="sub-menu pt-lg-2" @click="gotoProfile">
            <img
              src="../assets/icon-footer/profile-v2.svg"
              v-if="!imgAcctive.profile"
            />
            <img src="../assets/footer-active/profile-active-v2.svg" v-else />
            <span>{{ $t("profile.prof") }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { featureFlags } from "../native-app-feature-flag";
export default {
  data() {
    return {
      imgAcctive: {
        dashboard: false,
        import: false,
        scan: false,
        allAssets: false,
        history: false,
        profile: false,
        assetGraph: false,
      },
    };
  },
  mounted() {
    let param = this.$route.name;
    if (param == "Home" || param == "Import" || param == "DigitalSign") {
      this.imgAcctive.dashboard = true;
    } else if (param == "ScanQr") {
      this.imgAcctive.scan = true;
    } else if (
      param == "Fixed_Assets" ||
      param == "DetailAsset" ||
      param == "RegisterItem" ||
      param == "CheckItem" ||
      param == "DetailOneAsset" ||
      param == "ImageAsset"
    ) {
      this.imgAcctive.allAssets = true;
    } else if (
      param == "History" ||
      param == "Dashboard" ||
      param == "AssetFromDashboard" ||
      param == "DetailAssetFromDashboard"
    ) {
      this.imgAcctive.history = true;
    } else if (
      param == "Profile" ||
      param == "EditProfile" ||
      param == "ChangePassword"
    ) {
      this.imgAcctive.profile = true;
    } else if (param == "org" || param == "diagram-assets") {
      this.imgAcctive.assetGraph = true;
    }

    // console.log(`get`);
    console.log(`param`, this.$route.name);
  },
  methods: {
    gotoProfile() {
      this.$router.push("/profile");
    },
    gotoDashboard() {
      this.$router.push("/dashboard");
    },
    gotoHome() {
      this.$router.push("/");
    },
    gotoScan() {
      if (featureFlags.scan) {
        window.sendMessage("scan", {
          mode: "normal",
        });
      } else {
        this.$router.push("/scan");
      }
    },
    gotoImport() {
      this.$router.push("/import");
    },
    gotoAllasset() {
      this.$store.dispatch("setPageState", {
        pageName: "",
        pageNum: 1,
      });
      this.$router.push("/fixed_assets");
    },
    gotoHistory() {
      this.$router.push("/history");
    },
  },
  computed: {
    ...mapGetters(["isScan", "isCheckResult", "canUseCustom8"]),
    changeColorFooter(){
      switch (this.$route.name) {
        case "ScanMaintenanceAsset":
          return "sub-menu-maintenance"
        default:
          return "sub-menu-custom";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.sub-menu-custom {
  display: none;
}

p {
  padding: 0 !important;
}

span {
  grid-row-start: 2;
}

.menu {
  display: flex;
}

.sub-menu {
  // align-self: center;

  display: grid;
  grid-template-rows: 30px 30px;
  // height: ;
  justify-items: center;
  align-items: center;
  width: 100%;
  background: rgb(255, 255, 255);
  font-size: 10px;
  line-height: 1;
}

.sub-menu img {
  width: 30px;
  height: 28px;
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 100;
  margin: auto;
  width: 100%;
  padding-top: 0.5rem;
  background: white;
  box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 1025px) {
  .footer {
    top: 0;
    position: sticky;
    width: 100%;
    height: 100vh;
    margin: 0 !important;
    padding-top: 0;
    box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  .side-bar {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin: 0px;
  }
  .menu {
    display: flex;
    justify-content: space-around;
    height: 100%;
    align-items: center;
    flex-direction: column;
  }
  .sub-menu-custom {
    display: block;
    width: 100%;
    height: 173px;
    cursor: pointer;
  }
  .sub-menu {
    cursor: pointer;
    // padding-bottom: calc(1.5rem);
    font-size: 16px;
    height: calc(20vh - (173px / 5));
    &-custom {
      display: static;
      grid-template-columns: auto;
      grid-template-rows: auto;
      padding: 1.5rem;
      font-size: 16px;
      background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%)
        0% 0% no-repeat padding-box;
      & img {
        width: 124px;
        height: 125px;
      }
    }
  }
  .sub-menu-maintenance {
    background: linear-gradient(180deg, #ffa800 0%, #ea7509 100%) 0% 0%
      no-repeat padding-box;
  }
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  margin-bottom: 15px;
  border-radius: 100%;
  border-color: transparent;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) 0% 0% no-repeat
    padding-box;
  img {
    margin-left: 1px;
  }
}
</style>
