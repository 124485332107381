export const state = {
  customName: null,
  qr_all_gen: "",
  asset: "",
  all_asset: [],
  all_asset_store: [],
  deleted_asset: {
    tableData: [],
    otherColumn: [],
    assets_count: 0,
  },
  asset_other_column: [],
  asset_filter: null, // save all_asset filter state
  manage_asset_filter: null,
  result_regis_asset: "",
  check_asset: "",
  create_check_asset: "",
  report_asset: "",
  detailAsset: "",
  assetSelectedRegister: null,
  pictureAsset: "",
  editlAsset: "",
  user: {},
  editUser: null,
  selected_user: "",
  user_register: "",
  login: "",
  login_password_invalid: false,
  asset_amount: "",
  asset_used: "",
  asset_damaged: "",
  all_sumary: "",
  map_url:
    "https://storage.googleapis.com/verscan_demo_all_picture/map-20201010-133020.png",
  map_url_register_now:
    "https://storage.googleapis.com/verscan_demo_all_picture/map-20201010-133020.png",
  map_position: "",
  map_position_register_now: "",
  user_edit: "",
  map: "",
  all_map: "",
  floor: [],
  count_floor: 0,
  regis_asset_result: "",
  regis_asset_check: "",
  regis_asset: "",
  register_success: false,
  all_user: "",
  counting_date: [],
  summary_data: {
    result: {
      data: {
        count: 0,
        rows: [],
      },
    },
    summary: {
      counting_date: null,
      createdAt: null,
      end_counting_date: null,
      id_company: null,
      id_summary: null,
      is_delete: false,
      is_finish: false,
      is_report_done: false,
      signatures: [],
    },
  },
  company_detail: "",
  admin_page: 1,
  signature: "",
  dashboard_filter: "",
  dashboard_modal_filter: null,
  isAlert: false,
  permissionStatus: {},
  asset_dashboard: {},
  isFromProfile: false,
  regisManual: false,
  detailAssetRegisterNow: null,
  all_maintenance_store: [],
  maintenance_filter: null,

  category: [],
  delcategory: null,
  updatecategory: null,
  subcategory: [],

  alldepartment: [],
  department: null,
  allzone: [],
  zone: null,

  allColumn: [],
  all_asset_count: null,
  filterOption: {},

  maintenance_plan_data: null,
  selected_maintenance_asset: [],

  sector: [],
  sectorSelect: {},
  branch: [],
  vendor: [],

  pageState: {
    pageName: "",
    pageNum: 1,
  },

  dpRequest: {
    activate: false,
    deactivate: false,
    write_off: false,
    cancel_activate: false,
    cancel_deactivate: false,
  },

  noteAccounting: [],
  noteAccountingSelect: {
    sub_note_accountings: [],
  },

  optionAllIdFixAssets: [],

  packageLimit: {
    available_asset: 0,
    available_qr: 0,
    imported_asset: 0,
    registered_qr: 0,
  },
};
