<template>
  <ag-grid-vue
    style="width: 100%; height: 450px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    suppressDragLeaveHidesColumns
    @grid-ready="onGridReady"
    checkboxSelection="true"
    suppressMovableColumns
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import btnStatus from "../Table/components/btnStatus.vue";
import moment from "moment";
import { mapGetters } from "vuex";

import { toShowAccountingPrice } from "../../util/accountingPrice";

export default {
  data() {
    return {
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      columnDefs: [],
      rowSelection: "multiple",
    };
  },
  props: {
    dataTable: [],
    tag: { type: Boolean, default: false },
    otherColumn: [],
  },
  beforeMount() {
    let columnDefs = [
      {
        headerName: "",
        field: this.tag ? "status" : "",
        cellRenderer: this.tag ? "btnStatus" : "",
        maxWidth: this.tag ? 130 : 0,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("count.order"),
        field: "index",
        maxWidth: 110,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
      {
        headerName: this.$t("home.fixedas_num"),
        field: "id_fixed_asset",
        sortable: true,
        suppressSizeToFit: true,
      },
      { headerName: this.$t("home.as_name"), field: "name", sortable: true },
      {
        headerName: this.$t("home.as_dt"),
        field: "description",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.more_description"),
        field: "description2",
        sortable: true,
        minWidth: 200,
        hide: !this.canUseCustom8,
      },
      {
        headerName: this.$t("home.cat"),
        field: "category",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.sub_cat"),
        field: "sub_category",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("model.model"),
        field: "sub_category2",
        sortable: true,
        suppressSizeToFit: true,
        hide: !this.canUseCustom4,
      },
      {
        headerName: this.$t("asset.location"),
        field: "asset_sector",
        sortable: true,
        suppressSizeToFit: true,
        hide: !this.canUseCustom2 || this.isRoleLower,
      },
      {
        headerName: this.$t("asset.branch"),
        field: "asset_branch",
        sortable: true,
        suppressSizeToFit: true,
        hide: !this.canUseCustom2 || this.isRoleLower,
      },
      {
        headerName: this.$t("home.build"),
        field: "location_building",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.fl"),
        field: "location_floor",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.room"),
        field: "location_room",
        sortable: true,
        suppressSizeToFit: true,
        hide: !this.canUseCustom8
      },
      {
        headerName: this.$t("home.department_code"),
        field: "location_department_code",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.department"),
        field: "location_department",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.zone"),
        field: "location_zone",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.note"),
        field: "note",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("QR Code"),
        field: "qrcode",
        sortable: true,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
    ];
    if (!this.isRoleLower) {
      let fix_column = [
        {
          headerName: this.$t("asset.acc_note"),
          field: "note_acc",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.asset_acc_code"),
          field: "code_asset",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.asset_acc_name"),
          field: "name_asset",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.acc_code"),
          field: "code_acc",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.dep_acc_name"),
          field: "name_acc",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.accumulate_dep_acc_code"),
          field: "code_acc_dp",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.accumulate_dep_acc_name"),
          field: "name_acc_dp",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.purchase_date"),
          field: "purchase_date",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.start_date"),
          field: "start_date",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.exp_date"),
          field: "expire_date",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.purchase_price"),
          field: "purchase_price",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("asset.useful_life"),
          field: "useful_life",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("asset.scrap_value"),
          field: "scrap_value",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("asset.dep_at_date"),
          field: "acc_depreciation",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.nb_at_date"),
          field: "net_book",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.dep_date"),
          field: "depreciation_date",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.prior_dep"),
          field: "prior_depreciation",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("asset.prior_nb"),
          field: "prior_net_book",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("asset.pause_date"),
          field: "deactivate_dates",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.active_date"),
          field: "activate_dates",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.writeoff_date"),
          field: "write_off_date",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.writeoff_price"),
          field: "write_off_amount",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("asset.net_writeoff_price"),
          field: "residual_value",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("asset.profit_loss"),
          field: "profit_loss",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("home.intendant"),
          minWidth: 170,
          field: "intendant",
          sortable: true,
          hide: !this.canUseCustom3,
        },
        {
          headerName: this.$t("asset.insurance_start"),
          field: "insurance_start",
          sortable: true,
          suppressSizeToFit: true,
          hide: !this.canUseCustom2,
        },
        {
          headerName: this.$t("asset.insurance_end"),
          field: "insurance_end",
          sortable: true,
          suppressSizeToFit: true,
          hide: !this.canUseCustom2,
        },
        {
          headerName: this.$t("asset.vendor"),
          field: "vendor",
          sortable: true,
          suppressSizeToFit: true,
          hide: !this.canUseCustom2,
        },
      ];
      columnDefs = columnDefs.concat(fix_column);
    }
    this.columnDefs = columnDefs;
  },
  mounted() {
    this.loopHeaderFreeColumn();
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
      canUseCustom3: "canUseCustom3",
      canUseCustom4: "canUseCustom4",
      canUseCustom8: "canUseCustom8",
    }),
    rowData() {
      return this.dataTable.map((asset, index) => {
        let dataTable = {
          tag: this.tag ? asset : null,
          index: index + 1,
          id_fixed_asset: asset.id_fixed_asset,
          name: asset.name ? asset.name : "-",
          description: asset.description ? asset.description : "-",
          description2: asset.description2 ? asset.description2 : "-",
          category: asset.category ? asset.category : "-",
          sub_category: asset.sub_category ? asset.sub_category : "-",
          sub_category2: asset.sub_category2 ? asset.sub_category2 : "-",
          location_building: asset.location_building
            ? asset.location_building
            : "-",
          location_floor: asset.location_floor ? asset.location_floor : "-",
          location_room: asset.location_room ? asset.location_room : "-",
          location_department: asset.location_department
            ? asset.location_department
            : "-",
          location_department_code:
            asset?.location_departments?.code ||
            asset?.location_department_code ||
            "-",
          note_acc: asset?.note_acc || "-",
          code_asset: asset?.code_asset || "-",
          name_asset: asset?.name_asset || "-",
          code_acc: asset?.code_acc || "-",
          name_acc: asset?.name_acc || "-",
          code_acc_dp: asset?.code_acc_dp || "-",
          name_acc_dp: asset?.name_acc_dp || "-",
          location_zone: asset.location_zone ? asset.location_zone : "-",
          note: asset.note ? asset.note : "-",
          qrcode: "-",
          purchase_date: asset.purchase_date
            ? this.momentDay(asset.purchase_date)
            : "-",
          purchase_price: asset.purchase_price
            ? toShowAccountingPrice(asset.purchase_price)
            : "-",
          useful_life: asset.useful_life ? asset.useful_life : "-",
          start_date: asset.start_date ? this.momentDay(asset.start_date) : "-",
          expire_date: "-",
          scrap_value: asset.scrap_value
            ? toShowAccountingPrice(asset.scrap_value)
            : "-",
          acc_depreciation: "-",
          net_book: "-",
          depreciation_date: asset.depreciation_date
            ? this.momentDay(asset.depreciation_date)
            : "-",
          prior_depreciation: asset.prior_depreciation
            ? toShowAccountingPrice(asset.prior_depreciation)
            : "-",
          prior_net_book: asset.prior_net_book
            ? toShowAccountingPrice(asset.prior_net_book)
            : "-",
          deactivate_dates: "-",
          activate_dates: "-",
          write_off_date: "-",
          profit_loss: "-",
          write_off_amount: "-",
          residual_value: "-",
          status: asset.status || null,
          asset_sector: asset.sector || "-",
          asset_branch: asset.branch || "-",
          insurance_start: asset.custom_start_time
            ? this.momentDay(asset.custom_start_time)
            : "-",
          insurance_end: asset.custom_end_time
            ? this.momentDay(asset.custom_end_time)
            : "-",
          vendor: asset.vendor || "-",
          // ------------------- START Custom 3 SubAsset ------------------------
          intendant: asset.intendant,
          // ------------------- END Custom 3 SubAsset ------------------------
        };
        const assetDetail = asset.other;
        assetDetail.forEach((val) => {
          dataTable[`other_${val.column_no}`] = val.value;
        });
        // if (asset.other.length > 0) {
        //   asset.other.forEach((val, i) => {
        //     dataTable[`other_${i}`] = val.value;
        //   });
        // } else {
        //   asset.other.forEach((val, i) => {
        //     dataTable[`other_${i}`] = "-";
        //   });
        // }

        return dataTable;
      });
    },
  },
  methods: {
    loopHeaderFreeColumn() {
      let columnDefs = this.columnDefs;
      for (const col of this.otherColumn) {
        let header = {
          headerName: col.name,
          field: `other_${col.column_no}`,
          maxWidth: 130,
          cellRenderer: (params) => {
            return params.value || "-";
          },
          suppressSizeToFit: true,
        };
        columnDefs.push(header);
      }
      // if (this.dataTable.length > 0) {
      //   let column = this.otherColumn;
      //   column.forEach((val, i) => {
      //     let header = {
      //       headerName: column[i].name,
      //       field: `other_${i}`,
      //       maxWidth: 130,
      //       suppressSizeToFit: true,
      //     };
      //     columnDefs.push(header);
      //   });
      //   this.columnDefs = columnDefs;
      // }
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    momentDay(date) {
      return moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : "-";
    },
  },
  watch: {},
  components: {
    AgGridVue,
    btnStatus,
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
