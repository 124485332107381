<template>
  <div>
    <v-app>
      <v-dialog
        v-model="canvasVehiculo"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="canvasVehiculo = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Seleccionar partes del vehiculo:</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark flat @click="undo">Deshacer</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list three-line subheader>
            <v-subheader></v-subheader>
          </v-list>
        </v-card>
      </v-dialog>
      <v-btn @click="open">Click</v-btn>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    canvasVehiculo: false
  }),
  methods: {
    open() {
      this.canvasVehiculo = true;
      this.$nextTick(() => {
        this.$refs.signaturePad.resizeCanvas();
      });
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    }
  }
};
</script>
