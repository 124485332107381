<template>
  <ag-grid-vue
    style="width: 100%; height: 400px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    suppressDragLeaveHidesColumns
    @selection-changed="onSelectionChanged"
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { mapGetters } from "vuex";

import { toShowAccountingPrice } from "../../../util/accountingPrice";

//  Table Components
import CancelButton from "./CellFramework/CancelButton.vue";

export default {
  emits: ["selected-assets", "cancel-action"],
  data() {
    return {
      columnDefs: null,
      // rowSelection: "single",
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    rowSelection: { required: false, default: "multiple" },
    page: { type: Number },
    perPage: {
      type: Number,
      required: false,
      default: 100,
    },
    assetData: { default: () => [] },
    searchText: {
      type: String,
    },
    otherColumn: { default: () => [] },
  },
  beforeMount() {},
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.loopHeaderFreeColumn();
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
      canUseDepreciation: "canUseDepreciation",
      canUseCustom2: "canUseCustom2",
      canUseCustom3: "canUseCustom3",
      canUseCustom4: "canUseCustom4",
      canUseCustom8: "canUseCustom8",
    }),

    isAssetPage() {
      return (
        this.$route.name === "depreciation-asset" ||
        this.$route.name === "docRegisAsset"
      );
    },

    hideCancelColumn() {
      const routeName = this.$route.name;
      if (routeName === "activate-asset" || routeName === "deactivate-asset") {
        return false;
      }
      return true;
    },

    rowData() {
      return this.assetData.map((asset, index) => {
        const writeOff = asset.write_off;
        let showPauseAsset = false;
        let showWriteOff = false; // for hiding requesting for write off data

        if (this.isAssetPage) {
          if (asset.pause_assets?.length > 0) {
            showPauseAsset = true;
          }
          if (writeOff) {
            showWriteOff = true;
          }
        } else {
          showPauseAsset = asset.pause_assets?.length > 0 ? true : false;
          showWriteOff = writeOff ? true : false;
        }

        let dataTable = {
          index: (this.page - 1) * this.perPage + index + 1,
          id_asset: asset.id_asset,
          id_fixed_asset: asset.id_fixed_asset,
          name: asset.name ? asset.name : "-",
          description: asset.description ? asset.description : "-",
          description2: asset.description2 ? asset.description2 : "-",
          note_acc: asset?.note_accounting?.name || "-",
          code_acc: asset?.sub_note_accounting?.code_acc || "-",
          name_acc: asset?.sub_note_accounting?.name_acc || "-",
          code_asset: asset?.sub_note_accounting?.code_asset || "-",
          name_asset: asset?.sub_note_accounting?.name_asset || "-",
          code_acc_dp: asset?.sub_note_accounting?.code_acc_dp || "-",
          name_acc_dp: asset?.sub_note_accounting?.name_acc_dp || "-",
          category: asset.categories ? asset.categories.name : "-",
          sub_category: asset.sub_category ? asset.sub_category.name : "-",
          location_building: asset.location_buildings
            ? asset.location_buildings.name
            : "-",
          location_floor: asset.location_floors
            ? asset.location_floors.name
            : "-",
          location_room: asset.location_rooms ? asset.location_rooms.name : "-",
          location_department_code: asset?.location_departments?.code || "-",
          location_department: asset.location_departments
            ? asset.location_departments.name
            : "-",
          location_zone: asset.location_zones ? asset.location_zones.name : "-",
          note: asset.note ? asset.note : "-",
          id_qrcode: this.qrCode(asset),
          depreciation_status: this.dpStatusTranslate(
            asset.depreciation_status
          ),
          purchase_date: asset.purchase_date
            ? this.momentDay(asset.purchase_date)
            : "-",
          purchase_price: asset.purchase_price
            ? toShowAccountingPrice(asset.purchase_price)
            : "-",
          start_date: asset.start_date ? this.momentDay(asset.start_date) : "-",
          expire_date: asset.expire_date
            ? this.momentDay(asset.expire_date)
            : "-",
          useful_life: asset.useful_life ? asset.useful_life : "-",
          scrap_value: asset.scrap_value
            ? toShowAccountingPrice(asset.scrap_value)
            : "-",
          acc_depreciation: asset.acc_depreciation
            ? toShowAccountingPrice(asset.acc_depreciation)
            : "-",
          net_book: asset.net_book
            ? toShowAccountingPrice(asset.net_book)
            : "-",
          depreciation_date: asset.depreciation_date
            ? this.momentDay(asset.depreciation_date)
            : "-",
          prior_depreciation: asset.prior_depreciation
            ? toShowAccountingPrice(asset.prior_depreciation)
            : "-",
          prior_net_book: asset.prior_net_book
            ? toShowAccountingPrice(asset.prior_net_book)
            : "-",
          deactivate_dates: showPauseAsset
            ? this.momentDay(asset.pause_assets[0]?.deactivate_at)
            : null,
          activate_dates: showPauseAsset
            ? this.momentDay(asset.pause_assets[0]?.activate_at)
            : null,
          pause_asset: showPauseAsset ? asset.pause_assets[0] : null,
          id_pause_asset: showPauseAsset
            ? asset.pause_assets[0].id_pause_asset
            : null,
          write_off_date: showWriteOff
            ? this.momentDay(writeOff.write_off_date)
            : "-",
          profit_loss: showWriteOff
            ? toShowAccountingPrice(writeOff.profit_loss)
            : "-",
          write_off_amount: showWriteOff
            ? toShowAccountingPrice(writeOff.write_off_amount)
            : "-",
          residual_value: showWriteOff
            ? toShowAccountingPrice(writeOff.residual_value)
            : "-",
          request_by: showWriteOff
            ? writeOff.request_write_off_user?.name || "-"
            : "-",
          approve_by: showWriteOff
            ? writeOff.approve_write_off_user?.name || "-"
            : "-",
          approve_date: this.momentDay(asset.request_write_off_at),
          // ------------------- START customId2 Jagota MaintenancePlan & branch/sector------------------------
          asset_sector: asset.sector?.name || "-",
          asset_branch: asset.branch?.name || "-",
          insurance_start: asset.custom_start_time
            ? this.momentDay(asset.custom_start_time)
            : "-",
          insurance_end: asset.custom_end_time
            ? this.momentDay(asset.custom_end_time)
            : "-",
          vendor: asset.vendor?.name || "-",
          // ------------------- END customId2 Jagota MaintenancePlan & branch/sector------------------------
          // ------------------- START Custom 3 SubAsset ------------------------
          intendant: asset.intendant || "-",
          counting_status: this.checkAssetStatus(asset),
          // ------------------- END Custom 3 SubAsset ------------------------
          // ------------------- START Custom 4 ModelAsset ------------------------
          model: asset.sub_category2?.name || "-",
          // ------------------- END Custom 4 ModelAsset ------------------------
        };
        const assetDetail = asset.asset_details;
        assetDetail.forEach((val) => {
          dataTable[`other_${val.column_no}`] = val.value || "-";
        });
        return dataTable;
      });
    },
  },
  components: {
    AgGridVue,
    CancelButton,
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
    rowData() {
      this.loopHeaderFreeColumn();
    },
  },
  methods: {
    dpStatusTranslate(status) {
      switch (status) {
        case "active":
          return "Active";
        case "expire":
          return "Fully Depreciated";
        case "deactivate":
          return "Deactivate";
        case "write off":
          return "Written off / Disposed";
        default:
          return "-";
      }
    },
    qrCode(asset) {
      if (this.$route.name === "sold-asset") {
        return asset?.qrcode ? asset?.qrcode : this.$t("home.not_reg");
      } else {
        return asset?.qrcode?.id_qrcode
          ? asset?.qrcode?.id_qrcode
          : this.$t("home.not_reg");
      }
    },
    checkAssetStatus(asset) {
      let status = "-";
      if (asset.date_regis) {
        status = this.$t("home.regist");
      } else {
        status = this.$t("asset.not_reg");
      }

      if (asset.status) {
        switch (asset.status) {
          case "ใช้งานได้":
            status = this.$t("count.inuse");
            break;
          case "ไม่ได้ใช้งาน":
            status = this.$t("count.not_inuse");
            break;
          case "ชำรุด":
            status = this.$t("count.defect");
            break;
          case "อยู่ระหว่างซ่อม":
            status = this.$t("count.repairing");
            break;
        }
      }

      if (asset.damaged_status) {
        switch (asset.damaged_status) {
          case "ซ่อมได้":
            status = this.$t("count.repar");
            break;
          case "ซ่อมไม่ได้":
            status = this.$t("count.irrepar");
            break;
          case "รอการประเมิน":
            status = this.$t("count.wait_as");
            break;
          case "กำลังซ่อม":
            status = this.$t("count.repairing");
            break;
        }
      }

      if (asset.is_lost === "lost") {
        status = this.$t("count.lost");
      }
      if (asset.is_lost === "report") {
        status = this.$t("filter.rep_lost");
      }
      return status;
    },

    loopHeaderFreeColumn() {
      let columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          headerCheckboxSelection:
            this.rowSelection === "multiple" ? true : false,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: this.rowSelection !== "none" ? true : false,
          maxWidth: 110,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("home.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.as_name"),
          field: "name",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.as_dt"),
          field: "description",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.more_description"),
          field: "description2",
          sortable: true,
          minWidth: 200,
          hide: !this.canUseCustom8,
        },
        {
          headerName: this.$t("home.cat"),
          field: "category",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.sub_cat"),
          field: "sub_category",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("model.model"),
          field: "model",
          sortable: true,
          minWidth: 200,
          hide: !this.canUseCustom4,
        },
        {
          headerName: this.$t("asset.location"),
          field: "asset_sector",
          sortable: true,
          suppressSizeToFit: true,
          hide: !this.canUseCustom2 || this.isRoleLower,
        },
        {
          headerName: this.$t("asset.branch"),
          field: "asset_branch",
          sortable: true,
          suppressSizeToFit: true,
          hide: !this.canUseCustom2 || this.isRoleLower,
        },
        {
          headerName: this.$t("home.build"),
          field: "location_building",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.fl"),
          field: "location_floor",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.room"),
          field: "location_room",
          sortable: true,
          minWidth: 200,
          hide: !this.canUseCustom8,
        },
        {
          headerName: this.$t("home.department_code"),
          field: "location_department_code",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.department"),
          field: "location_department",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.zone"),
          field: "location_zone",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.note"),
          field: "note",
          sortable: true,
          minWidth: 150,
        },
        {
          headerName: "QR Code",
          field: "id_qrcode",
          sortable: true,
          suppressSizeToFit: true,
          cellClass: "text-center",
          cellStyle: (params) => {
            if (params.value === this.$t("home.not_reg")) {
              return { color: "#888888" };
            }
            return null;
          },
        },
      ];

      if (!this.isRoleLower) {
        const fix_column = [
          {
            headerName: this.$t("depreciation.acc_status"),
            field: "depreciation_status",
            sortable: true,
            minWidth: 150,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
            cellRenderer: (params) => {
              return params.value;
            },
            cellStyle: (params) => {
              switch (params.value) {
                case "Active":
                  return { color: "#00AB1B" };
                case "Fully Depreciated":
                  return { color: "#7B61FF" };
                case "Deactivate":
                  return { color: "#E9A800" };
                case "Written off / Disposed":
                  return { color: "#ED1616" };
                default:
                  return null;
              }
            },
          },
          {
            headerName: this.$t("asset.acc_note"),
            field: "note_acc",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.asset_acc_code"),
            field: "code_asset",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.asset_acc_name"),
            field: "name_asset",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.acc_code"),
            field: "code_acc",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.dep_acc_name"),
            field: "name_acc",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.accumulate_dep_acc_code"),
            field: "code_acc_dp",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.accumulate_dep_acc_name"),
            field: "name_acc_dp",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.purchase_date"),
            field: "purchase_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.start_date"),
            field: "start_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.exp_date"),
            field: "expire_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.purchase_price"),
            field: "purchase_price",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.useful_life"),
            field: "useful_life",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.scrap_value"),
            field: "scrap_value",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.dep_at_date"),
            field: "acc_depreciation",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation || this.$route.name === "sold-asset",
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.nb_at_date"),
            field: "net_book",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation || this.$route.name === "sold-asset",
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.dep_date"),
            field: "depreciation_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.prior_dep"),
            field: "prior_depreciation",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.prior_nb"),
            field: "prior_net_book",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.pause_date"),
            field: "deactivate_dates",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellRenderer: (params) => {
              return params.value || "-";
            },
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.active_date"),
            field: "activate_dates",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellRenderer: (params) => {
              return params.value || "-";
            },
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.writeoff_date"),
            field: "write_off_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.writeoff_price"),
            field: "write_off_amount",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.net_writeoff_price"),
            field: "residual_value",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.profit_loss"),
            field: "profit_loss",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("home.intendant"),
            minWidth: 170,
            field: "intendant",
            sortable: true,
            hide: !this.canUseCustom3,
          },
          {
            headerName: this.$t("home.as_stat"),
            suppressSizeToFit: true,
            field: "counting_status",
            sortable: true,
            hide: !this.canUseCustom3,
            cellStyle: (params) => {
              if (
                params.value === this.$t("count.repar") ||
                params.value === this.$t("count.irrepar") ||
                params.value === this.$t("count.wait_as")
              ) {
                return { color: "#C18B00" };
              } else if (params.value === this.$t("count.not_reg")) {
                return { color: "#888888" };
              } else if (
                params.value === this.$t("count.lost") ||
                params.value === this.$t("filter.rep_lost")
              ) {
                return { color: "#FF0000" };
              } else if (params.value === this.$t("count.inuse")) {
                return { color: "#1E9400" };
              } else if (params.value === this.$t("home.regist")) {
                return { color: "#00AB1B" };
              }
              return null;
            },
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.insurance_start"),
            field: "insurance_start",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
          {
            headerName: this.$t("asset.insurance_end"),
            field: "insurance_end",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
          {
            headerName: this.$t("asset.vendor"),
            field: "vendor",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
        ];
        columnDefs = columnDefs.concat(fix_column);
        let column = this.otherColumn;
        for (const col of column) {
          let header = {
            headerName: col.name,
            field: `other_${col.column_no}`,
            cellRenderer: (params) => {
              return params.value || "-";
            },
            suppressSizeToFit: true,
          };
          columnDefs.push(header);
        }
        if (this.$route.name === "sold-asset") {
          columnDefs = columnDefs.concat(
            {
              headerName: this.$t("depreciation.request_by"),
              field: "request_by",
              sortable: true,
              minWidth: 100,
              suppressSizeToFit: true,
              cellRenderer: (params) => {
                return params.value || "-";
              },
            },
            {
              headerName: this.$t("depreciation.approve_by"),
              field: "approve_by",
              sortable: true,
              minWidth: 100,
              suppressSizeToFit: true,
              cellRenderer: (params) => {
                return params.value || "-";
              },
            },
            {
              headerName: this.$t("depreciation.approve_date"),
              field: "approve_date",
              sortable: true,
              minWidth: 100,
              suppressSizeToFit: true,
              cellRenderer: (params) => {
                return params.value || "-";
              },
              cellClass: "text-center",
            }
          );
        }
        columnDefs = columnDefs.concat({
          headerName: this.$t("home.manage"),
          hide: this.hideCancelColumn,
          field: "pause_asset",
          sortable: true,
          suppressSizeToFit: true,
          cellRendererFramework: "CancelButton",
          cellRendererParams: {
            cancelActionDate: this.cancelActionDate.bind(this),
          },
        });
      }
      this.columnDefs = columnDefs;
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      // this.gridColumnApi.autoSizeAllColumns();
    },
    momentDay(date) {
      return moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : null;
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => {
        return node.data;
      });
      this.$emit("selected-assets", selectedData);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
    cancelActionDate(value) {
      this.$emit("cancel-action", value);
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
